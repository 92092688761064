import { useQuery } from '@apollo/client';
import {
  DayOfWeek,
  Icon,
  IconButton,
  Label,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import {
  FormHookAmount,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookDateSelect } from 'common/components/FormHooksFields/FormHookDateSelect';
import { FormHookTimePicker } from 'common/components/FormHooksFields/FormHookTimePicker';
import { useCommonStyles } from 'common/styles';
import Decimal from 'decimal.js';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TravelerAuthorizationCommonData } from 'travelAuthorization/TravelPlan/view/FormView/__generated__/TravelerAuthorizationCommonData';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import {
  Trip_trip,
  Trip_trip_tripItems_nodes_pickupDropSupplier,
  Trip_trip_tripItems_nodes_pickupSupplier,
  Trip_trip_tripItems_nodes_returnDropSupplier,
  Trip_trip_tripItems_nodes_returnSupplier,
} from '../../../../__generated__/Trip';
import { TripCreateFormValues } from '../../../../types';
import { SupplierTripSectionAutoComplete as SupplierAutoComplete } from '../components/SuppliersSection/SupplierAutoComplete';
const TRAVELER_AUTHORIZATION_COMMON_DATA = loader(
  '../../../../../../TravelerAuthorizationCommonData.graphql'
);

interface GroundTransportProps {
  tripIndex: number;
  tripData: Trip_trip | null | undefined;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
  listRefreshRequest: () => void;
}

export const GroundTransport: React.FC<GroundTransportProps> = ({
  tripIndex,
  tripData,
  travelAuthorizationData,
  listRefreshRequest,
}) => {
  useEffect(() => {
    listRefreshRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commonStyles = useCommonStyles();
  const baseField = `tripItems.${tripIndex}.`;
  const { control } = useFormContext<TripCreateFormValues>();
  const tripItemsValues = useWatch({ name: 'tripItems', control });
  const GroundTransportValues = tripItemsValues[tripIndex];

  const totalAmount = GroundTransportValues
    ? [GroundTransportValues]
        ?.reduce(
          (
            sum,
            { pickupPrice, pickupArrivalPrice, returnPrice, returnArrivalPrice }
          ) => {
            const decimalPickupPrice = new Decimal(pickupPrice || 0);
            const decimalPickupArrivalPrice = new Decimal(
              pickupArrivalPrice || 0
            );
            const decimalReturnPrice = new Decimal(returnPrice || 0);
            const decimalReturnArrivalPrice = new Decimal(
              returnArrivalPrice || 0
            );
            return decimalPickupPrice
              .plus(decimalPickupArrivalPrice)
              .plus(decimalReturnPrice)
              .plus(decimalReturnArrivalPrice)
              .plus(sum);
          },
          new Decimal(0)
        )
        .toNumber()
        .toFixed(2)
    : 0;

  const { data: commonData } = useQuery<TravelerAuthorizationCommonData>(
    TRAVELER_AUTHORIZATION_COMMON_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const companyCurrenciesOptions =
    commonData?.companyCurrencies?.nodes.map((ele) => {
      const currency = !!ele.name
        ? `${ele.isoCode} - ${ele.name}`
        : ele.isoCode || '';
      return {
        key: ele.id,
        text: currency,
      };
    }) || [];

  return (
    <Stack>
      <Stack horizontal horizontalAlign="end">
        <Stack
          horizontal
          tokens={{ childrenGap: 20, padding: '0px 0px 0px 0px' }}
        >
          <Text variant="xLarge">Total </Text>
          <AmountTextView
            className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
            value={totalAmount?.toString() || '0.00'}
            variant="xLarge"
          />
        </Stack>
      </Stack>
      <Stack tokens={{ childrenGap: 20 }}>
        <DepartureLayout
          baseField={baseField}
          tripIndex={tripIndex}
          travelAuthorizationData={travelAuthorizationData}
          tripData={tripData}
          currencyOptions={companyCurrenciesOptions}
        />
        <ReturnLayout
          baseField={baseField}
          tripIndex={tripIndex}
          travelAuthorizationData={travelAuthorizationData}
          tripData={tripData}
          currencyOptions={companyCurrenciesOptions}
        />
      </Stack>
    </Stack>
  );
};

const DepartureLayout: React.FC<{
  baseField: string;
  tripIndex: number;
  tripData: Trip_trip | null | undefined;
  currencyOptions: { key: number; text: string }[];
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
}> = ({
  baseField,
  tripIndex,
  tripData,
  travelAuthorizationData,
  currencyOptions,
}) => {
  const { setValue, control } = useFormContext<TripCreateFormValues>();
  const commonStyles = useCommonStyles();
  const [pickupSupplierState, setPickupSupplierState] =
    useState<Trip_trip_tripItems_nodes_pickupSupplier | null>();
  const [pickupDropSupplierState, setPickupDropSupplierState] =
    useState<Trip_trip_tripItems_nodes_pickupDropSupplier | null>();

  const { _isUpdatable, _isNonAmountUpdatableAfterApproval } = {
    ...travelAuthorizationData,
  };
  const { travelerId, tripItems } = { ...tripData };
  const tripSectionData = useWatch({
    name: `tripItems.${tripIndex}`,
    control,
  });
  const { isSupplierBreakdownRequired, tripSectionItemId } = {
    ...tripSectionData,
  };
  const isDisabled = !!_isNonAmountUpdatableAfterApproval
    ? false
    : !_isUpdatable;

  const { pickupDropSupplier, pickupSupplier } = {
    ...tripItems?.nodes?.[tripIndex],
  };

  useEffect(() => {
    if (!isSupplierBreakdownRequired) {
      setPickupSupplierState(null);
      setPickupDropSupplierState(null);
    }
  }, [isSupplierBreakdownRequired]);

  useEffect(() => {
    if (!!pickupSupplier) setPickupSupplierState(pickupSupplier);
    if (!!pickupDropSupplier) setPickupDropSupplierState(pickupDropSupplier);
  }, [pickupSupplier, pickupDropSupplier]);

  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Text
          variant="large"
          className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
        >
          Departure
        </Text>
        <Icon iconName="ArrowTallUpRight" />
      </Stack>
      <Stack grow>
        {/* FROM */}
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            {/* DATE-TIME */}
            <Stack
              style={{
                height: 75,
              }}
            >
              <Label disabled={isDisabled}>Pickup Date-Time</Label>
              <Stack
                verticalAlign="end"
                horizontal
                tokens={{ childrenGap: 10 }}
              >
                <FormHookDateSelect
                  name={`${baseField}departureDate`}
                  placeholder="Select Date"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday
                  styles={{ root: { width: 150, marginTop: 10 } }}
                  onSelectDateCallback={(date) => {
                    if (date)
                      setValue(
                        `tripItems.${tripIndex}.departureArrivalDate`,
                        date.toString() || null
                      );
                  }}
                  disabled={isDisabled}
                />
                <FormHookTimePicker
                  name={`${baseField}departureTime`}
                  placeholder="Enter time"
                  disabled={isDisabled}
                />
                {/* LOCATIONS */}
                <Icon style={{ fontSize: 20 }} />
                <FormHookTextField
                  name={`${baseField}pickupName`}
                  placeholder="Enter location"
                  label="From"
                  styles={{ root: { width: 250 } }}
                  iconProps={{ iconName: 'MapPin' }}
                  disabled={isDisabled}
                />
                <FormHookTextField
                  name={`${baseField}pickupDropName`}
                  placeholder="Enter location"
                  label="To"
                  styles={{ root: { width: 250 } }}
                  iconProps={{ iconName: 'MapPin' }}
                  disabled={isDisabled}
                />
                {/* OTHER FIELDS CONFIRMATION CODE, PRICES ETC */}
                <FormHookAmount
                  name={`${baseField}pickupPrice`}
                  label="Price"
                  placeholder="Enter Price"
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  disabled={!_isUpdatable}
                />
                <FormHookTextField
                  label="Confirmation Code"
                  name={`${baseField}departureCarrierConfirmationCode`}
                  placeholder="Enter Confirmation Code"
                  disabled={isDisabled}
                />
                <TooltipHost content="Clear line" id="tooltipId">
                  <IconButton
                    onClick={() => {
                      setValue(
                        `tripItems.${tripIndex}.departureCarrierConfirmationCode`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(`tripItems.${tripIndex}.pickupPrice`, null, {
                        shouldDirty: true,
                      });
                      setValue(`tripItems.${tripIndex}.pickupDropName`, null, {
                        shouldDirty: true,
                      });
                      setValue(`tripItems.${tripIndex}.pickupName`, null, {
                        shouldDirty: true,
                      });
                      setValue(`tripItems.${tripIndex}.departureTime`, null, {
                        shouldDirty: true,
                      });
                      setValue(`tripItems.${tripIndex}.departureDate`, null, {
                        shouldDirty: true,
                      });
                    }}
                    iconProps={{ iconName: 'Delete' }}
                    disabled={!_isUpdatable}
                  />
                </TooltipHost>
              </Stack>
            </Stack>
          </Stack>
          {isSupplierBreakdownRequired && (
            <Stack
              horizontal
              horizontalAlign="end"
              verticalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <FormHookDropdown
                name={`tripItems.${tripIndex}.pickupCurrencyId`}
                options={currencyOptions}
                width={230}
                label="Currency (Override)"
                placeholder="Select currency"
                disabled={isDisabled}
              />
              <SupplierAutoComplete
                name={`tripItems.${tripIndex}.pickupSupplierId`}
                label="Supplier"
                placeholder="Supplier"
                disabled={isDisabled}
                lookUpCalloutWidth={400}
                id={'tripItems-pickupSupplierId'}
                aria-labelledby={`tripItems-${tripIndex}-pickupSupplierId`}
                style={{ width: 300 }}
                showAddIcon
                supplier={pickupSupplierState!}
                travelerId={travelerId!}
                tripSectionId={tripSectionItemId!}
              />
            </Stack>
          )}
        </Stack>
        {/* TO */}
        <Stack style={{ marginTop: 20 }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            {/* DATE-TIME */}
            <Stack
              style={{
                height: 95,
              }}
            >
              <Label disabled={isDisabled}>Arrival Date-Time</Label>
              <Stack
                verticalAlign="end"
                horizontal
                tokens={{ childrenGap: 10 }}
              >
                <FormHookDateSelect
                  name={`${baseField}departureArrivalDate`}
                  placeholder="Select Date"
                  styles={{ root: { width: 150 } }}
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday
                  disabled={isDisabled}
                />
                <FormHookTimePicker
                  name={`${baseField}departureArrivalTime`}
                  placeholder="Enter time"
                  disabled={isDisabled}
                />
                {/* LOCATIONS */}
                <Icon style={{ fontSize: 20 }} />
                <FormHookTextField
                  name={`${baseField}pickupArrivalName`}
                  placeholder="Enter location"
                  styles={{ root: { width: 250 } }}
                  iconProps={{ iconName: 'MapPin' }}
                  disabled={isDisabled}
                />
                <FormHookTextField
                  name={`${baseField}pickupArrivalDropName`}
                  placeholder="Enter location"
                  styles={{ root: { width: 250 } }}
                  iconProps={{ iconName: 'MapPin' }}
                  disabled={isDisabled}
                />
                {/* OTHER FIELDS PRICES ETC */}
                <FormHookAmount
                  name={`${baseField}pickupArrivalPrice`}
                  label="Price"
                  placeholder="Enter Price"
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  disabled={!_isUpdatable}
                />
                <FormHookTextField
                  label="Confirmation Code"
                  name={`${baseField}departureArrivalConfirmationCode`}
                  placeholder="Enter Confirmation Code"
                  disabled={!_isUpdatable}
                />
                <TooltipHost content="Clear line" id="tooltipId">
                  <IconButton
                    onClick={() => {
                      setValue(
                        `tripItems.${tripIndex}.departureArrivalConfirmationCode`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(
                        `tripItems.${tripIndex}.pickupArrivalPrice`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(
                        `tripItems.${tripIndex}.pickupArrivalDropName`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(
                        `tripItems.${tripIndex}.pickupArrivalName`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(
                        `tripItems.${tripIndex}.departureArrivalTime`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(
                        `tripItems.${tripIndex}.departureArrivalDate`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                    }}
                    iconProps={{ iconName: 'Delete' }}
                    disabled={!_isUpdatable}
                  />
                </TooltipHost>
              </Stack>
            </Stack>
          </Stack>
          {isSupplierBreakdownRequired && (
            <Stack
              horizontal
              horizontalAlign="end"
              verticalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <FormHookDropdown
                name={`tripItems.${tripIndex}.pickupDropCurrencyId`}
                options={currencyOptions}
                width={230}
                label="Currency (Override)"
                placeholder="Select currency"
                disabled={isDisabled}
              />
              <SupplierAutoComplete
                name={`tripItems.${tripIndex}.pickupDropSupplierId`}
                label="Supplier"
                placeholder="Supplier"
                disabled={isDisabled}
                lookUpCalloutWidth={400}
                id={'tripItems-pickupDropSupplierId'}
                aria-labelledby={`tripItems-${tripIndex}-pickupDropSupplierId`}
                style={{ width: 300 }}
                showAddIcon
                supplier={pickupDropSupplierState!}
                travelerId={travelerId!}
                tripSectionId={tripSectionItemId!}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const ReturnLayout: React.FC<{
  baseField: string;
  tripIndex: number;
  currencyOptions: { key: number; text: string }[];
  tripData: Trip_trip | null | undefined;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
}> = ({
  baseField,
  tripIndex,
  travelAuthorizationData,
  currencyOptions,
  tripData,
}) => {
  const commonStyles = useCommonStyles();
  const { setValue, control } = useFormContext<TripCreateFormValues>();
  const [returnSupplierState, setReturnSupplierStateState] =
    useState<Trip_trip_tripItems_nodes_returnSupplier | null>();
  const [returnDropSupplierState, setReturnDropSupplierState] =
    useState<Trip_trip_tripItems_nodes_returnDropSupplier | null>();
  const { _isUpdatable, _isNonAmountUpdatableAfterApproval } = {
    ...travelAuthorizationData,
  };
  const { travelerId, tripItems } = { ...tripData };

  const isDisabled = !!_isNonAmountUpdatableAfterApproval
    ? false
    : !_isUpdatable;

  const tripSectionData = useWatch({
    name: `tripItems.${tripIndex}`,
    control,
  });
  const { isSupplierBreakdownRequired, tripSectionItemId } = {
    ...tripSectionData,
  };

  const { returnSupplier, returnDropSupplier } = {
    ...tripItems?.nodes?.[tripIndex],
  };

  useEffect(() => {
    if (!isSupplierBreakdownRequired) {
      setReturnSupplierStateState(null);
      setReturnDropSupplierState(null);
    }
  }, [isSupplierBreakdownRequired]);

  useEffect(() => {
    if (!!returnSupplier) setReturnSupplierStateState(returnSupplier);
    if (!!returnDropSupplier) setReturnDropSupplierState(returnDropSupplier);
  }, [returnSupplier, returnDropSupplier]);

  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
        <Text
          variant="large"
          className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
        >
          Return
        </Text>
        <Icon iconName="ArrowTallDownLeft" />
      </Stack>
      <Stack grow>
        {/* FROM */}
        <Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            {/* DATE-TIME */}
            <Stack
              style={{
                height: 95,
              }}
            >
              <Label disabled={isDisabled}>Pickup Date-Time</Label>
              <Stack
                verticalAlign="end"
                horizontal
                tokens={{ childrenGap: 10 }}
              >
                <FormHookDateSelect
                  name={`${baseField}returnDate`}
                  placeholder="Select Date"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday
                  styles={{ root: { width: 150 } }}
                  onSelectDateCallback={(date) => {
                    if (date)
                      setValue(
                        `tripItems.${tripIndex}.returnArrivalDate`,
                        date.toString() || null
                      );
                  }}
                  disabled={isDisabled}
                />
                <FormHookTimePicker
                  name={`${baseField}returnTime`}
                  placeholder="Enter time"
                  disabled={isDisabled}
                />
                {/* LOCATIONS */}

                <Icon style={{ fontSize: 20 }} />
                <FormHookTextField
                  name={`${baseField}returnName`}
                  placeholder="Enter location"
                  label="From"
                  styles={{ root: { width: 250 } }}
                  iconProps={{ iconName: 'MapPin' }}
                  disabled={isDisabled}
                />
                <FormHookTextField
                  name={`${baseField}returnDropName`}
                  placeholder="Enter location"
                  label="To"
                  styles={{ root: { width: 250 } }}
                  iconProps={{ iconName: 'MapPin' }}
                  disabled={isDisabled}
                />
                {/* OTHER FIELDS PRICES ETC */}

                <FormHookAmount
                  name={`${baseField}returnPrice`}
                  label="Price"
                  placeholder="Enter Price"
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  disabled={!_isUpdatable}
                />
                <FormHookTextField
                  label="Confirmation Code"
                  name={`${baseField}returnCarrierConfirmationCode`}
                  placeholder="Enter Confirmation Code"
                  disabled={isDisabled}
                />
                <TooltipHost content="Clear line" id="tooltipId">
                  <IconButton
                    onClick={() => {
                      setValue(
                        `tripItems.${tripIndex}.returnCarrierConfirmationCode`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(`tripItems.${tripIndex}.returnPrice`, null, {
                        shouldDirty: true,
                      });
                      setValue(`tripItems.${tripIndex}.returnDropName`, null, {
                        shouldDirty: true,
                      });
                      setValue(`tripItems.${tripIndex}.returnName`, null, {
                        shouldDirty: true,
                      });
                      setValue(`tripItems.${tripIndex}.returnTime`, null, {
                        shouldDirty: true,
                      });
                      setValue(`tripItems.${tripIndex}.returnDate`, null, {
                        shouldDirty: true,
                      });
                    }}
                    iconProps={{ iconName: 'Delete' }}
                    disabled={!_isUpdatable}
                  />
                </TooltipHost>
              </Stack>
            </Stack>
          </Stack>
          {isSupplierBreakdownRequired && (
            <Stack
              horizontal
              horizontalAlign="end"
              verticalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <FormHookDropdown
                name={`tripItems.${tripIndex}.returnCurrencyId`}
                options={currencyOptions}
                width={230}
                label="Currency (Override)"
                placeholder="Select currency"
                disabled={isDisabled}
              />
              <SupplierAutoComplete
                name={`tripItems.${tripIndex}.returnSupplierId`}
                label="Supplier"
                placeholder="Supplier"
                disabled={isDisabled}
                lookUpCalloutWidth={400}
                id={'tripItems-returnSupplierId'}
                aria-labelledby={`tripItems-${tripIndex}-returnSupplierId`}
                style={{ width: 300 }}
                showAddIcon
                supplier={returnSupplierState!}
                travelerId={travelerId!}
                tripSectionId={tripSectionItemId!}
              />
            </Stack>
          )}
        </Stack>
        {/* TO */}
        <Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            {/* DATE-TIME */}
            <Stack>
              <Label disabled={isDisabled}>Arrival Date-Time</Label>
              <Stack
                verticalAlign="end"
                horizontal
                tokens={{ childrenGap: 10 }}
              >
                <FormHookDateSelect
                  name={`${baseField}returnArrivalDate`}
                  placeholder="Select Date"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday
                  styles={{ root: { width: 150 } }}
                  disabled={isDisabled}
                />
                <FormHookTimePicker
                  name={`${baseField}returnArrivalTime`}
                  placeholder="Enter time"
                  disabled={isDisabled}
                />
                {/* LOCATIONS */}

                <Icon style={{ fontSize: 20 }} />
                <FormHookTextField
                  name={`${baseField}returnArrivalName`}
                  placeholder="Enter location"
                  styles={{ root: { width: 250 } }}
                  iconProps={{ iconName: 'MapPin' }}
                  disabled={isDisabled}
                />
                <FormHookTextField
                  name={`${baseField}returnArrivalDropName`}
                  placeholder="Enter location"
                  styles={{ root: { width: 250 } }}
                  iconProps={{ iconName: 'MapPin' }}
                  disabled={isDisabled}
                />
                {/* OTHER FIELDS PRICES ETC */}
                <FormHookAmount
                  name={`${baseField}returnArrivalPrice`}
                  label="Price"
                  placeholder="Enter Price"
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  disabled={!_isUpdatable}
                />
                <FormHookTextField
                  label="Confirmation Code"
                  name={`${baseField}returnArrivalConfirmationCode`}
                  placeholder="Enter Confirmation Code"
                  disabled={!_isUpdatable}
                />
                <TooltipHost content="Clear line" id="tooltipId">
                  <IconButton
                    onClick={() => {
                      setValue(
                        `tripItems.${tripIndex}.returnArrivalConfirmationCode`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(
                        `tripItems.${tripIndex}.returnArrivalPrice`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(
                        `tripItems.${tripIndex}.returnArrivalDropName`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(
                        `tripItems.${tripIndex}.returnArrivalName`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(
                        `tripItems.${tripIndex}.returnArrivalTime`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                      setValue(
                        `tripItems.${tripIndex}.returnArrivalDate`,
                        null,
                        {
                          shouldDirty: true,
                        }
                      );
                    }}
                    iconProps={{ iconName: 'Delete' }}
                    disabled={!_isUpdatable}
                  />
                </TooltipHost>
              </Stack>
            </Stack>
          </Stack>
          {isSupplierBreakdownRequired && (
            <Stack
              horizontal
              horizontalAlign="end"
              verticalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <FormHookDropdown
                name={`tripItems.${tripIndex}.returnDropCurrencyId`}
                options={currencyOptions}
                width={230}
                label="Currency (Override)"
                placeholder="Select currency"
                disabled={isDisabled}
              />
              <SupplierAutoComplete
                name={`tripItems.${tripIndex}.returnDropSupplierId`}
                label="Supplier"
                placeholder="Supplier"
                disabled={isDisabled}
                lookUpCalloutWidth={400}
                id={'tripItems-returnDropSupplierId'}
                aria-labelledby={`tripItems-${tripIndex}-returnDropSupplierId`}
                style={{ width: 300 }}
                showAddIcon
                supplier={returnDropSupplierState!}
                travelerId={travelerId!}
                tripSectionId={tripSectionItemId!}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
