import { makeStyles } from '@fluentui/react';
import { TAB_SIZE } from '.';

export const useStyles = makeStyles(() => ({
  tabContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    flex: 1,
    borderRadius: TAB_SIZE,
    transition: 'all 200ms ease-in-out',
    cursor: 'pointer',
    ':hover': {
      transform: 'scale(1.1) translateY(-10px)',
    },
  },
  tabContainerSelected: {
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    flex: 1,
    borderRadius: TAB_SIZE,
    transition: 'all 200ms ease-in-out',
    cursor: 'pointer',
    ':hover': {
      transform: 'scale(1.1) translateY(-10px)',
      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
    },
  },
  toggleButton: {
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    flex: 1,
    cursor: 'pointer',
  },
  noVisibility: {
    opacity: 0,
  },
  animationEntrance: {
    opacity: 1,
    transition: 'all 600ms ease-in-out',
    transformStyle: 'preserve-3d',
    transform: 'scale(1.1) translateY(-20px) translateZ(90px)',
  },
}));

// import { makeStyles } from '@fluentui/react';

// export const useStyles = makeStyles(() => ({
//   toggleButton: {
//     width: 100,
//     height: 100,
//     cursor: 'pointer',
//     transition: 'transform 200ms ease-in-out, box-shadow 200ms ease-in-out',
//     transformStyle: 'preserve-3d',
//     ':hover': {
//       transform: 'scale(1.1) translateY(-5px) translateZ(20px)',
//       boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
//     },
//   },
// }));
