import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import { EntityType } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { setTripsModalState } from '../../..';
import {
  TripDelete,
  TripDeleteVariables,
} from '../../../__generated__/TripDelete';
import { Trip_trip } from '../../__generated__/Trip';
import { TripCreateFormValues } from '../../types';
import { useStyles } from './index.styles';
import { setTripInitiateFlow } from 'utility/cache/ui';
const TRIP_DELETE = loader('../../../TripDelete.graphql');
export const TRIP_MODAL_HEADER_HEIGHT = 70;
interface HeaderProps {
  tripData: Trip_trip | null | undefined;
  onIsLoading: (param: boolean) => void;
  inputsDisabled: boolean;
  onClose?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  tripData,
  onIsLoading,
  inputsDisabled,
  onClose,
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const { addToast } = useToasts();
  const {
    tripLocator,
    id,
    _isDeletable,
    _rowTimestamp,
    travelAuthorizationId,
  } = { ...tripData };
  const isNew = !id;

  const {
    formState: { isDirty, dirtyFields },
  } = useFormContext<TripCreateFormValues>();

  const [deleteItem, { loading: deleteItemLoading }] = useMutation<
    TripDelete,
    TripDeleteVariables
  >(TRIP_DELETE, {
    errorPolicy: 'all',
  });

  const onDeleteClick = async () => {
    const { errors } = await deleteItem({
      variables: {
        input: {
          entityDelete: [
            {
              id: id!,
              rowTimestamp: _rowTimestamp!,
            },
          ],
        },
        id: travelAuthorizationId!,
      },
    });
    if (errors) addToast(errors?.[0].message, { appearance: 'error' });
    else {
      setTripsModalState({
        trip: undefined,
        isModalOpen: false,
        isNew: false,
      });
      addToast('Trip Item - Deleted Successfully', {
        appearance: 'success',
      });
    }
  };

  useEffect(() => {
    onIsLoading(deleteItemLoading);
  }, [deleteItemLoading]);

  return (
    <Stack horizontal className={styles.container}>
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        className={styles.innerContainer}
      >
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Text variant="xLarge">{`${'Trip'}`}</Text>
          {tripLocator && (
            <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
              {tripLocator}
            </Text>
          )}
        </Stack>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
          <ActionMessageModal
            visible={!isNew}
            entityType={EntityType.Trip}
            disabled={!_isDeletable || inputsDisabled}
            onConfirm={onDeleteClick}
          />

          <CancelButton
            onConfirm={() => {
              setTripsModalState({
                trip: undefined,
                isModalOpen: false,
                isNew: true,
              });
              onClose?.();
              setTripInitiateFlow('none');
            }}
            isDirty={isDirty && Object.keys(dirtyFields).length > 0}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const dialogStyles = { main: { maxWidth: 450 } };

const cancelDialogContentProps = {
  type: DialogType.normal,
  title: 'Warning',
  closeButtonAriaLabel: 'Close',
  subText: 'Are you sure you want to leave without saving changes?',
};

export const CancelButton: React.FC<{
  isDirty: boolean;
  onConfirm: () => void;
}> = ({ isDirty, onConfirm }) => {
  const [visible, setVisible] = useState(false);
  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: dialogStyles,
    }),
    []
  );

  return (
    <Stack>
      {visible && (
        <Dialog
          hidden={false}
          dialogContentProps={cancelDialogContentProps}
          modalProps={modalProps}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                onConfirm();
                setVisible(false);
              }}
              text="Leave"
            />
            <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
          </DialogFooter>
        </Dialog>
      )}

      <CloseButton
        onClick={() => {
          if (isDirty) {
            setVisible(true);
          } else onConfirm();
        }}
      />
    </Stack>
  );
};
