import { useReactiveVar } from '@apollo/client';
import { Icon, PrimaryButton, Stack, Text, TooltipHost } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TravelerAuthorizationCommonData } from 'travelAuthorization/TravelPlan/view/FormView/__generated__/TravelerAuthorizationCommonData';
import { setTripInitiateFlow, setUserDefaults } from 'utility/cache/ui';
import { SectionProps } from '../..';
import { Trip_trip } from '../../__generated__/Trip';
import { TripCreateFormValues, TripItemFormValues } from '../../types';
import { getDefaultDistribution } from '../../utils';
import { setSelectedTraveler } from '../BasicForm/TravelerAutoComplete';
import { getInitialValues, useTravelIcon } from '../SectionToggle/utils';
import { useStyles } from './index.styles';

export const TAB_SIZE = 70;
export type IntuitiveFlowProps = {
  commonData: TravelerAuthorizationCommonData | undefined;
  tripItemsState: Map<string, SectionProps>;
  tripData: Trip_trip | null | undefined;
  onTripItemsUpdate: (
    mapData: Map<string, SectionProps>,
    layoutAdded: string,
    adding: boolean
  ) => void;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
};
export const IntuitiveFlow: React.FC<IntuitiveFlowProps> = ({
  commonData,
  tripItemsState,
  travelAuthorizationData,
  tripData,
  onTripItemsUpdate,
}) => {
  const { append } = useFieldArray({ name: 'tripItems' });
  const selectedTraveler = useReactiveVar(setSelectedTraveler);
  const { control } = useFormContext<TripCreateFormValues>();
  const tripItemsValues = useWatch({ name: 'tripItems', control });
  const userDefaults = useReactiveVar(setUserDefaults);
  const tripInitiateFlowState = useReactiveVar(setTripInitiateFlow);
  const { getIcon, getOutlineIcon } = useTravelIcon();
  const styles = useStyles();
  const [dataSet, setDataSet] = useState<Map<string, TripItemFormValues>>(
    new Map()
  );

  const arr = Array.from(dataSet);

  const tooltipContent = (item: SectionProps, added: boolean) => {
    return (
      <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
        {!added && <Icon iconName="add" />}
        <Text> {`${item.name}`}</Text>
      </Stack>
    );
  };

  const tripsSelected = Array.from(tripItemsState).map((it) => ({
    tripKey: it[0],
    details: it[1],
  }));

  useEffect(() => {
    if (!selectedTraveler) setTripInitiateFlow('none');
    if (tripsSelected.length === 0 && selectedTraveler) {
      setTripInitiateFlow('initiated');
    }
    if (tripsSelected.length === 1) {
      setTripInitiateFlow('in-progress');
    }
  }, [tripsSelected, selectedTraveler]);

  return (
    <Stack
      style={{
        paddingTop: 50,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack>
        <Text variant="superLarge">Choose trip details:</Text>
        {tripInitiateFlowState === 'in-progress' && (
          <Text variant="xLargePlus">
            Select additional sections and press continue:
          </Text>
        )}

        <Stack
          tokens={{ childrenGap: 30, padding: '50px 0px 20px 0px' }}
          horizontal
        >
          {commonData?.tripSections?.nodes.map((item, index) => {
            const tripItemAdded = tripItemsState.has(item.layoutType!);

            return (
              <Stack
                className={
                  !tripItemAdded
                    ? styles.tabContainer
                    : styles.tabContainerSelected
                }
                key={index}
                onClick={() => {
                  const adding = !tripItemsState.has(item.layoutType!);

                  // original line ---- > if (adding && !inputsDisabled) {
                  if (adding) {
                    const tripDefaultValues = getInitialValues(
                      item.layoutType,
                      selectedTraveler,
                      travelAuthorizationData,
                      tripItemsValues
                    );

                    const values = getDefaultDistribution(
                      item.id!,
                      userDefaults,
                      true
                    );
                    const tripItemRow: TripItemFormValues = {
                      ...tripDefaultValues,
                      tripSectionItemId: item.tripSectionItems.nodes?.[0].id,
                      layout: item.layoutType,
                      tripSectionId: item.id,
                      currencyId:
                        tripData?.travelAuthorization?.currency?.id || null,
                      tripDistributions: [{ ...values }],
                    };
                    // append(tripItemRow);
                    dataSet.set(item.layoutType!, tripItemRow);
                    setDataSet(() => {
                      const newMap = new Map(Array.from(dataSet));
                      return newMap;
                    });
                    onTripItemsUpdate(
                      tripItemsState.set(item.layoutType!, { ...item }),
                      item.layoutType!,
                      adding
                    );
                  } else {
                    dataSet.delete(item.layoutType!);
                    setDataSet(new Map(dataSet));
                    tripItemsState.delete(item.layoutType!);
                    onTripItemsUpdate(
                      new Map(tripItemsState),
                      item.layoutType!,
                      false
                    );
                  }
                  // if (!inputsDisabled) {

                  // }
                }}
              >
                <TooltipHost content={tooltipContent(item, tripItemAdded)}>
                  <Stack className={styles.toggleButton}>
                    {tripItemAdded
                      ? getIcon(item.layoutType, TAB_SIZE)
                      : getOutlineIcon(item.layoutType, TAB_SIZE)}
                  </Stack>
                </TooltipHost>
              </Stack>
            );
          })}
        </Stack>
        <Stack verticalAlign="center">
          {tripInitiateFlowState === 'in-progress' && (
            <Text variant="large" style={{ textAlign: 'center' }}>
              (Sections can be added at any time)
            </Text>
          )}
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ padding: '100px 0px' }}
            className={
              tripInitiateFlowState === 'in-progress'
                ? styles.animationEntrance
                : styles.noVisibility
            }
          >
            <PrimaryButton
              onClick={() => {
                arr.forEach((value) => {
                  append(value[1]);
                });
                setTripInitiateFlow('ready');
              }}
              styles={{
                root: {
                  height: 60,
                  width: 180,
                  boxShadow: '0px 10px 20px  #3a7dc94d',
                },
                label: { fontSize: 22 },
              }}
              text="Continue"
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
