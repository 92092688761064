import { IconButton, Stack, Text } from '@fluentui/react';

import clsx from 'clsx';
import React from 'react';
import { useStyles } from './index.styles';
import { useTravelIcon } from '../../SectionToggle/utils';
import { SectionProps } from '../../..';

const TripTab: React.FC<{
  itemProps: SectionProps;
  onSelect: () => void;
  isSelected: boolean;
  onRemove: () => void;
  inputsDisabled: boolean;
}> = ({ onSelect, isSelected, itemProps, onRemove, inputsDisabled }) => {
  const styles = useStyles();

  const { getIcon } = useTravelIcon();
  return (
    <Stack
      horizontalAlign="space-between"
      horizontal
      className={clsx(
        styles.container,
        isSelected ? styles.selected : styles.unSelected
      )}
    >
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="start"
        tokens={{ childrenGap: 10 }}
        onClick={onSelect}
      >
        <Stack>{getIcon(itemProps?.layoutType)}</Stack>
        <Text>{itemProps?.name}</Text>
      </Stack>

      <Stack style={{ padding: '0px 0px 0px 10px' }}>
        {isSelected && (
          <IconButton
            disabled={inputsDisabled}
            onClick={onRemove}
            iconProps={{ iconName: 'cancel' }}
            styles={{ root: { borderRadius: 20 } }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export { TripTab };
