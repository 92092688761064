import { useReactiveVar } from '@apollo/client';
import {
  IChoiceGroupOption,
  IChoiceGroupStyles,
  IconButton,
  IDropdownOption,
  ITextFieldProps,
  Label,
  Separator,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import {
  AmountTextField,
  FormikChoiceGroup,
  FormikDropdown,
  FormikTextField,
} from 'common/components';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { setUserDefaults } from 'utility/cache/ui';
import {
  Traveler,
  Traveler_traveler_travelPolicy,
} from '../__generated__/Traveler';
import {
  TravelerCommonData_companyDepartments_nodes,
  TravelerCommonData_countries_nodes,
  TravelerCommonData_dietaryRequirements_nodes,
  TravelerCommonData_travelerCompanionMasters_nodes,
  TravelerCommonData_travelPolicies_nodes,
  TravelerCommonData_travelPolicies_nodes_travelPolicyAllowancesByTravelPolicyId_nodes,
  TravelerCommonData_userOccupationTitles_nodes,
} from '../__generated__/TravelerCommonData';
import { Address } from '../Address';
import { TravelerPolicyAllowanceRow, TravelerValues } from '../types';
import { DietaryRequirementsInput } from './DietaryRequirementsInput';
import { FareClasses } from './FareClasses';
import { useStyles } from './index.styles';
import {
  SelectedTypePolicy,
  TravelAllowancesCallout,
} from './TravelAllowancesCallout';
import { TravelerAlias } from './TravelerAlias';
import { OccupationViewModal } from 'settings/account/occupations/view/OccupationViewModal';

const fareClassOptions: IDropdownOption[] = [
  {
    key: 'isEconomyClassPreferred',
    text: 'Economy',
  },
  {
    key: 'isPremiumClassPreferred',
    text: 'Premium',
  },
  {
    key: 'isBusinessClassPreferred',
    text: 'Business',
  },
  {
    key: 'isFirstClassPreferred',
    text: 'First class',
  },
  {
    key: 'isCharterClassPreferred',
    text: 'Charter',
  },
];

interface BasicFormProps {
  traveler: Traveler | undefined;
  isEdit: boolean;
  userOccupationTitles: TravelerCommonData_userOccupationTitles_nodes[];
  dietaryRequirements: TravelerCommonData_dietaryRequirements_nodes[];
  companyDepartments: TravelerCommonData_companyDepartments_nodes[];
  travelPolicies: TravelerCommonData_travelPolicies_nodes[];
  travelerHosts: TravelerCommonData_travelerCompanionMasters_nodes[];
  countries: TravelerCommonData_countries_nodes[];
  travelPolicyDetails: Traveler_traveler_travelPolicy | null;
  inputsDisabled: boolean;
  isUpdatable: boolean;
  isUpdateTravelerOverride: boolean;
}
type TravelPolicyAllowances =
  TravelerCommonData_travelPolicies_nodes_travelPolicyAllowancesByTravelPolicyId_nodes;
export interface TravelPoliciesOptions extends IDropdownOption {
  key: string | number;
  text: string;
  isContractual: boolean;
  isCompanion: boolean;
  isDefaultCustomizable: boolean;
  companionTickets: number | null;
  companionAmount: string | null;
  isEconomyClassAllowed: boolean | null;
  isBusinessClassAllowed: boolean | null;
  isFirstClassAllowed: boolean | null;
  isPremiumClassAllowed: boolean | null;
  isCharterClassAllowed: boolean | null;
  currency: string | null | undefined;
  travelPolicyAllowances: TravelPolicyAllowances[];
}

interface TravelerHostsOptions extends IDropdownOption {
  travelerDepartmentId: string | null;
  isEconomyClassPreferred: boolean | null;
  isBusinessClassPreferred: boolean | null;
  isFirstClassPreferred: boolean | null;
  isPremiumClassPreferred: boolean | null;
  isCharterClassPreferred: boolean | null;
}
const choiceGroupStyles: IChoiceGroupStyles = {
  label: {
    display: 'inline',
  },
  flexContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
};

export enum SeatPreferenceOptions {
  Aisle = 'isIsleSeatPreferred',
  Window = 'isWindowSeatPreferred',
}

export enum FareClassOptions {
  Economy = 'Economy',
  Business = 'Business',
  First = 'First',
}

export type FaceClasses = Pick<
  TravelPoliciesOptions,
  | 'isEconomyClassAllowed'
  | 'isBusinessClassAllowed'
  | 'isFirstClassAllowed'
  | 'isPremiumClassAllowed'
  | 'isCharterClassAllowed'
>;

export type TravelerHostsFareClasses = Omit<
  TravelerHostsOptions,
  'travelerDepartmentId'
>;

export const BasicForm: React.FC<BasicFormProps> = ({
  traveler,
  isEdit,
  dietaryRequirements,
  userOccupationTitles,
  companyDepartments,
  travelPolicies,
  travelerHosts,
  countries,
  travelPolicyDetails,
  inputsDisabled,
  isUpdatable,
  isUpdateTravelerOverride,
}) => {
  const styles = useStyles();
  const userDefaults = useReactiveVar(setUserDefaults);
  const { _isTravelerEntryDepartmentOmitted, _isTravelerPolicyCollapsed } = {
    ...userDefaults,
  };
  const [expandPreferences, setExpandPreferences] = useState(
    !_isTravelerPolicyCollapsed
  );
  const [showOccupationModal, setShowOccupationModal] = useState(false);
  const [newOccupation, setNewOccupation] = useState<string | undefined>(
    undefined
  );

  const { values, setFieldValue } = useFormikContext<TravelerValues>();
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [showContractualInputRow, setShowContractualInputRow] = useState(false);
  const [showToCompanionDropdown, setShowToCompanionDropdown] = useState(false);
  const [disableFairClass, setDisableFairClass] = useState(false);

  const [disableContractualInputRow, setDisableContractualInputRow] =
    useState(false);
  const [selectedTypePolicy, setSelectedTypePolicy] =
    useState<SelectedTypePolicy>();

  const isDisabled = isUpdateTravelerOverride
    ? false
    : isUpdatable
    ? false
    : true;

  const options: IChoiceGroupOption[] = [
    { key: SeatPreferenceOptions.Aisle, text: 'Aisle' },
    { key: SeatPreferenceOptions.Window, text: 'Window' },
  ];
  const calloutId = useId(`callOutId`);
  const departmentOptions: IDropdownOption[] = companyDepartments.map(
    (type) => ({
      key: type.id,
      text: type.name || '',
    })
  );

  const userOccupationTitleOptions: IDropdownOption[] =
    userOccupationTitles.map((title) => ({
      key: title.id,
      text: title.userOccupationTitle || '',
    }));

  const travelerHostsOptions: TravelerHostsOptions[] = travelerHosts.map(
    (item) => ({
      key: item.id,
      text: item._fullName || '',
      travelerDepartmentId: item.travelerDepartmentId,
      isEconomyClassPreferred: item.isEconomyClassPreferred,
      isBusinessClassPreferred: item.isBusinessClassPreferred,
      isFirstClassPreferred: item.isFirstClassPreferred,
      isPremiumClassPreferred: item.isPremiumClassPreferred,
      isCharterClassPreferred: item.isCharterClassPreferred,
    })
  );

  const travelPoliciesOptions: TravelPoliciesOptions[] = travelPolicies.map(
    (type) => ({
      key: type.id,
      text: type.description || '',
      isContractual: type.isContractual || false,
      isCompanion: type.isCompanion || false,
      isDefaultCustomizable: type.isDefaultCustomizable || false,
      companionAmount: type.companionAmount,
      companionTickets: type.companionTickets,
      isBusinessClassAllowed: type.isBusinessClassAllowed,
      isEconomyClassAllowed: type.isEconomyClassAllowed,
      isFirstClassAllowed: type.isFirstClassAllowed,
      isPremiumClassAllowed: type.isPremiumClassAllowed,
      isCharterClassAllowed: type.isCharterClassAllowed,
      currency: type.companyCurrency?.isoCode,
      travelPolicyAllowances: type.travelPolicyAllowancesByTravelPolicyId.nodes,
    })
  );

  const onDismissCallout = () => {
    setCalloutVisible(false);
  };

  const getTravelAllowanceValue = (): string[] => {
    return (
      values.travelerPolicyAllowancesByTravelerId?.map((ele) =>
        ele.isChecked &&
        ele.expenditureType?.expenditureType &&
        ele.allowanceOverrideAmount
          ? `${ele.expenditureType?.expenditureType} - ${ele.allowanceOverrideAmount}, `
          : ele.isChecked && ele.expenditureType?.expenditureType
          ? `${ele.expenditureType?.expenditureType}, `
          : ''
      ) || []
    );
  };

  const onClearTypePolicy = async () => {
    await setFieldValue('travelerPolicyAllowancesByTravelerId', []);
    await setFieldValue('travelerCompanionTickets', null);
    await setFieldValue('travelerCompanionAmount', null);
    await setFieldValue('travelerCompanionMasterId', null);
    await clearFareClassValues();
    setSelectedTypePolicy(undefined);
    setShowContractualInputRow(false);
    setShowToCompanionDropdown(false);
    setDisableContractualInputRow(false);
    setDisableFairClass(false);
  };

  const onTravelPolicyChange = (selectedOption: TravelPoliciesOptions) => {
    setFieldValue('travelerPolicyAllowancesByTravelerId', []);
    const travelPolicyAllowancesArray: TravelerPolicyAllowanceRow[] =
      selectedOption.travelPolicyAllowances.map((ele) => ({
        id: ele.id,
        expenditureType: ele.expenditureType!,
        allowanceOverrideAmount: null,
        _allowanceDescription: ele._allowanceDescription!,
        isAllowanceCustomizable: ele.isAllowanceCustomizable,
        _travelerPolicyAllowanceRowTimestamp: null,
        _travelerPolicyAllowanceId: null,
        isChecked: false,
      }));
    if (travelPolicyAllowancesArray.length > 0) {
      setFieldValue('travelerPolicyAllowancesByTravelerId', [
        ...travelPolicyAllowancesArray,
      ]);
    }
    setFieldValue('travelerCompanionTickets', null);
    setFieldValue('travelerCompanionAmount', null);
    setFieldValue('travelerCompanionMasterId', null);
    setFareClassValue(selectedOption);
    setSelectedTypePolicy({
      text: selectedOption.text,
      isCompanion: selectedOption.isCompanion,
      isContractual: selectedOption.isContractual,
      isDefaultCustomizable: selectedOption.isDefaultCustomizable,
      currency: selectedOption.currency,
    });
    if (selectedOption.isContractual) {
      setShowContractualInputRow(true);
      setShowToCompanionDropdown(false);
      setFieldValue(
        'travelerCompanionTickets',
        selectedOption.companionTickets
      );
      setFieldValue('travelerCompanionAmount', selectedOption.companionAmount);
    } else if (selectedOption.isCompanion) {
      setShowToCompanionDropdown(true);
      setShowContractualInputRow(false);
    } else {
      setShowContractualInputRow(false);
      setShowToCompanionDropdown(false);
    }
    if (!selectedOption.isDefaultCustomizable) {
      setDisableContractualInputRow(true);
      setDisableFairClass(true);
    } else {
      setDisableContractualInputRow(false);
      setDisableFairClass(false);
    }
  };

  const onClearPrimaryTraveler = async () => {
    await clearFareClassValues();
  };

  const onToCompanionChange = async (selectedOption: TravelerHostsOptions) => {
    await setFieldValue(
      'travelerDepartmentId',
      selectedOption.travelerDepartmentId
    );
    await setCompanionFareClassValue(selectedOption);
  };

  const { isCompanion, isContractual, isDefaultCustomizable } = {
    ...travelPolicyDetails,
  };

  const fareClassKeys = [
    'isEconomyClassPreferred',
    'isFirstClassPreferred',
    'isBusinessClassPreferred',
    'isCharterClassPreferred',
    'isPremiumClassPreferred',
  ];

  const selectedFareClass = fareClassKeys.find((fareClass) => {
    const key = fareClass as keyof TravelerValues;
    return values[key] === true;
  });

  const clearFareClassValues = async () => {
    await setFieldValue('isBusinessClassPreferred', false);
    await setFieldValue('isEconomyClassPreferred', false);
    await setFieldValue('isFirstClassPreferred', false);
    await setFieldValue('isPremiumClassPreferred', false);
    await setFieldValue('isCharterClassPreferred', false);
  };

  const setFareClassValue = (selectedOption: FaceClasses) => {
    setFieldValue('isBusinessClassPreferred', false);
    setFieldValue('isEconomyClassPreferred', false);
    setFieldValue('isFirstClassPreferred', false);
    setFieldValue('isPremiumClassPreferred', false);
    setFieldValue('isCharterClassPreferred', false);
    switch (true) {
      case selectedOption.isBusinessClassAllowed:
        setFieldValue('isBusinessClassPreferred', true);
        break;
      case selectedOption.isEconomyClassAllowed:
        setFieldValue('isEconomyClassPreferred', true);
        break;
      case selectedOption.isFirstClassAllowed:
        setFieldValue('isFirstClassPreferred', true);
        break;
      case selectedOption.isPremiumClassAllowed:
        setFieldValue('isPremiumClassPreferred', true);
        break;
      case selectedOption.isCharterClassAllowed:
        setFieldValue('isCharterClassPreferred', true);
        break;
    }
  };

  const setCompanionFareClassValue = (
    selectedOption: TravelerHostsFareClasses
  ) => {
    setFieldValue('isBusinessClassPreferred', false);
    setFieldValue('isEconomyClassPreferred', false);
    setFieldValue('isFirstClassPreferred', false);
    setFieldValue('isPremiumClassPreferred', false);
    setFieldValue('isCharterClassPreferred', false);
    switch (true) {
      case selectedOption.isBusinessClassPreferred:
        setFieldValue('isBusinessClassPreferred', true);
        break;
      case selectedOption.isEconomyClassPreferred:
        setFieldValue('isEconomyClassPreferred', true);
        break;
      case selectedOption.isFirstClassPreferred:
        setFieldValue('isFirstClassPreferred', true);
        break;
      case selectedOption.isPremiumClassPreferred:
        setFieldValue('isPremiumClassPreferred', true);
        break;
      case selectedOption.isCharterClassPreferred:
        setFieldValue('isCharterClassPreferred', true);
        break;
    }
  };

  useEffect(() => {
    if (isEdit) {
      if (isContractual) {
        setShowContractualInputRow(true);
        setShowToCompanionDropdown(false);
      }
      if (isCompanion) {
        setShowToCompanionDropdown(true);
        setShowContractualInputRow(false);
      }
      if (!isContractual && !isCompanion) {
        setShowContractualInputRow(false);
        setShowToCompanionDropdown(false);
      }
      if (isDefaultCustomizable === false) {
        setDisableFairClass(true);
        setDisableContractualInputRow(true);
      } else {
        setDisableFairClass(false);
        setDisableContractualInputRow(false);
      }
    }
  }, [isEdit, isCompanion, isContractual, isDefaultCustomizable]);

  const onRenderLabel = (props: ITextFieldProps | undefined): JSX.Element => {
    return (
      <>
        <Stack horizontal verticalAlign="end" horizontalAlign="space-between">
          <Label disabled={props?.disabled}>{props?.label}</Label>
          {!!isEdit && <TravelerAlias traveler={traveler?.traveler} />}
        </Stack>
      </>
    );
  };

  useEffect(() => {
    if (newOccupation) {
      setFieldValue('userOccupationTitleId', newOccupation);
      setNewOccupation(undefined);
      setShowOccupationModal(false);
    }
  }, [newOccupation, setFieldValue]);

  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
        verticalAlign="baseline"
      >
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name="firstName"
            label="First Name"
            placeholder="Given Name"
            disabled={traveler?.traveler?._isAliasInvoked || !isUpdatable}
            onRenderLabel={onRenderLabel}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name="companyOrLastName"
            label="Last Name"
            placeholder="Family Name"
            disabled={traveler?.traveler?._isAliasInvoked || !isUpdatable}
            required
          />
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
        className={styles.formRow}
      >
        <Stack.Item className={styles.fieldHalfContainer}>
          <Stack horizontal>
            <Label>Occupation/Title</Label>
            <IconButton
              iconProps={{ iconName: 'AddTo' }}
              onClick={() => setShowOccupationModal(true)}
            />
          </Stack>
          {showOccupationModal && (
            <OccupationViewModal
              setNewOption={setNewOccupation}
              onClose={() => {
                if (!newOccupation) setShowOccupationModal(false);
              }}
            />
          )}
          <FormikDropdown
            placeholder="Select"
            options={userOccupationTitleOptions}
            name="userOccupationTitleId"
            disabled={isDisabled}
          />
        </Stack.Item>
        {!_isTravelerEntryDepartmentOmitted && (
          <Stack.Item className={styles.fieldHalfContainer}>
            <FormikDropdown
              label="Department"
              placeholder="Select"
              options={departmentOptions}
              name="travelerDepartmentId"
              disabled={isDisabled}
              onClear={() => setFieldValue('travelerDepartmentId', null)}
            />
          </Stack.Item>
        )}
      </Stack>
      <Separator />
      <Address
        isEdit={isEdit}
        countries={countries}
        inputsDisabled={!isUpdatable}
      />
      <Separator />

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Text variant="xLarge" className={styles.travelPreferencesHeading}>
          Travel Preferences
        </Text>
        {!expandPreferences ? (
          <IconButton
            onClick={() => setExpandPreferences(true)}
            iconProps={{ iconName: 'ChevronDown' }}
            className={styles.iconButtonColor}
          />
        ) : (
          <IconButton
            onClick={() => setExpandPreferences(false)}
            iconProps={{ iconName: 'ChevronUp' }}
            className={styles.iconButtonColor}
          />
        )}
      </Stack>
      {expandPreferences && (
        <>
          <Stack
            horizontal
            tokens={{
              childrenGap: 20,
            }}
            className={styles.formRow}
          >
            <Stack
              horizontal
              className={styles.halfPanelWidth}
              verticalAlign={'end'}
              tokens={{
                childrenGap: 5,
              }}
            >
              <Stack.Item grow>
                <TooltipHost content={getTravelAllowanceValue().join('')}>
                  <FormikDropdown
                    label="Travel Policy"
                    placeholder="Select"
                    options={travelPoliciesOptions}
                    name="travelPolicyId"
                    disabled={isDisabled}
                    onChange={(_e, option) => {
                      const selectedOption = option as TravelPoliciesOptions;
                      onTravelPolicyChange(selectedOption);
                    }}
                    onClear={onClearTypePolicy}
                  />
                </TooltipHost>
              </Stack.Item>
              <Stack.Item>
                <TooltipHost content="Allowances">
                  <IconButton
                    onClick={() => setCalloutVisible((prevState) => !prevState)}
                    iconProps={{ iconName: 'EntitlementRedemption' }}
                    id={calloutId}
                    disabled={isDisabled || !values.travelPolicyId}
                  />
                </TooltipHost>
                {calloutVisible && (
                  <TravelAllowancesCallout
                    calloutId={calloutId}
                    dismissCallout={setCalloutVisible}
                    closeCallout={onDismissCallout}
                    isCalloutVisible={calloutVisible}
                    inputsDisabled={isDisabled}
                    selectedTypePolicy={selectedTypePolicy}
                  />
                )}
              </Stack.Item>
            </Stack>
            <Stack.Item className={styles.fieldContainer}>
              <Stack tokens={{ childrenGap: 10 }}>
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  tokens={{ childrenGap: 20 }}
                >
                  <Stack.Item className={styles.fieldContainer}>
                    <FareClasses
                      options={fareClassOptions}
                      selectedFareClass={selectedFareClass}
                      disabled={isDisabled || disableFairClass}
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
            </Stack.Item>
          </Stack>
          {showContractualInputRow && (
            <Stack className={styles.formRow}>
              <Stack
                horizontal
                className={styles.halfPanelWidth}
                tokens={{
                  childrenGap: 16,
                }}
              >
                <Stack.Item className={styles.fieldContainer}>
                  <AmountTextField
                    name="travelerCompanionTickets"
                    label="Granted Tickets"
                    placeholder="Granted Tickets"
                    decimalScale={0}
                    allowNegative={false}
                    disabled={disableContractualInputRow}
                  />
                </Stack.Item>
                <Stack.Item className={styles.fieldContainer}>
                  <AmountTextField
                    name="travelerCompanionAmount"
                    label="Amount"
                    placeholder="Amount"
                    disabled={disableContractualInputRow}
                  />
                </Stack.Item>
              </Stack>
            </Stack>
          )}
          {showToCompanionDropdown && (
            <Stack className={styles.formRow}>
              <Stack
                horizontal
                className={styles.halfPanelWidth}
                tokens={{
                  childrenGap: 16,
                }}
              >
                <Stack.Item className={styles.fieldContainer}>
                  <FormikDropdown
                    label="Primary Traveler"
                    placeholder="Select"
                    options={travelerHostsOptions}
                    name="travelerCompanionMasterId"
                    onChange={(_e, option) => {
                      const selectedOption = option as TravelerHostsOptions;
                      onToCompanionChange(selectedOption);
                    }}
                    onClear={onClearPrimaryTraveler}
                  />
                </Stack.Item>
              </Stack>
            </Stack>
          )}
          <Separator />
          <DietaryRequirementsInput
            dietaryRequirements={dietaryRequirements}
            inputsDisabled={!isUpdatable}
          />
          <Separator />
          <Stack
            horizontal
            verticalAlign="baseline"
            tokens={{
              childrenGap: 20,
            }}
            className={clsx(styles.formRow)}
          >
            <Stack.Item className={styles.fieldContainer}>
              <FormikTextField
                name="frequentFlyerNumber"
                label="Frequent Flyer"
                placeholder="Number(s)"
                disabled={!isUpdatable}
              />
            </Stack.Item>
            <Stack.Item className={styles.fieldContainer}>
              <Stack tokens={{ childrenGap: 10 }}>
                <Stack horizontalAlign="center">
                  <Text className={styles.heading}>Seating Preference</Text>
                </Stack>
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  tokens={{ childrenGap: 20 }}
                >
                  <Stack.Item className={styles.fieldContainer}>
                    <FormikChoiceGroup
                      name="seatPreference"
                      options={options}
                      styles={choiceGroupStyles}
                      value={values.seatPreference!}
                      disabled={!isUpdatable}
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
            </Stack.Item>
          </Stack>
        </>
      )}
    </Stack>
  );
};
