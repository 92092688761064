import * as Yup from 'yup';
export const validationSchema = () =>
  Yup.object({
    companyOrLastName: Yup.string().required('Required').nullable(),
    supplierAddressesBySupplierId: Yup.array().of(
      Yup.object().shape({
        emailAddress: Yup.string()
          .email('Enter a valid email address')
          .nullable(),
      })
    ),
  });
