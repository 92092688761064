import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar
} from '@apollo/client';
import {
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { SuccessButton } from 'common/components/SuccessButton';
import { WarningModal } from 'common/components/WarningModal';
import {
  TravelAuthorizationDocumentPackageStatus,
  TravelAuthorizationDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/TravelAuthorizationDocumentPackageStatus';
import {
  DocumentPackageStatusType,
  ProcessRequestInput,
} from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { setUserDefaults } from 'utility/cache/ui';
import { TravelAuthorizationItem } from '..';
import { TACompanyCorporatePeriods } from './__generated__/TACompanyCorporatePeriods';
import {
  TravelAuthorizationEntryStampProcessing,
  TravelAuthorizationEntryStampProcessingVariables,
} from './__generated__/TravelAuthorizationEntryStampProcessing';
const TRAVEL_AUTHORIZATION_STAMP_PROCESSING = loader(
  './TravelAuthorizationEntryStampProcessing.graphql'
);
const TA_COMPANY_CORPORATE_PERIODS = loader(
  './TACompanyCorporatePeriods.graphql'
);
const TRAVEL_AUTHORIZATION_DOCUMENT_PACKAGE_STATUS = loader(
  '../../../../common/graphql/DocumentPackageSubscription/TravelAuthorizationDocumentPackageStatus.graphql'
);

interface responseProps {
  itemRef: string;
  message: string;
}

interface StampTravelPlanFooterProps {
  isStampTransactionMode: boolean;
  rows: TravelAuthorizationItem[] | undefined;
  rowsStampable: TravelAuthorizationItem[] | undefined;
  stampFields: Map<string, string>;
  onStampProcessComplete: (success?: boolean) => void;
}

export const StampTravelPlanFooter: React.FC<StampTravelPlanFooterProps> = ({
  isStampTransactionMode,
  rows,
  rowsStampable,
  stampFields,
  onStampProcessComplete,
}) => {
  const client = useApolloClient();
  const { addToast } = useToasts();
  const userDefaults = useReactiveVar(setUserDefaults);
  const [corporatePeriod, setCorporatePeriod] = useState<IDropdownOption>();
  const [visible, setVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState<responseProps[]>([]);
  const [warningVisible, setWarningVisible] = useState<responseProps[]>([]);

  const { data: commonData } = useQuery<TACompanyCorporatePeriods>(
    TA_COMPANY_CORPORATE_PERIODS
  );
  const { companyCorporatePeriods } = { ...commonData };

  const [stampEntry, { loading }] = useMutation<
    TravelAuthorizationEntryStampProcessing,
    TravelAuthorizationEntryStampProcessingVariables
  >(TRAVEL_AUTHORIZATION_STAMP_PROCESSING, { errorPolicy: 'all' });

  const companyCorporatePeriodOptions: IDropdownOption[] =
    companyCorporatePeriods?.nodes.map((item) => ({
      disabled: item.isEntryAllowed ? false : true,
      key: item.id,
      text:
        item._periodYear +
        `(${
          item.startDate! ? dateFormat(dateConvertions(item.startDate!)) : ''
        } - ${
          item.endDate! ? dateFormat(dateConvertions(item.endDate!)) : ''
        })`,
    })) || [];

  useEffect(() => {
    const selected = companyCorporatePeriodOptions.find(
      (item) => item.key === userDefaults?.companyCorporatePeriod?.id
    );
    if (selected && !corporatePeriod) setCorporatePeriod(selected);
  }, [companyCorporatePeriodOptions, corporatePeriod, userDefaults]);

  const stampFieldsArray = Array.from(stampFields);
  const isConfirmable = stampFieldsArray.length > 0;

  const onConfirm = async () => {
    const processRequestsData: ProcessRequestInput[] =
      rowsStampable
        ?.filter((item) => stampFields.has(item.id))
        .map(
          (item) =>
            ({
              id: item.id,
              reference: stampFields.get(item.id),
              rowTimestamp: item._rowTimestamp,
            } as ProcessRequestInput)
        ) || [];
    const { errors, data: serverResponse } = await stampEntry({
      variables: {
        input: {
          corporatePeriodId: corporatePeriod?.key.toString()!,
          processRequests: processRequestsData,
        },
      },
      update(cache, { data }) {
        const successRequest =
          data?.travelAuthorizationEntryStampProcessing?.processRequests?.filter(
            (item) => item?.isSuccess
          );
        if (successRequest) {
          const sucessMapValue = new Map(
            successRequest.map((obj) => [obj?.id, obj])
          );
          const existingInvoices = rows?.filter((item) =>
            sucessMapValue.has(item.id)
          );
          existingInvoices?.forEach((item, index) => {
            const responseItem = sucessMapValue.get(item.id);
            cache.modify({
              id: `TravelAuthorization:${item.id}`,
              fields: {
                statusType(existing) {
                  return { ...existing, statusType: 'Entered' };
                },
                _rowTimestamp() {
                  return responseItem?.rowTimestamp;
                },
                _isTransactionCancelled() {
                  return false;
                },
                _accountingStampTransactionReference() {
                  return responseItem?.reference;
                },
                _isAccountingEntryStampedComplete() {
                  return true;
                },
              },
            });
            const observer = client.subscribe<
              TravelAuthorizationDocumentPackageStatus,
              TravelAuthorizationDocumentPackageStatusVariables
            >({
              query: TRAVEL_AUTHORIZATION_DOCUMENT_PACKAGE_STATUS,
              variables: {
                id: item?.id,
              },
            });
            const subscription = observer.subscribe(({ data, errors }) => {
              if (errors)
                addToast(
                  'Errors received while Subscribing to document package',
                  { appearance: 'error' }
                );
              else {
                const { document, status } = {
                  ...data?.travelAuthorizationDocumentPackageStatus,
                };
                if (status === DocumentPackageStatusType.REGENERATION_FAILURE) {
                  addToast(
                    'Report generation failed. Document package was not created',
                    { appearance: 'error' }
                  );
                }
                if (status === DocumentPackageStatusType.FAILURE) {
                  addToast('Error while creating Document package ', {
                    appearance: 'error',
                  });
                }
                if (document) {
                  client.cache.modify({
                    id: client.cache.identify({ ...item }),
                    fields: {
                      _documentPackageId: () => {
                        return document.id;
                      },
                    },
                  });
                }
              }
              subscription.unsubscribe();
            });
          });
        }
      },
    });
    const { processRequests } = {
      ...serverResponse?.travelAuthorizationEntryStampProcessing,
    };
    if (errors?.length) {
      addToast(errors[0].message, { appearance: 'error' });
      setVisible(false);
    } else {
      if (processRequests) {
        const warningsMessages = processRequests
          .filter((item) => item?.isWarning)
          .map(
            (item) =>
              ({
                itemRef: item?.reference,
                message: item?.message || '',
              } as responseProps)
          );
        if (warningsMessages) setWarningVisible(warningsMessages || []);
        const errorMessages = processRequests
          .filter((item) => item?.isFail)
          .map(
            (item) =>
              ({
                itemRef: item?.reference,
                message: item?.message || '',
              } as responseProps)
          );
        if (errorMessages) setErrorVisible(errorMessages || []);
      }
      setVisible(false);
      onStampProcessComplete(true);
      addToast('Travel plan stamped successfully', { appearance: 'success' });
    }
  };

  const showDialog: boolean =
    errorVisible?.length! > 0 || warningVisible.length > 0;

  return (
    <Stack>
      {isStampTransactionMode && (
        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Stack
            horizontal
            horizontalAlign="end"
            verticalAlign="center"
            tokens={{ childrenGap: 10, padding: '20px 50px' }}
          >
            <Text>Enter transaction # and press</Text>
            <PrimaryButton
              text="Confirm Stamp"
              disabled={!isConfirmable}
              onClick={() => setVisible(true)}
              styles={{ root: { width: 200 } }}
            />
            <Text>Or</Text>
            <WarningModal
              isDataEntered={isConfirmable}
              onCancel={() => onStampProcessComplete()}
            />
          </Stack>
        </Sticky>
      )}
      {visible && (
        <>
          <Dialog
            dialogContentProps={{
              title:
                'Are you sure you want to stamp this travel plan as Entered?',
            }}
            hidden={false}
            minWidth={500}
            modalProps={{
              isBlocking: false,
              dragOptions: {
                moveMenuItemText: 'Move',
                closeMenuItemText: 'Close',
                menu: ContextualMenu,
                keepInBounds: true,
              },
            }}
          >
            <Stack tokens={{ childrenGap: 10, padding: '0px 0px 20px 0px' }}>
              <CustomDropdown
                label="Accounting Period"
                placeholder="Select"
                selectedKey={corporatePeriod ? corporatePeriod.key : null}
                options={companyCorporatePeriodOptions}
                onChange={(_, option) => {
                  setCorporatePeriod(option);
                }}
                onClear={() => {
                  setCorporatePeriod(undefined);
                }}
                notifyOnReselect
              />
            </Stack>
            {loading && <ProgressIndicator />}
            <DialogFooter>
              <SuccessButton
                text="Confirm"
                onClick={onConfirm}
                disabled={loading}
              />
              <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
            </DialogFooter>
          </Dialog>
        </>
      )}
      {showDialog && (
        <Dialog
          dialogContentProps={{
            title: 'Results',
          }}
          hidden={false}
          minWidth={500}
          modalProps={{
            isBlocking: false,
            dragOptions: {
              moveMenuItemText: 'Move',
              closeMenuItemText: 'Close',
              menu: ContextualMenu,
              keepInBounds: true,
            },
          }}
        >
          <Stack tokens={{ childrenGap: 10 }}>
            {errorVisible.map((item) => {
              return (
                <Stack horizontal>
                  <MessageBar messageBarType={MessageBarType.error}>
                    {item.message}
                  </MessageBar>
                </Stack>
              );
            })}
            {warningVisible.map((item) => {
              return (
                <Stack horizontal>
                  <MessageBar messageBarType={MessageBarType.warning}>
                    {item.message}
                  </MessageBar>
                </Stack>
              );
            })}
            <DialogFooter>
              <DefaultButton
                onClick={() => {
                  setErrorVisible([]);
                  onStampProcessComplete();
                }}
                text="Close"
              />
            </DialogFooter>
          </Stack>
        </Dialog>
      )}
    </Stack>
  );
};
