import {
  DatePicker,
  IconButton,
  IDatePicker,
  IDatePickerProps,
  makeStyles,
  Stack,
} from '@fluentui/react';
import React from 'react';
interface CustomDatePickerProps extends IDatePickerProps {
  onClear: () => void;
}
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
    alignSelf: 'end',
    margin: '0px 4px',
  },
}));
export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  onClear,
  ...props
}) => {
  const styles = useStyles();
  const datePickerRef = React.createRef<IDatePicker>();
  const onRemoveFocus = () => datePickerRef.current!.reset();

  function parseDateFromString(text: string): Date {
    // Get the current date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1

    // Regex to match the date format "M/d/yyyy"
    const regex = /^(\d{1,2})\/(\d{1,2})(?:\/(\d{4}))?$/;
    const match = text.match(regex);

    if (match) {
      // Extract the month, day, and year
      const month: string = match[1]; // Month as string
      const day: string = match[2]; // Day as string
      const year: string | undefined = match[3]; // Year as string (optional)

      // Convert the month, day, and year to numbers
      const parsedMonth = month ? parseInt(month, 10) : currentMonth;
      const parsedDay = day ? parseInt(day, 10) : 1;
      const parsedYear = year ? parseInt(year, 10) : currentYear;

      // Return the Date object
      return new Date(parsedYear, parsedMonth - 1, parsedDay); // Month is 0-indexed
    }

    // If no valid date is found, return the current date with 1st day of the current month
    return new Date(currentYear, currentMonth - 1, 1);
  }

  return (
    <Stack
      horizontal
      // verticalAlign="baseline"
      verticalAlign="end"
      tokens={{ childrenGap: 4 }}
    >
      <Stack.Item grow={1}>
        <DatePicker
          allowTextInput
          componentRef={datePickerRef}
          {...props}
          parseDateFromString={parseDateFromString}
        />
      </Stack.Item>
      <Stack>
        {props.value && !props.isRequired && !props.disabled && (
          <IconButton
            type="button"
            onClick={() => {
              onRemoveFocus();
              onClear();
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        )}
      </Stack>
    </Stack>
  );
};
