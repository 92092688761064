import {
  IDropdownOption,
  IconButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import {
  FormHookAmount,
  FormHookDropdown,
} from 'common/components/FormHooksFields';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TravelerPolicyAllowanceRow, TravelerValues } from '../interfaces';
import { FareClasses } from './FareClasses';
import {
  SelectedTypePolicy,
  TravelAllowancesCallout,
} from './TravelAllowancesCallout';
import { useStyles } from './index.styles';
import {
  FaceClasses,
  TravelPoliciesOptions,
  TravelPreferencesProps,
  TravelerHostsFareClasses,
  TravelerHostsOptions,
} from './types';

const fareClassOptions: IDropdownOption[] = [
  {
    key: 'isEconomyClassPreferred',
    text: 'Economy',
  },
  {
    key: 'isPremiumClassPreferred',
    text: 'Premium',
  },
  {
    key: 'isBusinessClassPreferred',
    text: 'Business',
  },
  {
    key: 'isFirstClassPreferred',
    text: 'First class',
  },
  {
    key: 'isCharterClassPreferred',
    text: 'Charter',
  },
];

export enum FareClassOptions {
  Economy = 'isEconomyClassPreferred',
  Business = 'isBusinessClassPreferred',
  First = 'isFirstClassPreferred',
  Charter = 'isCharterClassPreferred',
  Premium = 'isPremiumClassPreferred',
}

export const TravelPreferences: React.FC<TravelPreferencesProps> = ({
  isEdit,
  commonData,
  travelPolicyDetails,
  isUpdatable,
  isUpdateTravelerOverride,
  expandPreferences,
  setExpandPreferences,
}) => {
  const styles = useStyles();
  const calloutId = useId(`callOutId`);
  const { travelPolicies, travelerCompanionMasters } = { ...commonData };
  const [selectedTypePolicy, setSelectedTypePolicy] =
    useState<SelectedTypePolicy>();
  const [showContractualInputRow, setShowContractualInputRow] = useState(false);
  const [showToCompanionDropdown, setShowToCompanionDropdown] = useState(false);
  const [disableContractualInputRow, setDisableContractualInputRow] =
    useState(false);
  const [disableFairClass, setDisableFairClass] = useState(false);
  const [calloutVisible, setCalloutVisible] = useState(false);

  const { setValue, control } = useFormContext<TravelerValues>();
  const travelPolicyId = useWatch({ name: 'travelPolicyId', control });

  const fareClassKeys = [
    'isEconomyClassPreferred',
    'isFirstClassPreferred',
    'isBusinessClassPreferred',
    'isCharterClassPreferred',
    'isPremiumClassPreferred',
  ];

  const values = useWatch();
  const selectedFareClass = fareClassKeys.find((key) => values[key] === true);

  const travelPoliciesOptions: TravelPoliciesOptions[] =
    travelPolicies?.nodes.map((type) => ({
      key: type.id,
      text: type.description || '',
      isContractual: type.isContractual || false,
      isCompanion: type.isCompanion || false,
      isDefaultCustomizable: type.isDefaultCustomizable || false,
      companionAmount: type.companionAmount,
      companionTickets: type.companionTickets,
      isBusinessClassAllowed: type.isBusinessClassAllowed,
      isEconomyClassAllowed: type.isEconomyClassAllowed,
      isFirstClassAllowed: type.isFirstClassAllowed,
      isPremiumClassAllowed: type.isPremiumClassAllowed,
      isCharterClassAllowed: type.isCharterClassAllowed,
      currency: type.companyCurrency?.isoCode,
      travelPolicyAllowances: type.travelPolicyAllowancesByTravelPolicyId.nodes,
    })) || [];

  const travelerHostsOptions: TravelerHostsOptions[] =
    travelerCompanionMasters?.nodes.map((item) => ({
      key: item.id,
      text: item._fullName || '',
      travelerDepartmentId: item.travelerDepartmentId,
      isEconomyClassPreferred: item.isEconomyClassPreferred,
      isBusinessClassPreferred: item.isBusinessClassPreferred,
      isFirstClassPreferred: item.isFirstClassPreferred,
      isPremiumClassPreferred: item.isPremiumClassPreferred,
      isCharterClassPreferred: item.isCharterClassPreferred,
    })) || [];

  const onTravelPolicyChange = (selectedOption: TravelPoliciesOptions) => {
    setValue('travelerPolicyAllowancesByTravelerId', []);
    const travelPolicyAllowancesArray: TravelerPolicyAllowanceRow[] =
      selectedOption.travelPolicyAllowances.map((ele) => ({
        id: ele.id,
        expenditureType: ele.expenditureType!,
        allowanceOverrideAmount: null,
        _allowanceDescription: ele._allowanceDescription!,
        isAllowanceCustomizable: ele.isAllowanceCustomizable,
        _travelerPolicyAllowanceRowTimestamp: null,
        _travelerPolicyAllowanceId: null,
        isChecked: !selectedOption.isDefaultCustomizable,
      }));
    if (travelPolicyAllowancesArray.length > 0) {
      setValue('travelerPolicyAllowancesByTravelerId', [
        ...travelPolicyAllowancesArray,
      ]);
    }
    setValue('travelerCompanionTickets', null);
    setValue('travelerCompanionAmount', null);
    setValue('travelerCompanionMasterId', null);
    setFareClassValue(selectedOption);

    setSelectedTypePolicy({
      text: selectedOption.text,
      isCompanion: selectedOption.isCompanion,
      isContractual: selectedOption.isContractual,
      isDefaultCustomizable: selectedOption.isDefaultCustomizable,
      currency: selectedOption.currency,
    });
    if (selectedOption.isContractual) {
      setShowContractualInputRow(true);
      setShowToCompanionDropdown(false);
      setValue('travelerCompanionTickets', selectedOption.companionTickets);
      setValue('travelerCompanionAmount', selectedOption.companionAmount);
    } else if (selectedOption.isCompanion) {
      setShowToCompanionDropdown(true);
      setShowContractualInputRow(false);
    } else {
      setShowContractualInputRow(false);
      setShowToCompanionDropdown(false);
    }
    if (!selectedOption.isDefaultCustomizable) {
      setDisableContractualInputRow(true);
      setDisableFairClass(true);
    } else {
      setDisableContractualInputRow(false);
      setDisableFairClass(false);
    }
  };

  const onClearTypePolicy = async () => {
    await setValue('travelerPolicyAllowancesByTravelerId', []);
    await setValue('travelerCompanionTickets', null);
    await setValue('travelerCompanionAmount', null);
    await setValue('travelerCompanionMasterId', null);
    await clearFareClassValues();
    setSelectedTypePolicy(undefined);
    setShowContractualInputRow(false);
    setShowToCompanionDropdown(false);
    setDisableContractualInputRow(false);
    setDisableFairClass(false);
  };

  const onDismissCallout = () => {
    setCalloutVisible(false);
  };

  const onToCompanionChange = async (selectedOption: TravelerHostsOptions) => {
    await setValue('travelerDepartmentId', selectedOption.travelerDepartmentId);
    await setCompanionFareClassValue(selectedOption);
  };

  const onClearPrimaryTraveler = async () => {
    await clearFareClassValues();
  };

  const setFareClassValue = (selectedOption: FaceClasses) => {
    setValue('isBusinessClassPreferred', false);
    setValue('isEconomyClassPreferred', false);
    setValue('isFirstClassPreferred', false);
    setValue('isPremiumClassPreferred', false);
    setValue('isCharterClassPreferred', false);
    switch (true) {
      case selectedOption.isBusinessClassAllowed:
        setValue('isBusinessClassPreferred', true);
        break;
      case selectedOption.isEconomyClassAllowed:
        setValue('isEconomyClassPreferred', true);
        break;
      case selectedOption.isFirstClassAllowed:
        setValue('isFirstClassPreferred', true);
        break;
      case selectedOption.isPremiumClassAllowed:
        setValue('isPremiumClassPreferred', true);
        break;
      case selectedOption.isCharterClassAllowed:
        setValue('isCharterClassPreferred', true);
        break;
    }
  };

  const setCompanionFareClassValue = (
    selectedOption: TravelerHostsFareClasses
  ) => {
    setValue('isBusinessClassPreferred', false);
    setValue('isEconomyClassPreferred', false);
    setValue('isFirstClassPreferred', false);
    setValue('isPremiumClassPreferred', false);
    setValue('isCharterClassPreferred', false);
    switch (true) {
      case selectedOption.isBusinessClassPreferred:
        setValue('isBusinessClassPreferred', true);
        break;
      case selectedOption.isEconomyClassPreferred:
        setValue('isEconomyClassPreferred', true);
        break;
      case selectedOption.isFirstClassPreferred:
        setValue('isFirstClassPreferred', true);
        break;
      case selectedOption.isPremiumClassPreferred:
        setValue('isPremiumClassPreferred', true);
        break;
      case selectedOption.isCharterClassPreferred:
        setValue('isCharterClassPreferred', true);
        break;
    }
  };

  const clearFareClassValues = () => {
    setValue('isBusinessClassPreferred', false);
    setValue('isEconomyClassPreferred', false);
    setValue('isFirstClassPreferred', false);
    setValue('isPremiumClassPreferred', false);
    setValue('isCharterClassPreferred', false);
  };

  const { isCompanion, isContractual, isDefaultCustomizable } = {
    ...travelPolicyDetails,
  };

  useEffect(() => {
    if (isEdit) {
      if (isContractual) {
        setShowContractualInputRow(true);
        setShowToCompanionDropdown(false);
      }
      if (isCompanion) {
        setShowToCompanionDropdown(true);
        setShowContractualInputRow(false);
      }
      if (!isContractual && !isCompanion) {
        setShowContractualInputRow(false);
        setShowToCompanionDropdown(false);
      }
      if (isDefaultCustomizable === false) {
        setDisableFairClass(true);
        setDisableContractualInputRow(true);
      } else {
        setDisableFairClass(false);
        setDisableContractualInputRow(false);
      }
    }
  }, [isEdit, isCompanion, isContractual, isDefaultCustomizable]);

  const isDisabled = isUpdateTravelerOverride
    ? false
    : isUpdatable
    ? false
    : true;

  useEffect(() => {
    if (travelPolicyDetails) {
      const {
        description,
        isCompanion,
        isContractual,
        isDefaultCustomizable,
        companyCurrency,
      } = { ...travelPolicyDetails };
      setSelectedTypePolicy({
        text: description,
        isCompanion: isCompanion!,
        isContractual: isContractual!,
        isDefaultCustomizable: isDefaultCustomizable!,
        currency: companyCurrency?.isoCode,
      });
    }
  }, [travelPolicyDetails]);

  return (
    <Stack style={{ margin: '0px 20px' }}>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Text variant="xLarge" className={styles.travelPreferencesHeading}>
          Travel Preferences
        </Text>
        {!expandPreferences ? (
          <IconButton
            onClick={() => setExpandPreferences(true)}
            iconProps={{ iconName: 'ChevronDown' }}
            className={styles.iconButtonColor}
          />
        ) : (
          <IconButton
            onClick={() => setExpandPreferences(false)}
            iconProps={{ iconName: 'ChevronUp' }}
            className={styles.iconButtonColor}
          />
        )}
      </Stack>
      {expandPreferences && (
        <>
          <Stack
            horizontal
            tokens={{
              childrenGap: 20,
            }}
          >
            <Stack
              horizontal
              className={styles.halfPanelWidth}
              verticalAlign={'end'}
              tokens={{
                childrenGap: 5,
              }}
            >
              <Stack style={{ width: '80%' }}>
                <FormHookDropdown
                  label="Travel Policy"
                  placeholder="Select"
                  options={travelPoliciesOptions}
                  name="travelPolicyId"
                  onChange={(_e, option) => {
                    const selectedOption = option as TravelPoliciesOptions;
                    onTravelPolicyChange(selectedOption);
                  }}
                  onClear={onClearTypePolicy}
                  disabled={isDisabled}
                />
              </Stack>
              <Stack style={{ width: '20%' }}>
                <TooltipHost content="Allowances">
                  <IconButton
                    onClick={() => setCalloutVisible((prevState) => !prevState)}
                    iconProps={{ iconName: 'EntitlementRedemption' }}
                    id={calloutId}
                    disabled={isDisabled || !travelPolicyId}
                  />
                </TooltipHost>
                {calloutVisible && (
                  <TravelAllowancesCallout
                    calloutId={calloutId}
                    dismissCallout={setCalloutVisible}
                    closeCallout={onDismissCallout}
                    isCalloutVisible={calloutVisible}
                    inputsDisabled={isDisabled}
                    selectedTypePolicy={selectedTypePolicy}
                  />
                )}
              </Stack>
            </Stack>
            <Stack className={styles.fieldContainer}>
              <Stack tokens={{ childrenGap: 10 }}>
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  tokens={{ childrenGap: 20 }}
                >
                  <Stack.Item className={styles.fieldContainer}>
                    <FareClasses
                      options={fareClassOptions}
                      selectedFareClass={selectedFareClass}
                      disabled={isDisabled || disableFairClass}
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            {showContractualInputRow && (
              <Stack className={styles.formRow}>
                <Stack
                  horizontal
                  className={styles.halfPanelWidth}
                  tokens={{
                    childrenGap: 16,
                  }}
                >
                  <Stack.Item className={styles.fieldContainer}>
                    <FormHookAmount
                      name="travelerCompanionTickets"
                      label="Granted Tickets"
                      placeholder="Granted Tickets"
                      decimalScale={0}
                      allowNegative={false}
                      disabled={disableContractualInputRow}
                    />
                  </Stack.Item>
                  <Stack.Item className={styles.fieldContainer}>
                    <FormHookAmount
                      name="travelerCompanionAmount"
                      label="Amount"
                      placeholder="Amount"
                      disabled={disableContractualInputRow}
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
            )}
            {showToCompanionDropdown && (
              <Stack className={styles.formRow}>
                <Stack
                  horizontal
                  className={styles.halfPanelWidth}
                  tokens={{
                    childrenGap: 16,
                  }}
                >
                  <Stack.Item className={styles.fieldContainer}>
                    <FormHookDropdown
                      label="Primary Traveler"
                      placeholder="Select"
                      options={travelerHostsOptions}
                      name="travelerCompanionMasterId"
                      onChange={(_e, option) => {
                        const selectedOption = option as TravelerHostsOptions;
                        onToCompanionChange(selectedOption);
                      }}
                      onClear={onClearPrimaryTraveler}
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};
