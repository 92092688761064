import { useQuery, useReactiveVar } from '@apollo/client';
import {
  Checkbox,
  ChoiceGroup,
  Dropdown,
  FontSizes,
  FontWeights,
  ICheckboxStyles,
  IChoiceGroupOption,
  IChoiceGroupStyles,
  IconButton,
  IDropdownOption,
  IDropdownStyleProps,
  IDropdownStyles,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react';
import { GetApprovalCommonData } from 'approvals/__generated__/GetApprovalCommonData';
import {
  branchUserList,
  branchUserList_branchUserList_nodes,
} from 'approvals/ApprovalsAllUsers/__generated__/branchUserList';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { SearchBar, SearchBarProps } from 'common/components/SearchBar';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { globalMode } from 'utility/cache/ui';
import { useStyles } from './index.styles';

const COMMON_DATA = loader('../GetApprovalCommonData.graphql');
const BRANCH_USER_LIST = loader('../ApprovalsAllUsers/BranchUserList.graphql');

interface ApprovalSearchBarProps extends SearchBarProps, MenuActionBarProps {
  onFilterChange?: (filterarray: FilterArrayType[]) => void;
}

export const ApprovalSearchBar: React.FC<ApprovalSearchBarProps> = ({
  columns,
  onFilterChange,
  onEnterPress,
  hintsAvailable,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  onHintsViewToggle,
  onHistoryToogle,
  onQueuedToogle,
  onActionPendingToggle,
  urlParams,
  onGlobalToggle,
  isGlobalAvailable,
  actionPendingFilter,
  ...props
}) => {
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const { data: commonData } = useQuery<GetApprovalCommonData>(COMMON_DATA, {
    nextFetchPolicy: 'cache-only',
  });

  const businessUnitOptions = formatDropdownOptions(
    commonData?.companyBusinessUnits?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );

  const departmentOptions = formatDropdownOptions(
    commonData?.availableApprovalDepartments?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );

  const statusTypeOptions = formatDropdownOptions(
    commonData?.approvalIconTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.iconStatusType!,
      includeAll: true,
    }
  );

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      urlParams={urlParams}
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange?.([]);
        setFilterTypes([]);
      }}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      hintsAvailable={hintsAvailable}
      onHintsViewToggle={onHintsViewToggle}
      onToggleVisibility={onToggleVisibility}
      onGlobalToggle={onGlobalToggle}
      isGlobalAvailable={isGlobalAvailable}
      renderActions={
        <MenuActionBar
          onQueuedToogle={onQueuedToogle}
          onHistoryToogle={onHistoryToogle}
          onActionPendingToggle={onActionPendingToggle}
          actionPendingFilter={actionPendingFilter}
          {...props}
        />
      }
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20, padding: '0px 0px 0px 20px' }}
      >
        <DropdownFilter
          filterKey={'businessUnitId'}
          options={businessUnitOptions!}
          placeholder={'Business Unit'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'departmentId'}
          options={departmentOptions!}
          placeholder={'Department'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          dropdownWidth={200}
          filterKey={'iconTypeId'}
          options={statusTypeOptions!}
          placeholder={'Status'}
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
interface MenuActionBarProps {
  onQueuedToogle?: (value: boolean) => void;
  onHistoryToogle?: (value: boolean) => void;
  onActionPendingToggle?: (value: boolean) => void;
  actionPendingFilter: boolean;
  isAllApproval?: boolean;
  setIsUserApprover?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsUserRequestor?: React.Dispatch<React.SetStateAction<boolean>>;
  approverUser?: branchUserList_branchUserList_nodes | null;
  setApproverUser?: React.Dispatch<
    React.SetStateAction<branchUserList_branchUserList_nodes | null>
  >;
  onUnsignedCheck?: (value: boolean) => void;
  isUserApprover?: boolean;
  isUserRequestor?: boolean;
}

const choiceGroupStyles: IChoiceGroupStyles = {
  label: {
    display: 'inline',
  },
  flexContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 240,
  },
};
let checkBoxStyle: ICheckboxStyles = {
  root: {
    paddingTop: 4,
  },
  label: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.size10,
    color: '#a9a9a9',
  },
};
export const MenuActionBar: React.FC<MenuActionBarProps> = ({
  onQueuedToogle,
  onHistoryToogle,
  onActionPendingToggle,
  actionPendingFilter,
  isAllApproval = false,
  setIsUserApprover,
  setIsUserRequestor,
  setApproverUser,
  approverUser,
  onUnsignedCheck,
  isUserApprover,
  isUserRequestor,
}) => {
  const showBranchUserList = isUserApprover || isUserRequestor;

  const globalState = useReactiveVar(globalMode);
  const styles = useStyles();
  const TOOLTIP_ACTIVE_TEXT = 'Requiring immediate approval';
  const TOOLTIP_PENDING_TEXT =
    'Waiting approval, upcoming approval or already approved';
  const TOOLTIP_HISTORY_TEXT =
    'Inactive approvals: fully approved or cancelled';
  const [branchUserListOptions, setbranchUserListOptions] = useState<
    IDropdownOption[]
  >([]);
  const { data: branchUserListData } =
    useQuery<branchUserList>(BRANCH_USER_LIST);

  useEffect(() => {
    const userList =
      branchUserListData?.branchUserList?.nodes.map((item) => ({
        key: item.id,
        text: item.name,
        data: item._activeApprovalCount,
      })) || [];
    setbranchUserListOptions(userList);
  }, [branchUserListData]);

  const options: IChoiceGroupOption[] = [
    {
      key: 'Active',
      text: 'Active',
      styles: { choiceFieldWrapper: { fontWeight: 800 } },
      onRenderField: (props, render) => {
        return (
          <TooltipHost content={TOOLTIP_ACTIVE_TEXT}>
            {render!(props)}
          </TooltipHost>
        );
      },
    },
    {
      key: 'Pending',
      text: 'Pending',
      styles: { choiceFieldWrapper: { fontWeight: 800 } },
      onRenderField: (props, render) => {
        return (
          <TooltipHost content={TOOLTIP_PENDING_TEXT}>
            {render!(props)}
          </TooltipHost>
        );
      },
    },
    {
      key: 'History',
      text: 'History',
      styles: { choiceFieldWrapper: { fontWeight: 800 } },
      onRenderField: (props, render) => {
        return (
          <TooltipHost content={TOOLTIP_HISTORY_TEXT}>
            {render!(props)}
          </TooltipHost>
        );
      },
    },
  ];

  const _onChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    switch (option?.key) {
      case 'Active':
        onActionPendingToggle?.(true);
        onQueuedToogle?.(false);
        onHistoryToogle?.(false);
        break;
      case 'Pending':
        onActionPendingToggle?.(false);
        onQueuedToogle?.(true);
        onHistoryToogle?.(false);
        break;
      case 'History':
        onActionPendingToggle?.(false);
        onHistoryToogle?.(true);
        onQueuedToogle?.(false);
        break;
    }
  };
  function getDropdownStyles(
    props: IDropdownStyleProps
  ): Partial<IDropdownStyles> {
    return {
      root: {
        textAlign: 'center',
        border: 0,
        width: 120,
      },
      title: {
        // backgroundColor: props.theme?.palette.neutralLighter,
        backgroundColor: props?.theme?.palette.neutralLight,

        selectors: {
          ':hover': {
            borderRadius: 5,
            backgroundColor: props.theme?.palette.neutralQuaternary,
          },
        },
        border: 0,
      },
    };
  }
  useEffect(() => {
    if (!showBranchUserList) setApproverUser?.(null);
  }, [showBranchUserList, setApproverUser]);

  return (
    <Stack horizontal horizontalAlign="center" verticalAlign="center">
      <Stack
        className={styles.actionsWrapperStyle}
        horizontalAlign="center"
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 25 }}
      >
        <ChoiceGroup
          options={options}
          disabled={globalState}
          defaultSelectedKey={actionPendingFilter ? 'Active' : undefined}
          styles={choiceGroupStyles}
          onChange={_onChange}
        />
        {isAllApproval && (
          <Checkbox
            disabled={globalState}
            styles={checkBoxStyle}
            label="Approver"
            onChange={(_, checked) => setIsUserApprover?.(checked!)}
          />
        )}
        {isAllApproval && isUserApprover && (
          <Checkbox
            disabled={globalState}
            styles={checkBoxStyle}
            label="Unsigned"
            onChange={(_, checked) => onUnsignedCheck?.(checked!)}
          />
        )}
        {isAllApproval && (
          <Checkbox
            disabled={globalState}
            styles={checkBoxStyle}
            label="Requestor"
            onChange={(_, checked) => setIsUserRequestor?.(checked!)}
          />
        )}

        {isAllApproval && showBranchUserList && (
          <Stack horizontalAlign="center" horizontal>
            <TooltipHost content={'Choose user'}>
              <Dropdown
                dropdownWidth={200}
                placeholder="User"
                styles={getDropdownStyles}
                options={branchUserListOptions}
                selectedKey={approverUser ? approverUser.id : null}
                onChange={(_, option) => {
                  const data = branchUserListData?.branchUserList?.nodes.find(
                    (item) => item.id === option?.key
                  );
                  setApproverUser?.(data || null);
                }}
                onRenderOption={(option) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  return (
                    <Stack grow>
                      {option && (
                        <Stack horizontal horizontalAlign="space-between">
                          <Text>{option.text}</Text>
                          {option.data && <Text>{option.data}</Text>}
                        </Stack>
                      )}
                    </Stack>
                  );
                }}
              />
            </TooltipHost>

            {approverUser && (
              <TooltipHost content={'Remove selected user'}>
                <IconButton
                  onClick={() => {
                    setApproverUser?.(null);
                  }}
                  iconProps={{ iconName: 'Cancel' }}
                />
              </TooltipHost>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
