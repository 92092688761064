import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles(() => ({
  flex50: {
    flex: 0.5,
  },
  documentsContainer: {
    margin: '0px 0px',
  },
  marginB20: {
    marginBottom: 20,
  },
  formikCheckbox: {
    width: 400,
    paddingLeft: 15,
    alignItems: 'center',
  },
}));
