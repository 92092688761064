import { useReactiveVar } from '@apollo/client';
import { FontSizes, Label, Stack, Text, makeStyles } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { FormHookTextField } from 'common/components/FormHooksFields';
import { useCommonStyles } from 'common/styles';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trip_trip } from '../../__generated__/Trip';
import { TripCreateFormValues } from '../../types';
import { TravelAllowancesCallout } from './TravelAllowancesCallout';
import {
  TravelerAutoComplete,
  setSelectedTraveler,
} from './TravelerAutoComplete';
import { WarningsCallout } from './WarningsCallout';
import { setTripInitiateFlow } from 'utility/cache/ui';

const useStyles = makeStyles((_theme) => ({
  totalAmount: {
    fontSize: FontSizes.size24,
  },
  overageAmount: {
    color: 'red',
  },
}));

interface BasicFormProps {
  inputsDisabled: boolean;
  tripData: Trip_trip | null | undefined;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  tripData,
  inputsDisabled,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { setValue } = useFormContext<TripCreateFormValues>();
  const selectedTraveler = useReactiveVar(setSelectedTraveler);
  const labelId: string = useId('label');
  const travelerAutocompleteId: string = useId('travelerAutocomplete');
  const { traveler, controlTotalAmount, travelAuthorization } = { ...tripData };
  const { _fullName } = { ...traveler };
  const { currency } = { ...travelAuthorization };
  const currencySymbol = currency?.currencySymbol || null;

  useEffect(() => {
    if (traveler) {
      setSelectedTraveler(traveler);
    }
  }, [traveler]);

  return (
    <>
      <Stack
        horizontal
        tokens={{ padding: '0px 20px 20px 20px', childrenGap: 20 }}
        verticalAlign="baseline"
      >
        <Stack.Item grow>
          <TravelerAutoComplete
            fieldValueName="trip.travelerFullName"
            fieldValueId="trip.travelerId"
            label="Traveler"
            placeholder="Traveler"
            id={travelerAutocompleteId}
            aria-labelledby={labelId}
            showAddIcon
            disabled={inputsDisabled}
            travelerName={_fullName}
            traveler={traveler}
            tripData={tripData}
            onTravelerSelect={(traveler) => {
              setTripInitiateFlow('initiated');
              if (traveler.travelPolicy?.description)
                setValue(
                  'trip.travelPolicy',
                  traveler.travelPolicy?.description
                );
              else {
                setValue('trip.travelPolicy', null);
              }
            }}
            onClear={() => {
              setTripInitiateFlow('none');
              setValue('trip.travelPolicy', null);
            }}
            required
          />
        </Stack.Item>
        <Stack.Item grow>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <TravelAllowancesCallout traveler={selectedTraveler} />
            <Label>Policy</Label>
          </Stack>
          <FormHookTextField
            name="trip.travelPolicy"
            value={selectedTraveler?.travelPolicy?.description}
            disabled
          />
        </Stack.Item>
        <Stack.Item grow>
          <Stack verticalAlign="baseline" tokens={{ padding: '10px 0px' }}>
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <WarningsCallout tripData={tripData} />
              <Label>Trip Total:</Label>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <Text
                variant="large"
                className={clsx(
                  commonStyles.colorThemePrimary,
                  commonStyles.bold,
                  styles.totalAmount
                )}
              >
                {currencySymbol}
              </Text>
              <AmountTextView
                variant="large"
                value={controlTotalAmount || '0.00'}
                className={clsx(
                  commonStyles.colorThemePrimary,
                  commonStyles.bold,
                  styles.totalAmount
                )}
              />
              <Stack tokens={{ padding: '0px 0px 0px 10px' }}>
                <Text
                  className={clsx(
                    commonStyles.colorThemePrimary,
                    commonStyles.bold,
                    styles.totalAmount
                  )}
                >
                  {travelAuthorization?.currency?.isoCode}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );
};
