import {
  Callout,
  CommandBarButton,
  DefaultButton,
  DirectionalHint,
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { ADDRESS_INITIAL_VALUES } from '../constants';
import { TravelerValues } from '../types';
import { TravelerCommonData_countries_nodes } from '../__generated__/TravelerCommonData';
import { AddressFormFields } from './AddressFormFields';
import { useStyles } from './index.styles';
import { useId } from '@fluentui/react-hooks';
interface AddressProps {
  isEdit: boolean;
  countries: TravelerCommonData_countries_nodes[];
  inputsDisabled: boolean;
}
export const Address: React.FC<AddressProps> = ({
  isEdit,
  countries,
  inputsDisabled,
}) => {
  const styles = useStyles();
  const { values, errors, setFieldValue } = useFormikContext<TravelerValues>();
  const [showAddressForm, setShowAddressForm] = useState<boolean>(true);
  const [addressIndex, setAddressIndex] = useState<number>(0);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const addressName = `travelerAddressesByTravelerId[${addressIndex}]`;
  const calloutId = useId(`address`);

  const ADDRESS_INFO =
    'Address will auto-populate fields in ground-transportation section';

  useEffect(() => {
    setShowAddressForm(!isEdit);
    setAddressIndex(isEdit ? -1 : 0);
  }, [isEdit]);

  return (
    <Stack>
      <FieldArray name="travelerAddressesByTravelerId">
        {({ remove, insert }) => (
          <>
            {showAddressForm && addressIndex !== -1 ? (
              <AddressFormFields
                addressName={addressName}
                toList={() => {
                  setAddressIndex(-1);
                  setShowAddressForm(false);
                }}
                countries={countries}
                isEdit={isEdit}
                remove={remove}
                inputsDisabled={inputsDisabled}
              />
            ) : (
              <>
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  className={styles.defaultButtonContainer}
                >
                  <Stack horizontal>
                    {isInfoVisible && (
                      <Callout
                        gapSpace={0}
                        target={`#${calloutId}`}
                        directionalHint={DirectionalHint.leftTopEdge}
                        setInitialFocus
                      >
                        <Stack className={styles.commentContainer}>
                          {ADDRESS_INFO}
                        </Stack>
                      </Callout>
                    )}
                    <IconButton
                      iconProps={{ iconName: 'Info' }}
                      onMouseEnter={() => setInfoVisible(true)}
                      onMouseLeave={() => setInfoVisible(false)}
                      id={calloutId}
                    />
                    <Text variant="xLarge">Address</Text>
                  </Stack>
                  <DefaultButton
                    text="Add Address"
                    iconProps={{ iconName: 'Add' }}
                    onClick={() => {
                      setAddressIndex(0);
                      setShowAddressForm(true);
                      insert(0, { ...ADDRESS_INITIAL_VALUES });
                    }}
                    disabled={inputsDisabled}
                  />
                </Stack>
                {errors.travelerAddressesByTravelerId && (
                  <>
                    <MessageBar messageBarType={MessageBarType.error}>
                      At least one address is currently invalid
                    </MessageBar>
                  </>
                )}
                {values.travelerAddressesByTravelerId?.map((ele, index) => {
                  const formattedAddress =
                    [
                      ele.addressLine1,
                      ele.addressLine2,
                      ele.cityName,
                      ele.countryId,
                      ele.stateRegionId,
                      ele.postalCode,
                    ]
                      .filter(Boolean)
                      .join(', ') || 'Missing Address';

                  const formattedSubtext = [
                    ele.telephoneNumber,
                    ele.emailAddress,
                  ]
                    .filter(Boolean)
                    .join(', ');
                  return (
                    <Stack
                      horizontal
                      verticalAlign="center"
                      className={clsx(styles.root, styles.AddressListItem)}
                      key={index}
                    >
                      <Stack className={styles.rowCell}>
                        <Link
                          className={styles.addressTypeLabel}
                          onClick={(event) => {
                            event.preventDefault();
                            setAddressIndex(index);
                            setShowAddressForm(true);
                          }}
                        >
                          {formattedAddress}
                        </Link>
                        <Text
                          className={styles.addressSubtitle}
                          variant="small"
                        >
                          {formattedSubtext}
                        </Text>
                      </Stack>
                      <Stack horizontal tokens={{ childrenGap: 50 }}>
                        {!ele.isPrimary && !inputsDisabled && (
                          <CommandBarButton
                            iconProps={{ iconName: 'HomeVerify' }}
                            onClick={(event) => {
                              event.stopPropagation();
                              values.travelerAddressesByTravelerId?.forEach(
                                (_addr, i) => {
                                  setFieldValue(
                                    `travelerAddressesByTravelerId[${i}].isPrimary`,
                                    i === index
                                  );
                                }
                              );
                            }}
                            text="Set primary"
                            className={clsx(
                              styles.setPrimaryButton,
                              'setPrimaryButtonGlobal'
                            )}
                          />
                        )}
                        {!ele.isPrimary ? (
                          !inputsDisabled && (
                            <TooltipHost content="Delete">
                              <IconButton
                                iconProps={{ iconName: 'delete' }}
                                className={styles.deleteIcon}
                                ariaLabel="Delete"
                                onClick={() => {
                                  remove(index);
                                  //if only one address left, set it to Primary
                                  if (
                                    values.travelerAddressesByTravelerId
                                      ?.length === 2
                                  )
                                    setFieldValue(
                                      `travelerAddressesByTravelerId[0].isPrimary`,
                                      true
                                    );
                                }}
                              />
                            </TooltipHost>
                          )
                        ) : (
                          <Text className={styles.primaryLabel}>Primary</Text>
                        )}
                      </Stack>
                    </Stack>
                  );
                })}
              </>
            )}
          </>
        )}
      </FieldArray>
    </Stack>
  );
};
