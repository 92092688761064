import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  Callout,
  DayOfWeek,
  DirectionalHint,
  IconButton,
  IDropdownOption,
  ITextFieldProps,
  Label,
  Stack,
  Text,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import {
  FormHookAmount,
  FormHookDatePicker,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { useCommonStyles } from 'common/styles';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TravelAuthorizationValues } from 'travelAuthorization/TravelPlan/view/interface';
import { setUserDefaults } from 'utility/cache/ui';
import { TravelerAuthorizationCommonData } from '../__generated__/TravelerAuthorizationCommonData';
import { TravelAgencyAutoComplete } from './TravelAgencyAutoComplete';
import {
  GetCorporateWorkgroups,
  GetCorporateWorkgroupsVariables,
} from './__generated__/GetCorporateWorkgroups';
import { useStyles } from './index.styles';
const GET_CORPORATE_WORK_GROUPS = loader('./GetCorporateWorkgroups.graphql');
interface BusinessUnitsOptions extends IDropdownOption {
  _isCorporateWorkgroupRequired: boolean;
}
interface BasicFormProps {
  isNew: boolean;
  inputsDisabled: boolean;
  commonData: TravelerAuthorizationCommonData | undefined;
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
}

const COMMENT_INFO =
  'Enter a brief description describing the TA:(Scouting NZ,NY Meeting) or leave blank to default with trip information';

export const BasicForm: React.FC<BasicFormProps> = ({
  isNew,
  inputsDisabled,
  commonData,
  travelAuthorizationData,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { setValue, control, trigger } =
    useFormContext<TravelAuthorizationValues>();
  const isCorporateWorkgroupRequired = useWatch({
    name: '_isCorporateWorkgroupRequired',
    control,
  });
  const [isInfoVisible, setInfoVisible] = useState(false);
  const userDefaults = useReactiveVar(setUserDefaults);
  const { _isTaPersonalTravelOmitted } = {
    ...userDefaults,
  };

  const businessUnitId = useWatch({
    name: 'businessUnitId',
    control,
  });
  const calloutId = useId(`address`);

  const [getCorporateWorkGroups, { data: corporateWorkGroupsData }] =
    useLazyQuery<GetCorporateWorkgroups, GetCorporateWorkgroupsVariables>(
      GET_CORPORATE_WORK_GROUPS,
      {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
      }
    );

  const { companyCurrencies, companyBusinessUnits, companyDepartments } = {
    ...commonData,
  };

  const { supplier, controlTotalAmount, currency } = {
    ...travelAuthorizationData,
  };
  const { currencySymbol } = { ...currency };

  const businessUnitsOptions: BusinessUnitsOptions[] =
    companyBusinessUnits?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.name || '',
      _isCorporateWorkgroupRequired: ele._isCorporateWorkgroupRequired || false,
    })) || [];

  const currenciesOptions =
    companyCurrencies?.nodes.map((item) => {
      const currency = !!item.name
        ? `${item.isoCode} - ${item.name}`
        : item.isoCode || '';
      return { key: item.id, text: currency };
    }) || [];

  const departmentsOptions = formatDropdownOptions(companyDepartments?.nodes, {
    getKey: (item) => item.id,
    getText: (item) => item.name || '',
    getIsDisabled: (item) => !item._isTaSelectable,
  });

  const workgroupOptions = formatDropdownOptions(
    corporateWorkGroupsData?.corporateWorkgroups?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
    }
  );

  const onRenderLabel = (): JSX.Element => {
    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{
          paddingBottom: 6,
        }}
      >
        <Text style={{ fontWeight: 600 }}>Purpose of Trip</Text>
        {!_isTaPersonalTravelOmitted && (
          <FormHookCheckBox
            name="isPersonalTravelAuthorization"
            label="Personal"
            disabled={inputsDisabled}
          />
        )}
      </Stack>
    );
  };
  const onRenderComment = (props: ITextFieldProps | undefined): JSX.Element => {
    return (
      <>
        <Stack horizontal verticalAlign="center">
          <IconButton
            iconProps={{ iconName: 'Info' }}
            onMouseEnter={() => setInfoVisible(true)}
            onMouseLeave={() => setInfoVisible(false)}
            id={calloutId}
          />
          <Label>{props?.label}</Label>
        </Stack>
      </>
    );
  };

  const onBusinessUnitChange = (option: BusinessUnitsOptions) => {
    setValue(
      '_isCorporateWorkgroupRequired',
      !!option._isCorporateWorkgroupRequired
    );
    setValue('corporateWorkgroupId', null);
    trigger();
  };

  const handleBusinessUnitChange = () => {
    if (isCorporateWorkgroupRequired && !!businessUnitId) {
      getCorporateWorkGroups({
        variables: {
          filter: {
            businessUnitId: {
              equalTo: businessUnitId,
            },
            isNewWorkgroupSelectable: {
              equalTo: true,
            },
          },
        },
      });
    }
  };

  const handleBusinessUnitChangeMemo = useCallback(handleBusinessUnitChange, [
    isCorporateWorkgroupRequired,
    businessUnitId,
  ]);

  useEffect(() => {
    handleBusinessUnitChangeMemo();
  }, [handleBusinessUnitChangeMemo]);

  return (
    <Stack
      horizontal
      tokens={{
        childrenGap: 50,
        padding: '0px 20px',
      }}
      className={styles.container}
    >
      <Stack className={styles.leftInputsContainer}>
        <Stack
          horizontal
          tokens={{
            childrenGap: 20,
          }}
        >
          <Stack.Item grow>
            {isInfoVisible && (
              <>
                <Callout
                  gapSpace={0}
                  target={`#${calloutId}`}
                  directionalHint={DirectionalHint.leftTopEdge}
                  setInitialFocus
                  // onDismiss={onCalloutDismiss}
                >
                  <Stack className={styles.commentContainer}>
                    {COMMENT_INFO}
                  </Stack>
                </Callout>
              </>
            )}
            <FormHookTextField
              name="comment"
              label="Travel Plan"
              placeholder="Brief Description"
              disabled={inputsDisabled}
              onRenderLabel={onRenderComment}
            />
          </Stack.Item>
          <Stack.Item grow>
            <FormHookDropdown
              label="Business Unit"
              placeholder="Select"
              options={businessUnitsOptions}
              name="businessUnitId"
              disabled={inputsDisabled}
              onChange={(_e, option) => {
                const selectedOptions = option as BusinessUnitsOptions;
                onBusinessUnitChange(selectedOptions);
              }}
              required
            />
          </Stack.Item>
        </Stack>
        {!!isCorporateWorkgroupRequired && (
          <Stack className={styles.workgroupContainer}>
            <FormHookDropdown
              label="Workgroup"
              placeholder="Select"
              options={workgroupOptions}
              name="corporateWorkgroupId"
              disabled={inputsDisabled}
              required
            />
          </Stack>
        )}
        <Stack
          tokens={{
            childrenGap: 10,
            padding: '10px 0x 0px 0px',
          }}
        >
          <TravelAgencyAutoComplete
            name="supplierId"
            label="Travel Agency"
            placeholder="Travel Agency"
            lookUpCalloutWidth={400}
            id={'supplierFormikField'}
            showAddIcon
            supplierName={supplier?._fullName || null}
            supplier={supplier || null}
            isNew={isNew}
            onTravelAgencySelect={(data) => {
              setValue('bookingFee', data.vendorServiceRate);
            }}
            disabled={inputsDisabled}
          />
          <Stack className={styles.businessPurposeContainer}>
            <FormHookTextField
              name="tripPurpose"
              placeholder="Purpose of Trip"
              onRenderLabel={onRenderLabel}
              multiline
              rows={7}
              disabled={inputsDisabled}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack className={styles.rightInputsContainer}>
        <FormHookDropdown
          label="Department"
          placeholder="Select"
          options={departmentsOptions}
          name="departmentId"
          disabled={inputsDisabled}
        />
        <Stack
          tokens={{
            childrenGap: 10,
            padding: '20px 0x 0px 0px',
          }}
        >
          <FormHookAmount
            name="bookingFee"
            label="Booking Fee"
            placeholder="Booking Fee"
            fixedDecimalScale
            decimalScale={2}
            allowNegative={false}
            disabled={inputsDisabled}
          />
          <FormHookDatePicker
            name="travelAuthorizationDate"
            placeholder="Select a date"
            label="Date"
            firstDayOfWeek={DayOfWeek.Sunday}
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            showGoToToday
            isRequired
            disabled={inputsDisabled}
          />
          <FormHookDropdown
            label="Currency"
            placeholder="Select"
            options={currenciesOptions}
            name="currencyId"
            disabled={inputsDisabled}
            required
          />
          <Stack
            horizontal
            verticalAlign="baseline"
            tokens={{ childrenGap: 4, padding: '10px 0px' }}
            horizontalAlign="space-between"
          >
            <Label>Travel Total:</Label>
            <Stack horizontal>
              <Text
                variant="large"
                className={clsx(
                  commonStyles.colorThemePrimary,
                  commonStyles.bold,
                  styles.totalAmount
                )}
              >
                {currencySymbol}
              </Text>
              <AmountTextView
                variant="large"
                value={controlTotalAmount || '0.00'}
                className={clsx(
                  commonStyles.colorThemePrimary,
                  commonStyles.bold,
                  styles.totalAmount
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
