import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    tripPurpose: Yup.string().required('Required').nullable(),
    travelAuthorizationDate: Yup.string().required('Required').nullable(),
    businessUnitId: Yup.string().required('Required').nullable(),
    currencyId: Yup.string().required('Required').nullable(),
    corporateWorkgroupId: Yup.string().when('_isCorporateWorkgroupRequired', {
      is: true,
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
  });
