import { CorporateWorkgroup } from './__generated__/CorporateWorkgroup';
import {
  CORPORATE_WORKGROUP_VALUES,
  WORKGROUP_USERS_VALUES,
} from './constants';
import { CorporateWorkgroupValues, workgroupUserType } from './types';

interface DefaultValueProps {
  isNew: boolean;
  corporateWorkgroupData: CorporateWorkgroup | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isNew, corporateWorkgroupData } = { ...props };
  let defaultValues: CorporateWorkgroupValues = CORPORATE_WORKGROUP_VALUES;
  if (corporateWorkgroupData?.corporateWorkgroup && !isNew) {
    const {
      id,
      _rowTimestamp,
      _isUpdatable,
      _isDeletable,
      corporateChartOfAccount,
      statusType,
      workgroupUsersByWorkgroupId,
      ...workgroupValues
    } = { ...corporateWorkgroupData.corporateWorkgroup };
    const { nodes } = {
      ...corporateWorkgroupData?.corporateWorkgroup
        ?.workgroupUsersByWorkgroupId,
    };
    defaultValues = {
      ...workgroupValues,
      chartOfAccountsId: corporateChartOfAccount?.id!,
      workgroupUsers: !!nodes.length
        ? [
            ...nodes?.map((node) => ({
              name: node?.userProfile?.name,
              emailAccount: node?.userProfile?.emailAccount,
              profileIconState:
                node?.userProfile?._signinInfo?.profileIconState,
              profileIconType: node?.userProfile?._signinInfo?.profileIconType,
              _lastUsedDate: node?.userProfile?._lastUsedDate,
              id: node.id,
              _rowTimestamp: node._rowTimestamp,
              userProfileId: node.userProfileId,
              _isUpdatable: node._isUpdatable,
              _isDeletable: node._isDeletable,
            })),
            WORKGROUP_USERS_VALUES,
          ]
        : [WORKGROUP_USERS_VALUES],
    };
  }
  return defaultValues;
};

export const getCreatedWorkgroupUsers = (
  defaultValues: workgroupUserType[],
  currentValues: workgroupUserType[]
) => {
  const defaultUserProfileIds = new Set(
    defaultValues.map((item) => item.userProfileId)
  );
  const newUsers = currentValues
    .filter(
      (item) =>
        item.userProfileId && !defaultUserProfileIds.has(item.userProfileId)
    )
    .map((item) => ({
      userProfileId: item.userProfileId!,
    }));
  return newUsers;
};

export const getDeletedWorkgroupUsers = (
  defaultValues: workgroupUserType[],
  currentValues: workgroupUserType[]
) => {
  const currentUserIds = new Set(
    currentValues.map((item) => item.userProfileId)
  );
  const deletedUsers = defaultValues
    .filter((item) => item.id && !currentUserIds.has(item.userProfileId))
    .map((item) => ({
      id: item.id!,
      rowTimestamp: item._rowTimestamp!,
    }));

  return deletedUsers;
};
