import {
  CommandBarButton,
  DefaultButton,
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { ADDRESS_INITIAL_VALUES } from '../../constants';
import { SupplierValues } from '../../types';
import { SupplierCommonData_countries_nodes } from '../../__generated__/SupplierCommonData';
import { AddressFormFields } from './AddressFormFields';
import { useStyles } from './index.styles';
interface AddressFormProps {
  isEdit: boolean;
  countries: SupplierCommonData_countries_nodes[];
  isUpdatable: boolean | null | undefined;
}
export const AddressForm: React.FC<AddressFormProps> = ({
  isEdit,
  countries,
  isUpdatable,
}) => {
  const styles = useStyles();
  const { values, errors, setFieldValue } = useFormikContext<SupplierValues>();
  const [showAddressForm, setShowAddressForm] = useState<boolean>(true);
  const [addressIndex, setAddressIndex] = useState<number>(0);
  const addressName = `supplierAddressesBySupplierId[${addressIndex}]`;
  const isDisabled = isUpdatable === false ? true : false;

  useEffect(() => {
    setShowAddressForm(!isEdit);
    setAddressIndex(isEdit ? -1 : 0);
  }, [isEdit]);

  return (
    <Stack tokens={{ padding: '10px 0px' }}>
      <FieldArray name="supplierAddressesBySupplierId">
        {({ remove, insert }) => (
          <>
            {showAddressForm && addressIndex !== -1 ? (
              <AddressFormFields
                addressName={addressName}
                toList={() => {
                  setAddressIndex(-1);
                  setShowAddressForm(false);
                }}
                countries={countries}
                isEdit={isEdit}
                remove={remove}
                isUpdatable={isUpdatable}
              />
            ) : (
              <>
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  className={styles.defaultButtonContainer}
                >
                  <Text variant="xLarge">Address</Text>
                  <DefaultButton
                    text="Add Address"
                    iconProps={{ iconName: 'Add' }}
                    onClick={() => {
                      setAddressIndex(0);
                      setShowAddressForm(true);
                      insert(0, { ...ADDRESS_INITIAL_VALUES });
                    }}
                    disabled={isDisabled}
                  />
                </Stack>
                {errors.supplierAddressesBySupplierId && (
                  <>
                    <MessageBar messageBarType={MessageBarType.error}>
                      At least one address is currently invalid
                    </MessageBar>
                  </>
                )}
                {values.supplierAddressesBySupplierId &&
                  values.supplierAddressesBySupplierId?.length > 0 &&
                  values.supplierAddressesBySupplierId?.map((ele, index) => {
                    const formattedAddress =
                      [
                        ele.addressLine1,
                        ele.addressLine2,
                        ele.cityName,
                        ele.countryId,
                        ele.stateRegionId,
                        ele.postalCode,
                      ]
                        .filter(Boolean)
                        .join(', ') || 'Missing Address';

                    const formattedSubtext = [
                      ele.telephoneNumber,
                      ele.emailAddress,
                      ele.webAddress,
                    ]
                      .filter(Boolean)
                      .join(', ');

                    return (
                      <Stack
                        horizontal
                        verticalAlign="center"
                        className={clsx(styles.root, styles.AddressListItem)}
                        key={index}
                      >
                        <Stack className={styles.rowCell}>
                          <Link
                            className={styles.addressTypeLabel}
                            onClick={(event) => {
                              event.preventDefault();
                              setAddressIndex(index);
                              setShowAddressForm(true);
                            }}
                          >
                            {formattedAddress}
                          </Link>
                          <Text
                            className={styles.addressSubtitle}
                            variant="small"
                          >
                            {formattedSubtext}
                          </Text>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 50 }}>
                          {!ele.isPrimary && !isDisabled && (
                            <CommandBarButton
                              iconProps={{ iconName: 'HomeVerify' }}
                              onClick={(event) => {
                                event.stopPropagation();
                                values.supplierAddressesBySupplierId?.forEach(
                                  (_addr, i) => {
                                    setFieldValue(
                                      `supplierAddressesBySupplierId[${i}].isPrimary`,
                                      i === index
                                    );
                                  }
                                );
                              }}
                              text="Set primary"
                              className={clsx(
                                styles.setPrimaryButton,
                                'setPrimaryButtonGlobal'
                              )}
                            />
                          )}
                          {!ele.isPrimary ? (
                            !isDisabled && (
                              <TooltipHost content="Delete">
                                <IconButton
                                  iconProps={{ iconName: 'delete' }}
                                  className={styles.deleteIcon}
                                  ariaLabel="Delete"
                                  onClick={() => {
                                    remove(index);
                                    //if only one address left, set it to Primary
                                    if (
                                      values.supplierAddressesBySupplierId
                                        ?.length === 2
                                    )
                                      setFieldValue(
                                        `supplierAddressesBySupplierId[0].isPrimary`,
                                        true
                                      );
                                  }}
                                />
                              </TooltipHost>
                            )
                          ) : (
                            <Text className={styles.primaryLabel}>Primary</Text>
                          )}
                        </Stack>
                      </Stack>
                    );
                  })}
              </>
            )}
          </>
        )}
      </FieldArray>
    </Stack>
  );
};
