import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  topSheet: {
    padding: '0px 20px',
  },
  commentContainer: {
    padding: 10,
    backgroundColor: theme.palette.neutralLighterAlt,
    width: 250,
    borderRadius: 4,
  },
  addressContainer: {
    padding: '0px 20px 20px',
    marginTop: '10px !important',
  },
  inputContainer: {
    flex: 1,
  },
  halfWidthContainer: {
    flex: 0.5,
  },
  checkboxContainer: { width: '50%', marginTop: '30px !important' },
}));
