import { useReactiveVar } from '@apollo/client';
import {
  Callout,
  DirectionalHint,
  IconButton,
  IDropdownOption,
  ITextFieldProps,
  Label,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import {
  FormHookCountryRegion,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { TravelerCreate_travelerCreate_traveler } from 'common/graphql/__generated__/TravelerCreate';
import React, { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { OccupationViewModal } from 'settings/account/occupations/view/OccupationViewModal';
import { TravelerAlias } from 'settings/account/Travelers/view/BasicForm/TravelerAlias';
import { Trip_trip } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/__generated__/Trip';
import { setUserDefaults } from 'utility/cache/ui';
import { TravelerDetails_traveler } from '../__generated__/TravelerDetails';
import { TravelerModalDropdownsData } from '../__generated__/TravelerModalDropdownsData';
import { TravelerValues } from '../interfaces';
import { useStyles } from './index.styles';
import { TravelerNameAutocomplete } from './TravelerNameAutocomplete';
import { useId } from '@fluentui/react-hooks';

interface BasicFormProps {
  isNew: boolean;
  isUpdatable: boolean;
  isUpdateTravelerOverride: boolean;
  traveler: TravelerDetails_traveler | null | undefined;
  commonData: TravelerModalDropdownsData | undefined;
  tripData: Trip_trip | null | undefined;
  onTravelerCreate: (param: TravelerCreate_travelerCreate_traveler) => void;
  setValue: UseFormSetValue<TravelerValues>;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  isNew,
  isUpdatable,
  isUpdateTravelerOverride,
  traveler,
  commonData,
  tripData,
  onTravelerCreate,
  setValue,
}) => {
  const styles = useStyles();
  const calloutId = useId(`address`);
  const { companyDepartments, userOccupationTitles, countries } = {
    ...commonData,
  };
  const [isInfoVisible, setInfoVisible] = useState(false);
  const { _isUpdatable } = { ...tripData };
  const isProtected = !!tripData?.id ? !_isUpdatable : false;
  const userDefaults = useReactiveVar(setUserDefaults);
  const { _isTravelerEntryDepartmentOmitted } = {
    ...userDefaults,
  };
  const [showOccupationModal, setShowOccupationModal] = useState(false);
  const [newOccupation, setNewOccupation] = useState<string | undefined>(
    undefined
  );
  const departmentsOptions: IDropdownOption[] =
    companyDepartments?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.name || '',
    })) || [];

  const occupationOptions: IDropdownOption[] =
    userOccupationTitles?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.userOccupationTitle || '',
    })) || [];

  const countryOptions: IDropdownOption[] =
    countries?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.country || '',
    })) || [];

  const isDisabled = isUpdateTravelerOverride
    ? false
    : isUpdatable
    ? false
    : true;

  const ADDRESS_INFO =
    'Address will auto-populate fields in ground-transportation section';

  const onRenderLabel = (props: ITextFieldProps | undefined): JSX.Element => {
    return (
      <>
        <Stack
          horizontal
          verticalAlign="baseline"
          horizontalAlign="space-between"
        >
          <Label disabled={props?.disabled}>{props?.label}</Label>
          {!isNew && (
            <TravelerAlias
              traveler={traveler}
              isTravelAuthMutation
              onAliasUpdate={onTravelerCreate}
              isProtected={isProtected}
            />
          )}
        </Stack>
      </>
    );
  };
  useEffect(() => {
    if (newOccupation) {
      setValue('userOccupationTitleId', newOccupation, { shouldDirty: true });
      setNewOccupation(undefined);
      setShowOccupationModal(false);
    }
  }, [newOccupation, setValue]);

  return (
    <Stack>
      <Stack className={styles.topSheet} tokens={{ childrenGap: 10 }}>
        <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 20 }}>
          <Stack className={styles.inputContainer}>
            <TravelerNameAutocomplete
              name="firstName"
              label="First Name"
              placeholder="Given Name"
              id={'givenName'}
              disabled={!isUpdatable || !!traveler?._isAliasInvoked}
              onRenderLabel={onRenderLabel}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <TravelerNameAutocomplete
              name="companyOrLastName"
              label="Last Name"
              placeholder="Family Name"
              id={'familyName'}
              disabled={!isUpdatable || !!traveler?._isAliasInvoked}
              required
            />
          </Stack>
        </Stack>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 20 }}>
          <Stack className={styles.halfWidthContainer}>
            <Stack horizontal>
              <Label>Occupation/Title</Label>
              <IconButton
                iconProps={{ iconName: 'AddTo' }}
                onClick={() => setShowOccupationModal(true)}
              />
            </Stack>
            {showOccupationModal && (
              <OccupationViewModal
                setNewOption={setNewOccupation}
                onClose={() => {
                  if (!newOccupation) setShowOccupationModal(false);
                }}
              />
            )}
            <FormHookDropdown
              placeholder="Select"
              options={occupationOptions}
              name="userOccupationTitleId"
              disabled={isDisabled}
            />
          </Stack>
          {!_isTravelerEntryDepartmentOmitted && (
            <Stack className={styles.halfWidthContainer}>
              <FormHookDropdown
                label="Department"
                placeholder="Select"
                options={departmentsOptions}
                name="travelerDepartmentId"
                disabled={isDisabled}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Separator />
      <Stack horizontal style={{ paddingLeft: 10 }}>
        {isInfoVisible && (
          <Callout
            gapSpace={0}
            target={`#${calloutId}`}
            directionalHint={DirectionalHint.leftTopEdge}
            setInitialFocus
          >
            <Stack className={styles.commentContainer}>{ADDRESS_INFO}</Stack>
          </Callout>
        )}
        <IconButton
          iconProps={{ iconName: 'Info' }}
          onMouseEnter={() => setInfoVisible(true)}
          onMouseLeave={() => setInfoVisible(false)}
          id={calloutId}
        />
        <Text variant="xLarge">Address</Text>
      </Stack>
      <Stack className={styles.addressContainer} tokens={{ childrenGap: 16 }}>
        <Stack verticalAlign="center">
          <FormHookTextField
            name="travelerAddressesByTravelerId[0].addressLine1"
            label="Line 1)"
            placeholder="Street Number and PO Box"
            disabled={!isUpdatable}
          />
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{
            childrenGap: 20,
          }}
        >
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="travelerAddressesByTravelerId[0].addressLine2"
              label="Line 2)"
              placeholder="Apartment, Suite, Unit, Building, Floor"
              disabled={!isUpdatable}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="travelerAddressesByTravelerId[0].cityName"
              label="City"
              placeholder="City/Town"
              disabled={!isUpdatable}
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{
            childrenGap: 20,
          }}
        >
          <Stack style={{ flex: 2 }}>
            <FormHookCountryRegion
              countries={countryOptions}
              countryName={`travelerAddressesByTravelerId[0].countryId`}
              regionName={`travelerAddressesByTravelerId[0].stateRegionId`}
              CountryAutocompleteProps={{
                label: 'Country',
                errorMessage: '',
              }}
              StateRegionAutocompleteProps={{
                label: 'State / Region / Province',
                errorMessage: '',
              }}
              disabled={!isUpdatable}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="travelerAddressesByTravelerId[0].postalCode"
              label="Zip/Postal Code"
              placeholder="Zip/Postal Code"
              disabled={!isUpdatable}
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          verticalAlign="baseline"
          tokens={{
            childrenGap: 20,
          }}
        >
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="travelerAddressesByTravelerId[0].telephoneNumber"
              label="Phone"
              placeholder="Phone"
              disabled={!isUpdatable}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="travelerAddressesByTravelerId[0].emailAddress"
              label="Email"
              placeholder="Email"
              disabled={!isUpdatable}
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          tokens={{
            childrenGap: 20,
          }}
          className={styles.checkboxContainer}
        >
          <Stack className={styles.inputContainer}>
            <FormHookCheckBox
              name="travelerAddressesByTravelerId[0].isGroundTransferAddress"
              label="Ground Transfer"
              disabled={!isUpdatable}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <FormHookCheckBox
              name="travelerAddressesByTravelerId[0].isDeliveryAddress"
              label="Delivery"
              disabled={!isUpdatable}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
