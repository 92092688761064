import { CorporateWorkgroupValues, workgroupUserType } from './types';
export const WORKGROUP_USERS_VALUES: workgroupUserType = {
  id: null,
  _rowTimestamp: null,
  userProfileId: null,
  _isUpdatable: null,
  _isDeletable: null,
  name: null,
  emailAccount: null,
  profileIconState: null,
  profileIconType:null,
  _lastUsedDate: null,
};

export const CORPORATE_WORKGROUP_VALUES: CorporateWorkgroupValues = {
  workgroupNumber: null,
  name: null,
  description: null,
  businessUnitId: null,
  chartOfAccountsId: null,
  productionPhaseId: null,
  isNewWorkgroupSelectable: true,
  prefixCode: null,
  productionStatusTypeId: null,
  workgroupUsers: [WORKGROUP_USERS_VALUES],
};
