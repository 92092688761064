import {
  DayOfWeek,
  Icon,
  Label,
  Pivot,
  PivotItem,
  Stack,
  Text,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import {
  FormHookAmount,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { FormHookDateSelect } from 'common/components/FormHooksFields/FormHookDateSelect';
import { FormHookTimePicker } from 'common/components/FormHooksFields/FormHookTimePicker';
import { useCommonStyles } from 'common/styles';
import React, { useEffect, useState } from 'react';
import { Trip_trip } from '../../../../__generated__/Trip';
import { CarrierAutocomplete } from '../CarrierAutocomplete';
import { DepotAutoComplete } from '../DepotAutoComplete';
import { FareClasses } from '../components/FareClasses';
import {
  departureFlightClassOptions,
  returnFlightClassOptions,
} from '../components/FareClasses/constants';
import { useFormContext, useWatch } from 'react-hook-form';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { TransactionLayout } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { TravelerAuthorizationCommonData } from 'travelAuthorization/TravelPlan/view/FormView/__generated__/TravelerAuthorizationCommonData';
import { TripCreateFormValues, TripItemFormValues } from '../../../../types';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
const TRAVELER_AUTHORIZATION_COMMON_DATA = loader(
  '../../../../../../TravelerAuthorizationCommonData.graphql'
);

interface TrainProps {
  tripIndex: number;
  tripData: Trip_trip | null | undefined;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
  listRefreshRequest: () => void;
}

interface DepartureLayoutProps {
  baseField: string;
  tripIndex: number;
  tripData: Trip_trip | null | undefined;
  isNew: boolean;
  tripType: string;
  onFareClassSelect: (depot: string | null) => void;
  travelAuthorizationData:
  | Partial<TravelAuthorization_travelAuthorization>
  | null
  | undefined;
}

interface ReturnLayoutProps {
  baseField: string;
  tripIndex: number;
  tripData: Trip_trip | null | undefined;
  fareClass: string | null | undefined;
  selectedKey: string;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
}

export const Train: React.FC<TrainProps> = ({
  tripIndex,
  tripData,
  travelAuthorizationData,
  listRefreshRequest,
}) => {
  const { _isUpdatable } = { ...travelAuthorizationData };
  const baseField = `tripItems.${tripIndex}.`;
  const commonStyles = useCommonStyles();
  const { control, setValue } = useFormContext<TripCreateFormValues>();
  const [selectedKey, setSelectedKey] = useState<string>(
    TransactionLayout.TRAIN_BOOKING_ROUND_TRIP
  );
  const [selectedFareClass, setSelectedFareClass] = useState<
    string | null | undefined
  >(undefined);

  const watchDepartureAirfare = useWatch({
    name: `tripItems.${tripIndex}.departureAirfare`,
  });
  const watchReturnAirfare = useWatch({
    name: `tripItems.${tripIndex}.returnAirfare`,
  });
  const tripItems = useWatch({ name: 'tripItems', control });

  const { data: commonData } = useQuery<TravelerAuthorizationCommonData>(
    TRAVELER_AUTHORIZATION_COMMON_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { tripSectionId, tripSectionItemId, id } = { ...tripItems[tripIndex] };
  const isNew = !id;
  const departureDepotId = useWatch({
    name: `tripItems.${tripIndex}.departureDepotId`,
    control,
  });
  const departureArrivalDepotId = useWatch({
    name: `tripItems.${tripIndex}.departureArrivalDepotId`,
    control,
  });
  const showReturnFields = !!departureDepotId && !!departureArrivalDepotId;
  const { nodes } = { ...commonData?.tripSections };
  const trainTravelSectionItems = nodes?.find(
    (ele) => ele.id === tripSectionId
  );
  const { tripSectionItems } = { ...trainTravelSectionItems };

  const onLinkClick = (item: PivotItem | undefined) => {
    const tripSectionItemId = tripSectionItems?.nodes.find(
      (ele) => ele.layoutType === item?.props?.itemKey
    )?.id;
    if (tripSectionItemId) {
      setValue(`tripItems.${tripIndex}.tripSectionItemId`, tripSectionItemId, {
        shouldDirty: true,
      });
      if (item?.props.itemKey === TransactionLayout.TRAIN_BOOKING_ONE_WAY) {
        setValue(`tripItems.${tripIndex}.returnDepotId`, null);
        setValue(`tripItems.${tripIndex}.returnDepotName`, null);
        setValue(`tripItems.${tripIndex}.returnDate`, null);
        setValue(`tripItems.${tripIndex}.returnTime`, null);
        setValue(`tripItems.${tripIndex}.returnAirfare`, null);
        setValue(`tripItems.${tripIndex}.isReturnRefundable`, false);
        setValue(`tripItems.${tripIndex}.returnArrivalDepotId`, null);
        setValue(`tripItems.${tripIndex}.returnArrivalDepotName`, null);
        setValue(`tripItems.${tripIndex}.returnArrivalDate`, null);
        setValue(`tripItems.${tripIndex}.returnArrivalTime`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierConfirmationCode`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierId`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierName`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierTripNumber`, null);
        setValue(`tripItems.${tripIndex}.returnSeat`, null);
        setValue(`tripItems.${tripIndex}.returnMiles`, null);
        setValue(`tripItems.${tripIndex}.isReturnBusinessClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnCharterClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnEconomyClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnFirstClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnPremiumClass`, false);
        setSelectedFareClass(null);
      }
    }
    setSelectedKey(
      item?.props.itemKey || TransactionLayout.TRAIN_BOOKING_ROUND_TRIP
    );
  };

  const totalAmount =
    parseFloat(watchDepartureAirfare || '0.0') +
    parseFloat(watchReturnAirfare || '0.0');

  const disablePivot = !_isUpdatable
    ? {
        disabled: true,
        style: { color: 'grey' },
      }
    : undefined;

  useEffect(() => {
    if (trainTravelSectionItems) {
      const layoutType = trainTravelSectionItems.tripSectionItems.nodes.find(
        (item) => item.id === tripSectionItemId
      )?.layoutType;
      setSelectedKey(layoutType!);
    }
  }, [trainTravelSectionItems, tripSectionItemId]);
  
 useEffect(() => {
    listRefreshRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      <Stack horizontal horizontalAlign="end">
        <Stack
          horizontal
          tokens={{ childrenGap: 20, padding: '0px 20px 0px 0px' }}
        >
          <Text variant="xLarge">Total </Text>
          <AmountTextView
            className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
            value={totalAmount?.toString() || '0.00'}
            variant="xLarge"
          />
        </Stack>
      </Stack>
      <Stack tokens={{ childrenGap: 30 }}>
        <Pivot selectedKey={selectedKey} onLinkClick={onLinkClick}>
          {tripSectionItems?.nodes
            .filter(
              (ele) =>
                ele.layoutType !== TransactionLayout.TRAIN_BOOKING_MULTI_TRIP
            )
            .map((item) => {
              return (
                <PivotItem
                  headerText={item.name}
                  itemKey={item.layoutType!}
                  key={item.id}
                  headerButtonProps={disablePivot}
                />
              );
            })}
        </Pivot>
        <Stack tokens={{ childrenGap: 60 }}>
          <DepartureLayout
            baseField={baseField}
            tripIndex={tripIndex}
            tripData={tripData}
            isNew={isNew}
            onFareClassSelect={setSelectedFareClass}
            tripType={selectedKey}
            travelAuthorizationData={travelAuthorizationData}
          />
          {isNew ? (
            showReturnFields && (
              <ReturnLayout
                baseField={baseField}
                tripIndex={tripIndex}
                tripData={tripData}
                selectedKey={selectedKey}
                fareClass={selectedFareClass}
                travelAuthorizationData={travelAuthorizationData}
              />
            )
          ) : (
            <ReturnLayout
              baseField={baseField}
              tripIndex={tripIndex}
              tripData={tripData}
              selectedKey={selectedKey}
              fareClass={selectedFareClass}
              travelAuthorizationData={travelAuthorizationData}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const DepartureLayout: React.FC<DepartureLayoutProps> = ({
  baseField,
  tripIndex,
  tripData,
  isNew,
  tripType,
  onFareClassSelect,
  travelAuthorizationData,
}) => {
  const commonStyles = useCommonStyles();
  const { setValue } = useFormContext<TripCreateFormValues>();
  const departureDepotName = useWatch({
    name: `tripItems.${tripIndex}.departureDepotName`,
  });
  const arrivalDepotName = useWatch({
    name: `tripItems.${tripIndex}.arrivalDepotName`,
  });
  const carrierName = useWatch({ name: `tripItems.${tripIndex}.carrierName` });
  const carrierCalloutId = useId(`departureCarrier`);
  const departureFromDepot = useId(`departureFromDepot`);
  const departureToDepot = useId(`departureToDepot`);

  const {
    isDepartureEconomyClass,
    isDeparturePremiumClass,
    isDepartureBusinessClass,
    isDepartureFirstClass,
    isDepartureCharterClass,
  } = {
    ...tripData?.tripItems.nodes[tripIndex],
  };

  const fareClassObject = {
    isDepartureEconomyClass,
    isDeparturePremiumClass,
    isDepartureBusinessClass,
    isDepartureFirstClass,
    isDepartureCharterClass,
  };
  const [selectedFareClass] =
    Object.entries(fareClassObject).find(([key, value]) => value === true) ||
    [];

  const onFareClassChange = (selectedClass: string) => {
    if (tripType !== TransactionLayout.TRAIN_BOOKING_ONE_WAY) {
      const returnKey = returnFlightClassOptions.find(
        (ele) => ele.text === selectedClass
      )?.key as keyof TripItemFormValues;
      if (returnKey) {
        const departureClass = returnKey as keyof TripItemFormValues;
        if (departureClass) {
          const keysToModify = returnFlightClassOptions.map(
            (ele) => ele.key! as keyof TripItemFormValues
          );
          keysToModify.forEach((ele) => {
            if (ele === departureClass) {
              setValue(`tripItems.${tripIndex}.${ele}`, true, {
                shouldDirty: true,
              });
            } else {
              setValue(`tripItems.${tripIndex}.${ele}`, false, {
                shouldDirty: true,
              });
            }
          });
        }
        onFareClassSelect(returnKey);
      }
    }
  };

  const { _isUpdatable, _isNonAmountUpdatableAfterApproval } = {
    ...travelAuthorizationData,
  };

  const isDisabled = !!_isNonAmountUpdatableAfterApproval
    ? false
    : !_isUpdatable;

  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Text
          variant="large"
          className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
        >
          Departure
        </Text>
        <Icon iconName="ArrowTallUpRight" />
      </Stack>
      <Stack tokens={{ childrenGap: 20 }} grow>
        {/* FROM */}
        <Stack horizontal verticalAlign="end" horizontalAlign="space-between">
          <Stack horizontal grow={3} tokens={{ childrenGap: 10 }}>
            <DepotAutoComplete
              name={`${baseField}departureDepotId`}
              placeholder="Enter Location"
              label="From"
              labelId="departureDepotId"
              lookUpCalloutWidth={300}
              id={departureFromDepot}
              depotData={departureDepotName}
              isTrain
              disabled={isDisabled}
              onDepotSelect={(data) => {
                if (data) {
                  setValue(
                    `tripItems.${tripIndex}.departureDepotName`,
                    data.description || null
                  );
                  if (
                    isNew &&
                    tripType !== TransactionLayout.TRAIN_BOOKING_ONE_WAY
                  ) {
                    setValue(
                      `tripItems.${tripIndex}.returnArrivalDepotId`,
                      data.id || null
                    );
                    setValue(
                      `tripItems.${tripIndex}.returnArrivalDepotName`,
                      data.description || null
                    );
                  }
                }
              }}
              onClear={() => {
                setValue(`tripItems.${tripIndex}.departureDepotName`, null);
              }}
            />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack style={{ width: 160 }}>
                <FormHookDateSelect
                  label="Departure Date"
                  name={`${baseField}departureDate`}
                  placeholder="Select Date"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  disabled={isDisabled}
                  showMonthPickerAsOverlay
                  showGoToToday
                  onSelectDateCallback={(date) => {
                    if (date)
                      setValue(
                        `tripItems.${tripIndex}.departureArrivalDate`,
                        date.toString() || null
                      );
                  }}
                />
              </Stack>
              <Stack>
                <Label disabled={isDisabled}>Time</Label>
                <FormHookTimePicker
                  name={`${baseField}departureTime`}
                  disabled={isDisabled}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack grow={2} horizontal verticalAlign="center">
            <Stack grow={3}>
              <FormHookAmount
                name={`${baseField}departureAirfare`}
                placeholder="Enter Train Fare"
                label="Train Fare"
                fixedDecimalScale
                allowNegative={false}
                decimalScale={2}
                disabled={!_isUpdatable}
                styles={{ fieldGroup: { width: 150 } }}
              />
            </Stack>
            <Stack grow={1}>
              <FormHookCheckBox
                label="Refundable"
                name={`${baseField}isDepartureRefundable`}
                disabled={isDisabled}
              />
            </Stack>
          </Stack>
        </Stack>

        {/* TO */}
        <Stack
          horizontal
          verticalAlign="end"
          horizontalAlign="space-between"
          tokens={{ childrenGap: 190 }}
        >
          <Stack horizontal grow={3} tokens={{ childrenGap: 10 }}>
            <Stack>
              <DepotAutoComplete
                name={`${baseField}departureArrivalDepotId`}
                placeholder="Enter Location"
                label="To"
                labelId="departureTo"
                lookUpCalloutWidth={300}
                disabled={isDisabled}
                id={departureToDepot}
                depotData={arrivalDepotName}
                isTrain
                onDepotSelect={(data) => {
                  if (data) {
                    setValue(
                      `tripItems.${tripIndex}.arrivalDepotName`,
                      data.description || null
                    );
                    if (
                      isNew &&
                      tripType !== TransactionLayout.TRAIN_BOOKING_ONE_WAY
                    ) {
                      setValue(
                        `tripItems.${tripIndex}.returnDepotId`,
                        data.id || null
                      );
                      setValue(
                        `tripItems.${tripIndex}.returnDepotName`,
                        data.description || null
                      );
                    }
                  }
                }}
                onClear={() => {
                  setValue(`tripItems.${tripIndex}.arrivalDepotName`, null);
                }}
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack style={{ width: 160 }}>
                <FormHookDateSelect
                  label="Arrival Date"
                  name={`${baseField}departureArrivalDate`}
                  placeholder="Select Date"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday
                  disabled={isDisabled}
                />
              </Stack>
              <Stack>
                <Label disabled={isDisabled}>Time</Label>
                <FormHookTimePicker
                  name={`${baseField}departureArrivalTime`}
                  disabled={isDisabled}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack grow={2} horizontal tokens={{ childrenGap: 10 }}>
            <FareClasses
              tripIndex={tripIndex}
              options={departureFlightClassOptions}
              selectedFareClass={selectedFareClass}
              onClassChange={onFareClassChange}
              disabled={isDisabled}
            />
            <FormHookTextField
              label="Confirmation code"
              name={`${baseField}departureCarrierConfirmationCode`}
              placeholder="Enter confirmation code"
              disabled={isDisabled}
            />
          </Stack>
        </Stack>

        {/* CARRIER */}
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack
            horizontal
            grow={3}
            tokens={{ childrenGap: 10 }}
            verticalAlign="end"
          >
            <Stack>
              <CarrierAutocomplete
                name={`${baseField}departureCarrierId`}
                placeholder="Enter Carrier"
                label="Carrier"
                lookUpCalloutWidth={300}
                disabled={isDisabled}
                isTrainLookup
                id={carrierCalloutId}
                carrierData={carrierName}
                onCarrierSelect={(data) => {
                  if (data) {
                    setValue(
                      `tripItems.${tripIndex}.carrierName`,
                      data.description || null
                    );
                    if (tripType !== TransactionLayout.TRAIN_BOOKING_ONE_WAY) {
                      setValue(
                        `tripItems.${tripIndex}.returnCarrierId`,
                        data.id || null
                      );
                      setValue(
                        `tripItems.${tripIndex}.returnCarrierName`,
                        data.description || null
                      );
                    }
                  }
                }}
                onClear={() => {
                  setValue(`tripItems.${tripIndex}.carrierName`, null);
                }}
              />
            </Stack>

            <Stack>
              <FormHookTextField
                label="Train Number"
                name={`${baseField}departureCarrierTripNumber`}
                placeholder="Enter train number"
                styles={{ root: { width: 150 } }}
                disabled={isDisabled}
              />
            </Stack>

            <Stack>
              <FormHookTextField
                label="Seat Number"
                name={`${baseField}departureSeat`}
                placeholder="Enter seat number"
                styles={{ root: { width: 150 } }}
                disabled={isDisabled}
              />
            </Stack>
          </Stack>
          <Stack grow={2} horizontal verticalAlign="end" horizontalAlign="end">
            <FormHookAmount
              name={`${baseField}departureMiles`}
              placeholder="Enter Miles"
              label="Miles"
              fixedDecimalScale
              allowNegative={false}
              decimalScale={0}
              disabled={isDisabled}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ReturnLayout: React.FC<ReturnLayoutProps> = ({
  baseField,
  tripIndex,
  tripData,
  selectedKey,
  fareClass,
  travelAuthorizationData,
}) => {
  const { setValue } = useFormContext<TripCreateFormValues>();
  const returnDepotName = useWatch({
    name: `tripItems.${tripIndex}.returnDepotName`,
  });
  const returnArrivalDepotName = useWatch({
    name: `tripItems.${tripIndex}.returnArrivalDepotName`,
  });
  const returnCarrierName = useWatch({
    name: `tripItems.${tripIndex}.returnCarrierName`,
  });
  const calloutId = useId(`returnCarrier`);
  const returnFromDepot = useId(`returnFromDepot`);
  const returnToDepot = useId(`returnToDepot`);
  const commonStyles = useCommonStyles();
  if (selectedKey === TransactionLayout.TRAIN_BOOKING_ONE_WAY) return null;
  const {
    isReturnEconomyClass,
    isReturnPremiumClass,
    isReturnBusinessClass,
    isReturnFirstClass,
    isReturnCharterClass,
  } = {
    ...tripData?.tripItems.nodes[tripIndex],
  };

  const fareClassObject = {
    isReturnEconomyClass,
    isReturnPremiumClass,
    isReturnBusinessClass,
    isReturnFirstClass,
    isReturnCharterClass,
  };
  const [selectedFareClass] =
    Object.entries(fareClassObject).find(([key, value]) => value === true) ||
    [];
  const optionalLabel =
    selectedKey === TransactionLayout.TRAIN_BOOKING_MULTI_TRIP
      ? '(Optional)'
      : '';

  const { _isUpdatable, _isNonAmountUpdatableAfterApproval } = {
    ...travelAuthorizationData,
  };

  const isDisabled = !!_isNonAmountUpdatableAfterApproval
    ? false
    : !_isUpdatable;

  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
        <Text
          variant="large"
          className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
        >
          {`Return ${optionalLabel}`}
        </Text>
        <Icon iconName="ArrowTallDownLeft" />
      </Stack>

      <Stack tokens={{ childrenGap: 20 }} grow>
        {/* FROM */}
        <Stack horizontal verticalAlign="end" horizontalAlign="space-between">
          <Stack horizontal grow={3} tokens={{ childrenGap: 10 }}>
            <DepotAutoComplete
              name={`${baseField}returnDepotId`}
              placeholder="Enter Location"
              label="From"
              labelId="returnDepotFrom"
              lookUpCalloutWidth={300}
              disabled={isDisabled}
              id={returnFromDepot}
              depotData={returnDepotName}
              isTrain
              onDepotSelect={(data) => {
                if (data) {
                  setValue(
                    `tripItems.${tripIndex}.returnDepotName`,
                    data.description || null
                  );
                }
              }}
              onClear={() => {
                setValue(`tripItems.${tripIndex}.returnDepotName`, null);
              }}
            />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack style={{ width: 160 }}>
                <FormHookDateSelect
                  label="Return Date"
                  name={`${baseField}returnDate`}
                  placeholder="Select Date"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday
                  disabled={isDisabled}
                  onSelectDateCallback={(date) => {
                    if (date)
                      setValue(
                        `tripItems.${tripIndex}.returnArrivalDate`,
                        date.toString() || null
                      );
                  }}
                />
              </Stack>
              <Stack>
                <Label disabled={isDisabled}>Time</Label>
                <FormHookTimePicker
                  name={`${baseField}returnTime`}
                  disabled={isDisabled}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack grow={2} horizontal verticalAlign="center">
            <Stack grow={3}>
              <FormHookAmount
                name={`${baseField}returnAirfare`}
                placeholder="Enter Train Fare"
                label="Train Fare"
                fixedDecimalScale
                allowNegative={false}
                decimalScale={2}
                disabled={!_isUpdatable}
                styles={{ fieldGroup: { width: 150 } }}
              />
            </Stack>
            <Stack grow={1}>
              <FormHookCheckBox
                name={`${baseField}isReturnRefundable`}
                label="Refundable"
                disabled={isDisabled}
              />
            </Stack>
          </Stack>
        </Stack>

        {/* TO */}
        <Stack
          horizontal
          verticalAlign="end"
          horizontalAlign="space-between"
          tokens={{ childrenGap: 190 }}
        >
          <Stack horizontal grow={3} tokens={{ childrenGap: 10 }}>
            <Stack>
              <DepotAutoComplete
                name={`${baseField}returnArrivalDepotId`}
                placeholder="Enter Location"
                label="To"
                labelId="returnArrivalDepot"
                lookUpCalloutWidth={300}
                disabled={isDisabled}
                id={returnToDepot}
                depotData={returnArrivalDepotName}
                isTrain
                onDepotSelect={(data) => {
                  if (data) {
                    setValue(
                      `tripItems.${tripIndex}.returnArrivalDepotName`,
                      data.description || null
                    );
                  }
                }}
                onClear={() => {
                  setValue(
                    `tripItems.${tripIndex}.returnArrivalDepotName`,
                    null
                  );
                }}
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack style={{ width: 160 }}>
                <FormHookDateSelect
                  label="Arrival Date"
                  name={`${baseField}returnArrivalDate`}
                  placeholder="Select Date"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday
                  disabled={isDisabled}
                />
              </Stack>
              <Stack>
                <Label disabled={isDisabled}>Time</Label>
                <FormHookTimePicker
                  name={`${baseField}returnArrivalTime`}
                  disabled={isDisabled}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack grow={2} horizontal tokens={{ childrenGap: 10 }}>
            <FareClasses
              tripIndex={tripIndex}
              options={returnFlightClassOptions}
              selectedFareClass={fareClass || selectedFareClass}
              disabled={isDisabled}
            />
            <FormHookTextField
              label="Confirmation code"
              name={`${baseField}returnCarrierConfirmationCode`}
              placeholder="Enter confirmation code"
              disabled={isDisabled}
            />
          </Stack>
        </Stack>

        {/* CARRIER */}
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack
            horizontal
            grow={3}
            tokens={{ childrenGap: 10 }}
            verticalAlign="end"
          >
            <Stack>
              <CarrierAutocomplete
                name={`${baseField}returnCarrierId`}
                placeholder="Enter Carrier"
                label="Carrier"
                lookUpCalloutWidth={300}
                disabled={isDisabled}
                isTrainLookup
                id={calloutId}
                carrierData={returnCarrierName}
                onCarrierSelect={(data) => {
                  if (data) {
                    setValue(
                      `tripItems.${tripIndex}.returnCarrierName`,
                      data.description || null
                    );
                  }
                }}
                onClear={() => {
                  setValue(`tripItems.${tripIndex}.returnCarrierName`, null);
                }}
              />
            </Stack>

            <Stack>
              <FormHookTextField
                label="Train Number"
                name={`${baseField}returnCarrierTripNumber`}
                placeholder="Enter train number"
                styles={{ root: { width: 150 } }}
                disabled={isDisabled}
              />
            </Stack>

            <Stack>
              <FormHookTextField
                label="Seat Number"
                name={`${baseField}returnSeat`}
                placeholder="Enter seat number"
                styles={{ root: { width: 150 } }}
                disabled={isDisabled}
              />
            </Stack>
          </Stack>

          <Stack grow={2} horizontal verticalAlign="end" horizontalAlign="end">
            <FormHookAmount
              name={`${baseField}returnMiles`}
              placeholder="Enter Miles"
              label="Miles"
              fixedDecimalScale
              allowNegative={false}
              decimalScale={0}
              disabled={isDisabled}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
