import { useLazyQuery } from '@apollo/client';
import { DayOfWeek, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { FormHookTextField } from 'common/components/FormHooksFields';
import { FormHookDateSelect } from 'common/components/FormHooksFields/FormHookDateSelect';
import { FormHookTimePicker } from 'common/components/FormHooksFields/FormHookTimePicker';
import { useCommonStyles } from 'common/styles';
import { StandardCalculationType } from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { EndDateCalculator } from 'purchaseOrder/view/FormView/POItems/POItemsModal/POItemsForm/EndDateCalculator';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TripCreateFormValues } from '../../../../types';
import { LabelWrapper } from '../VehicleRental';
import { AddressSectionInput } from '../components/AddressSectionInput';
import { ContactSectionInput } from '../components/ContactSectionInput';
import { ProviderSearch } from '../components/ProviderSearch';
import { RateAndTotalSection } from '../components/RateAndTotalSection';
import {
  DateCalcConsecutiveNightTotals,
  DateCalcConsecutiveNightTotalsVariables,
} from '../components/RateAndTotalSection/__generated__/DateCalcConsecutiveNightTotals';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
const CAL_QUERY = loader(
  '../components/RateAndTotalSection/DateCalcConsecutiveNightTotals.graphql'
);

export const Accommodation: React.FC<{
  tripIndex: number;
  tripSectionId: string | null | undefined;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
  listRefreshRequest: () => void;
}> = ({
  tripIndex,
  tripSectionId,
  travelAuthorizationData,
  listRefreshRequest,
}) => {
  const baseField = `tripItems.${tripIndex}.`;
  const commonStyles = useCommonStyles();
  const { setValue } = useFormContext<TripCreateFormValues>();
  const rentalPrice = useWatch({ name: `${baseField}rentalPrice` });
  const checkInDate = useWatch({ name: `${baseField}checkInDate` });
  const checkOutDate = useWatch({ name: `${baseField}checkOutDate` });
  const itemTotal = useWatch({ name: `${baseField}itemTotal` });

  const [getItemTotals, { loading }] = useLazyQuery<
    DateCalcConsecutiveNightTotals,
    DateCalcConsecutiveNightTotalsVariables
  >(CAL_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted(data) {
      setValue(
        `tripItems.${tripIndex!}.itemTotal`,
        data.dateCalcConsecutiveNightTotals?.periodTotal!,
        { shouldTouch: true }
      );
      setValue(
        `tripItems.${tripIndex!}.rentalTerm`,
        data.dateCalcConsecutiveNightTotals?.fullPeriods!,
        { shouldTouch: true }
      );
    },
  });

  const calculateNewRate = (
    rate: string,
    startDate: string,
    endDate: string
  ) => {
    if (parseInt(rate) > 0 && startDate && endDate) {
      getItemTotals({
        variables: {
          endDate,
          startDate,
          rate,
        },
      });
    }
  };

  const { _isUpdatable, _isNonAmountUpdatableAfterApproval } = {
    ...travelAuthorizationData,
  };

  const isDisabled = !!_isNonAmountUpdatableAfterApproval
    ? false
    : !_isUpdatable;

  useEffect(() => {
    listRefreshRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack tokens={{ childrenGap: 40 }}>
      <Stack horizontal horizontalAlign="end">
        <Stack
          horizontal
          tokens={{ childrenGap: 20, padding: '0px 20px 0px 0px' }}
        >
          <Text variant="xLarge">Total </Text>
          <AmountTextView
            className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
            value={itemTotal?.toString() || '0.00'}
            variant="xLarge"
          />
        </Stack>
      </Stack>
      <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="end">
        <Stack grow={2}>
          <ProviderSearch
            tripIndex={tripIndex}
            placeholder={'Enter hotel name'}
            label={'Hotel'}
            name={`${baseField}rentalName`}
            tripSectionId={tripSectionId}
            disabled={isDisabled}
          />
        </Stack>
        <Stack grow={1}>
          <FormHookTextField
            label="Confirmation code"
            name={`${baseField}departureCarrierConfirmationCode`}
            styles={{ field: { height: 50 }, fieldGroup: { height: 50 } }}
            disabled={isDisabled}
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        horizontalAlign="space-between"
        grow
        tokens={{ childrenGap: 20 }}
      >
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <LabelWrapper content="Check-In" disabled={isDisabled}>
            <FormHookDateSelect
              name={`${baseField}checkInDate`}
              placeholder="Check-In Day"
              firstDayOfWeek={DayOfWeek.Sunday}
              firstWeekOfYear={1}
              showMonthPickerAsOverlay
              showGoToToday
              styles={{ root: { width: 150, marginTop: '10 px !important' } }}
              onSelectDateCallback={(date) => {
                calculateNewRate(
                  rentalPrice,
                  dateFormat(date!.toString()),
                  checkOutDate
                );
              }}
              disabled={isDisabled}
            />
          </LabelWrapper>
          <Stack style={{ marginTop: -25 }}>
            <EndDateCalculator
              label="Calculate Check-Out Date"
              calculationType={StandardCalculationType.PO_DAILY_NIGHTLY}
              startDate={checkInDate}
              onEndDateReturn={(date) => {
                setValue(
                  `tripItems.${tripIndex}.checkOutDate`,
                  dateConvertions(date),
                  { shouldDirty: true }
                );
              }}
              disabled={isDisabled}
            />
          </Stack>
          <Stack>
            <LabelWrapper content="Time" disabled={isDisabled}>
              <FormHookTimePicker
                name={`${baseField}checkInTime`}
                disabled={isDisabled}
              />
            </LabelWrapper>
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <LabelWrapper content="Check-Out" disabled={isDisabled}>
            <FormHookDateSelect
              name={`${baseField}checkOutDate`}
              placeholder="Check-Out Day"
              firstDayOfWeek={DayOfWeek.Sunday}
              firstWeekOfYear={1}
              showMonthPickerAsOverlay
              showGoToToday
              styles={{ root: { width: 150, marginTop: '10 px !important' } }}
              onSelectDateCallback={(date) => {
                calculateNewRate(
                  rentalPrice,
                  checkInDate,
                  dateFormat(date!.toString())
                );
              }}
              disabled={isDisabled}
            />
          </LabelWrapper>
          <Stack>
            <LabelWrapper content="Time" disabled={isDisabled}>
              <FormHookTimePicker
                name={`${baseField}checkOutTime`}
                disabled={isDisabled}
              />
            </LabelWrapper>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: 100 }}
      >
        <AddressSectionInput baseField={baseField} disabled={isDisabled} />
        <ContactSectionInput baseField={baseField} disabled={isDisabled} />
      </Stack>
      <RateAndTotalSection
        baseField={baseField}
        tripIndex={tripIndex}
        loading={loading}
        onRateEntry={(rate) => {
          calculateNewRate(rate, checkInDate, checkOutDate);
        }}
        isAccommodation={'Nights'}
        disabled={!_isUpdatable}
      />
    </Stack>
  );
};
