import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  DefaultButton,
  IDropdownOption,
  IPanelHeaderRenderer,
  IPanelProps,
  IRenderFunction,
  MessageBar,
  MessageBarType,
  Separator,
  Spinner,
  Stack,
} from '@fluentui/react';
import DraggablePanel from 'common/components/DraggablePanel';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { ReportStatusType, UploadStatusType } from 'common/types/globalTypes';
import { PanelCommonProps } from 'common/types/utility';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { setUserDefaults } from 'utility/cache/ui';
import { TravelAuthorization_travelAuthorization } from '../__generated__/TravelAuthorization';
import { TravelAuthorizationValues } from '../interface';
import { ActionMenu } from './ActionMenu';
import { ApprovalHistoryView } from './ApprovalHistoryView';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { Header } from './Header';
import { RevisionHistory } from './RevisionHistory';
import { ShimmerView } from './Shimmer/ShimmerViews';
import { Trips } from './Trips';
import { TripList } from './Trips/TripList';

import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import {
  TravelAuthComment,
  TravelAuthCommentVariables,
} from '../__generated__/TravelAuthComment';
import {
  TravelAuthorizationReportPreview,
  TravelAuthorizationReportPreviewVariables,
} from './__generated__/TravelAuthorizationReportPreview';
import {
  TravelAuthorizationReportPreviewStatus,
  TravelAuthorizationReportPreviewStatusVariables,
} from './__generated__/TravelAuthorizationReportPreviewStatus';
import { TravelerAuthorizationCommonData } from './__generated__/TravelerAuthorizationCommonData';
import { useStyles } from './index.styles';
import { MovementOrders } from './MovementOrders';

const TRAVELER_AUTHORIZATION_COMMON_DATA = loader(
  './TravelerAuthorizationCommonData.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);

const TA_PREVIEW_REPORT = loader('./TravelAuthorizationReportPreview.graphql');
const PREVIEW_REPORT_STATUS = loader(
  './TravelAuthorizationReportPreviewStatus.graphql'
);

const REFRESH_COMMENT = loader('../TravelAuthComment.graphql');

const PANEL_WIDTH = 1200;

interface FormViewProps {
  isNew: boolean;
  isLoading: boolean; //Boolean indicating travel authorization data is being fetched.
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
  refetch: () => void;
  onSave: () => void;
  isSaveAnother: (saveAnother: boolean) => void;
}

export const FormView: React.FC<FormViewProps> = ({
  isNew,
  isLoading,
  travelAuthorizationData,
  refetch,
  onSave,
  isSaveAnother,
}) => {
  const history = useHistory();
  const client = useApolloClient();
  const { addToast, updateToast } = useToasts();
  const userDefaults = useReactiveVar(setUserDefaults);
  const [corporatePeriod, setCorporatePeriod] = useState<IDropdownOption>();
  const styles = useStyles();
  const {
    formState: { isDirty, isSubmitting },
  } = useFormContext<TravelAuthorizationValues>();

  const { _isUpdatable, approvalHistoriesByEntityId } = {
    ...travelAuthorizationData,
  };

  const inputsDisabled = !isNew && !_isUpdatable;

  const { data: commonData } = useQuery<TravelerAuthorizationCommonData>(
    TRAVELER_AUTHORIZATION_COMMON_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
    title: '',
  });

  const { secureRowLevels, companyCorporatePeriods } = { ...commonData };

  const companyCorporatePeriodsOptions: IDropdownOption[] =
    companyCorporatePeriods?.nodes.map((item) => ({
      disabled: item.isEntryAllowed ? false : true,
      key: item.id,
      text:
        item._periodYear +
        `(${
          item.startDate! ? dateFormat(dateConvertions(item.startDate!)) : ''
        } - ${
          item.endDate! ? dateFormat(dateConvertions(item.endDate!)) : ''
        })`,
    })) || [];

  const loading = isSubmitting;

  const _onRenderHeader: IPanelHeaderRenderer = () => {
    return (
      <Header
        isNew={isNew}
        dirty={isDirty}
        loading={isLoading}
        travelAuthorizationData={travelAuthorizationData}
        isSubmitting={isSubmitting}
        companyCorporatePeriods={companyCorporatePeriods}
        onRefetch={refetch}
      />
    );
  };

  const showShimmer: boolean = !isNew && isLoading;

  const _onRenderFooter: IRenderFunction<IPanelProps> = () => {
    return (
      <Footer
        isNew={isNew}
        isLoading={loading}
        dataLoading={isLoading}
        onSave={(saveAnother) => {
          isSaveAnother(saveAnother);
          onSave();
        }}
        travelAuthorizationData={travelAuthorizationData}
        onRefetch={refetch}
      />
    );
  };

  useEffect(() => {
    const selected = companyCorporatePeriodsOptions.find(
      (item) => item.key === userDefaults?.companyCorporatePeriod?.id
    );
    if (selected && !corporatePeriod) setCorporatePeriod(selected);
  }, [companyCorporatePeriodsOptions, corporatePeriod, userDefaults]);

  const [fetchComment] = useLazyQuery<
    TravelAuthComment,
    TravelAuthCommentVariables
  >(REFRESH_COMMENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const showPreviewButton = !(
    travelAuthorizationData?.statusType?.statusType === 'Pending' ||
    travelAuthorizationData?.statusType?.statusType === 'Approved'
  );
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const [previewTAReport] = useMutation<
    TravelAuthorizationReportPreview,
    TravelAuthorizationReportPreviewVariables
  >(TA_PREVIEW_REPORT, { errorPolicy: 'all' });

  const handlePreviewClick = async () => {
    if (travelAuthorizationData) {
      setShowLoader(true);
      const { errors, data } = await previewTAReport({
        variables: {
          input: { travelAuthorizationId: travelAuthorizationData.id },
        },
      });

      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
        setShowLoader(false);
      } else {
        const observer = client.subscribe<
          TravelAuthorizationReportPreviewStatus,
          TravelAuthorizationReportPreviewStatusVariables
        >({
          query: PREVIEW_REPORT_STATUS,
          variables: {
            id: data?.travelAuthorizationReportPreview?.travelAuthorizationId!,
          },
        });

        const subscription = observer.subscribe(({ data, errors }) => {
          if (errors) {
            addToast('Errors received while subscribing to report generation', {
              appearance: 'error',
            });
            setShowLoader(false);
          } else {
            const { document, status } = {
              ...data?.travelAuthorizationReportPreviewStatus,
            };
            if (status === ReportStatusType.SUCCESS) {
              addToast('Report generation successful.', {
                appearance: 'success',
              });
              if (document?._downloadLink) {
                setDocViewState({
                  ...docViewState,
                  entityDocumentId:
                    data?.travelAuthorizationReportPreviewStatus?.document?.id,
                  isOpen: true,
                  title: '(Preview)',
                });
              }
            }
            if (status === ReportStatusType.FAILURE) {
              addToast('Report generation failed.', { appearance: 'error' });
            }
          }
          setShowLoader(false);
          subscription.unsubscribe();
        });
      }
    }
  };

  return (
    <>
      <DraggablePanel
        initialWidth={PANEL_WIDTH}
        minWidth={PANEL_WIDTH}
        onRenderHeader={_onRenderHeader}
        onRenderFooter={_onRenderFooter}
        {...PanelCommonProps}
        isBlocking={isNew}
        isOpen
        onDismiss={() => {
          history.replace('/ta/travel-plan');
        }}
        isLightDismiss
      >
        {/* ADD SHIMMER COMPONENT HERE */}
        {showShimmer ? (
          <ShimmerView />
        ) : (
          <>
            <Stack
              tokens={{
                // Base padding to add gaps :P
                padding: '20px 10px',
                childrenGap: 10,
              }}
            >
              {/* ADD GLOBAL ACTIONS COMPONENT HERE */}
              {!isNew && travelAuthorizationData && (
                <Stack
                  tokens={{
                    childrenGap: 20,
                  }}
                >
                  {!!travelAuthorizationData?._requiredApprovalDocuments && (
                    <MessageBar messageBarType={MessageBarType.error}>
                      {travelAuthorizationData?._requiredApprovalDocuments}
                    </MessageBar>
                  )}
                  <Stack horizontal>
                    {travelAuthorizationData && (
                      <ActionMenu
                        onRefetch={refetch}
                        travelAuthorizationData={travelAuthorizationData}
                        secureRowLevels={secureRowLevels}
                        onUpload={async (fileSelected, document, toastId) => {
                          const observer = client.subscribe({
                            query: DOCUMENT_UPLOAD_STATUS,
                            variables: {
                              documentId: document.document._documentFileId!,
                            },
                          });
                          const subscription = observer.subscribe(
                            (response) => {
                              const subscribedData =
                                response.data as OnDocumentUploadStatus;

                              const { status, document } = {
                                ...subscribedData.documentUploadStatus,
                              };

                              if (!document) {
                                if (
                                  status.type === UploadStatusType.VALIDATING
                                ) {
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Validating files ${fileSelected.name} - ${status.message}`
                                      : `Validating files ${fileSelected.name}`,
                                    appearance: 'info',
                                    autoDismiss: false,
                                  });
                                } else if (
                                  status.type === UploadStatusType.EXTRACTING
                                ) {
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                      : `Extracting data from ${fileSelected.name}`,
                                    appearance: 'info',
                                    autoDismiss: false,
                                  });
                                } else if (
                                  status.type === UploadStatusType.FAILURE
                                ) {
                                  subscription.unsubscribe();
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                      : `Upload of ${fileSelected.name} failed`,
                                    appearance: 'error',
                                    autoDismiss: true,
                                  });
                                } else if (
                                  status.type === UploadStatusType.WARNING
                                ) {
                                  subscription.unsubscribe();
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Warning for file ${fileSelected.name}: ${status.message}`
                                      : `Warning for file ${fileSelected.name}`,
                                    appearance: 'warning',
                                    autoDismiss: true,
                                  });
                                }
                              } else {
                                subscription.unsubscribe();
                                updateToast(toastId!, {
                                  content: status.message
                                    ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                                    : `Successfully uploaded ${fileSelected.name}`,
                                  appearance: 'success',
                                  autoDismiss: true,
                                });
                                refetch?.();
                              }
                            }
                          );
                        }}
                      >
                        <MovementOrders
                          travelAuthorizationData={travelAuthorizationData}
                        />
                      </ActionMenu>
                    )}
                    {showPreviewButton && (
                      <Stack style={{ marginLeft: 'auto' }}>
                        <DefaultButton
                          className={styles.disabledButton}
                          onClick={handlePreviewClick}
                        >
                          {showLoader ? <Spinner /> : 'Preview'}
                        </DefaultButton>
                      </Stack>
                    )}
                  </Stack>

                  <Separator />
                </Stack>
              )}

              {/* ADD BASIC FORM COMPONENT HERE  */}
              <BasicForm
                isNew={isNew}
                inputsDisabled={inputsDisabled}
                commonData={commonData}
                travelAuthorizationData={travelAuthorizationData}
              />
              {!isNew && (
                <>
                  {/* TRIPS */}
                  <Trips
                    isDirty={isDirty}
                    inputsDisabled={inputsDisabled}
                    travelAuthorizationData={travelAuthorizationData}
                    onClose={() => {
                      if (
                        travelAuthorizationData?.id &&
                        !travelAuthorizationData?.comment
                      )
                        fetchComment({
                          variables: { id: travelAuthorizationData?.id! },
                        });
                    }}
                  />
                  {/* TRIP LIST */}
                  <TripList
                    isDirty={isDirty}
                    inputsDisabled={inputsDisabled}
                    travelAuthorizationData={travelAuthorizationData}
                  />
                  {/* ADD SIGNATURE COMPONENT HERE  */}

                  {/* ADD APPROVAL HISTORY COMPONENT HERE  */}
                  <ApprovalHistoryView
                    approvalHistoriesByEntityId={approvalHistoriesByEntityId}
                  />
                </>
              )}
              <RevisionHistory travelAuthorization={travelAuthorizationData} />
            </Stack>
          </>
        )}
        <Prompt
          when={isDirty && !isSubmitting}
          message="Are you sure you want to leave your changes unsaved?"
        />
      </DraggablePanel>
      <DocumentViewModal
        paddingLeft={100}
        paddingTop={0}
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
