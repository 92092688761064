import { FontWeights, makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0px 25px',
  },
  formRow: {
    margin: '14px 0px',
  },
  fieldContainer: {
    flex: 1,
  },
  travelPreferencesHeading: {
    marginBottom: 20,
  },
  halfPanelWidth: {
    width: '50%',
  },
  heading: {
    fontWeight: FontWeights.bold,
  },
  iconButtonColor: {
    marginLeft: 30,
    color: theme.palette.neutralDark,
  },
}));
