import { IColumn } from '@fluentui/react';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};
export type ColumnData = IColumn & {
  /** property to determine whether this column can be toggled for visibility */
  toggleVisibility?: boolean;
  /** property to determine whether the column is visible or not*/
  isVisible?: boolean;
  isFormField?: boolean;
};

export const useColumns = () => {
  const columns = new Map<string, ColumnData>([
    [
      'userProfileId',
      {
        key: 'userProfileId',
        name: 'User',
        fieldName: 'userProfileId',
        minWidth: 180,
        maxWidth: 180,
        ...commonColumnProps,
      },
    ],
    [
      'emailAccount',
      {
        key: 'emailAccount',
        name: 'Email',
        fieldName: 'emailAccount',
        minWidth: 230,
        maxWidth: 230,
        ...commonColumnProps,
      },
    ],
    [
      'profileIconState',
      {
        key: 'profileIconState',
        name: 'Status',
        fieldName: 'profileIconState',
        minWidth: 100,
        maxWidth: 100,
        ...commonColumnProps,
      },
    ],
    [
      '_lastUsedDate',
      {
        key: '_lastUsedDate',
        name: 'Last Sign-in',
        fieldName: '_lastUsedDate',
        minWidth: 180,
        maxWidth: 180,
        ...commonColumnProps,
      },
    ],
    [
      'delete',
      {
        key: 'delete',
        name: '',
        fieldName: 'delete',
        minWidth: 80,
        maxWidth: 80,
        ...commonColumnProps,
      },
    ],
  ]);

  let columnArray = Array.from(columns, (data) => data[1]);
  return { columns, columnArray };
};
