import { IconButton, Stack, Text, TooltipHost } from '@fluentui/react';
import { Chip } from 'common/components/Chip';
import {
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { ColumnData } from 'common/components/SearchBar';
import { IconState } from 'common/types/globalTypes';
import { getAcessStatusColor } from 'common/utils';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import React from 'react';
import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  CorporateWorkgroupValues,
  workgroupUserOptionType,
} from 'settings/account/corporateWorkGroups/view/types';

export interface FormFieldProps {
  baseField: string;
  columnData: ColumnData;
  remove: UseFieldArrayRemove;
  index: number;
  workgroupUsersOptions: workgroupUserOptionType[];
}

export const FormField: React.FC<FormFieldProps> = ({
  baseField,
  columnData,
  remove,
  index,
  workgroupUsersOptions,
}) => {
  const watchCorporateWorkgroup = useWatch<CorporateWorkgroupValues>();
  const { setValue, getValues, control } =
    useFormContext<CorporateWorkgroupValues>();
  const { fields } = useFieldArray({
    control,
    name: 'workgroupUsers',
  });
  const isLastItem = index === fields.length - 1;
  const isEdit = !!watchCorporateWorkgroup?.workgroupUsers?.[index]?.id;
  const isUpdatable =
    !isEdit || watchCorporateWorkgroup?.workgroupUsers?.[index]?._isUpdatable;
  const isDeletable =
    !isEdit || watchCorporateWorkgroup?.workgroupUsers?.[index]?._isDeletable;

  const currentUserId = getValues(`workgroupUsers.${index}.userProfileId`);

  const filteredWorkgroupUsers = workgroupUsersOptions.filter(
    (user) =>
      currentUserId === user.key ||
      !fields.some((field) => field.userProfileId === user.key)
  );

  switch (columnData.key) {
    case 'userProfileId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={filteredWorkgroupUsers}
          width={columnData.maxWidth}
          underlined
          disabled={!isUpdatable}
          onClear={() => {
            remove(index);
          }}
          onChange={(_, value) => {
            const selectedValue = value as workgroupUserOptionType;
            setValue(
              `workgroupUsers.${index}.emailAccount`,
              selectedValue?.emailAccount
            );
            setValue(
              `workgroupUsers.${index}.profileIconState`,
              selectedValue?.profileIconState
            );
            setValue(
              `workgroupUsers.${index}._lastUsedDate`,
              selectedValue?._lastUsedDate
            );
            setValue(
              `workgroupUsers.${index}.profileIconType`,
              selectedValue?.profileIconType
            );
          }}
        />
      );
    case '_lastUsedDate':
      const lastUsedDate = getValues(`workgroupUsers.${index}._lastUsedDate`);
      return (
        <Text style={{ width: columnData.maxWidth }}>
          {lastUsedDate ? getGlobalDateFormat(lastUsedDate) : ''}
        </Text>
      );
    case 'profileIconState':
      const profileIconType = getValues(
        `workgroupUsers.${index}.profileIconType`
      );
      const profileIconState = getValues(
        `workgroupUsers.${index}.profileIconState`
      );
      if (!profileIconType) return null;
      return (
        <Stack
          verticalAlign="center"
          horizontalAlign="start"
          style={{ marginLeft: 0 }}
        >
          <TooltipHost content={profileIconType ?? ''}>
            <Chip
              text={
                profileIconType && profileIconType?.length > 9
                  ? profileIconType?.slice(0, 9) + '...'
                  : profileIconType ?? ''
              }
              iconVisible={false}
              color={getAcessStatusColor(profileIconState as IconState)}
              buttonStylesCustom
            />
          </TooltipHost>
        </Stack>
      );

    case 'delete':
      if (isLastItem) return null;
      return (
        <TooltipHost content="Delete User" id="tooltipId">
          <IconButton
            iconProps={{ iconName: 'delete' }}
            ariaLabel="Delete"
            onClick={() => {
              remove(index);
            }}
            disabled={!isDeletable}
          />
        </TooltipHost>
      );

    case 'emailAccount':
      const email = getValues(`workgroupUsers.${index}.emailAccount`);
      return (
        <Text
          style={{
            width: columnData.maxWidth,
          }}
        >
          {email}
        </Text>
      );

    default:
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          disabled={!isUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
  }
};
