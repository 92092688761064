import { useQuery } from '@apollo/client';
import { Separator, Stack, Text } from '@fluentui/react';
import { loader } from 'graphql.macro';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { WORKGROUP_USERS_VALUES } from '../../../constants';
import {
  CorporateWorkgroupValues,
  workgroupUserOptionType,
} from '../../../types';
import { ColumnsHeader } from './ColumnHeader';
import { FormField } from './FormField';
import { AvailableWorkgroupUsers } from './__generated__/AvailableWorkgroupUsers';
import { useColumns } from './column.data';

const AVAILABLE_WORKGROUPS_USERS = loader('./availableWorkgroupUsers.graphql');

export const ProductionUsersSection: React.FC = () => {
  const { columnArray } = useColumns();
  const { control, watch } = useFormContext<CorporateWorkgroupValues>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'workgroupUsers',
  });
  const watchWorkgroupUsers = watch('workgroupUsers');
  const lastItem = watchWorkgroupUsers?.[fields.length - 1];

  const isValueExist = lastItem
    ? Object.entries(lastItem).reduce((prev, current) => {
        const isValue = current?.[1] !== null;
        return isValue || prev;
      }, false)
    : true;
  const { data: workgroupUsersList } = useQuery<AvailableWorkgroupUsers>(
    AVAILABLE_WORKGROUPS_USERS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const workgroupUsersOptions: workgroupUserOptionType[] =
    workgroupUsersList?.availableWorkgroupUsers?.nodes.map((item) => ({
      key: item.id,
      text: item?.name ?? 'NA',
      emailAccount: item?.emailAccount,
      profileIconState: item?._signinInfo?.profileIconState,
      profileIconType: item?._signinInfo?.profileIconType,
      _lastUsedDate: item?._lastUsedDate,
    })) || [];

  useEffect(() => {
    if (isValueExist) {
      append(WORKGROUP_USERS_VALUES, { shouldFocus: false });
    }
  }, [append, isValueExist]);

  return (
    <Stack
      tokens={{
        padding: '10px 25px 0px',
      }}
      style={{ marginTop: 10 }}
    >
      <Text variant="xLarge" style={{ marginBottom: 20, marginTop: 20 }}>
        Production Users
      </Text>
      <ColumnsHeader columnsArray={columnArray} />

      <Separator />
      <Stack>
        {fields.map((field, index) => {
          const baseField = `workgroupUsers.${index}`;
          return (
            <Stack
              key={index}
              horizontal
              tokens={{ childrenGap: 50 }}
              style={{ marginLeft: 20 }}
              verticalAlign="center"
            >
              {columnArray.map((value, key) => {
                if (value.isVisible)
                  return (
                    <FormField
                      baseField={baseField}
                      columnData={value}
                      key={field.id + key}
                      remove={remove}
                      index={index}
                      workgroupUsersOptions={workgroupUsersOptions}
                    />
                  );
                else return null;
              })}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
