export const DRAWER_OPEN_WIDTH = 230;
export const DRAWER_CLOSE_WIDTH = 50;

export const TABLE_ROWS = 40;
export const RATING_LIST = 5;

export const NOTIFICATIONS_PER_PAGE = 20;
export const NOTIFICATION_POLL_INTERVAL = 60000;
export const MESSAGES_PER_PAGE = 20;
export const ATTACHABLE_TABLE_ROWS = 100;
export enum GlobalActions {
  protect = 'PROTECT_ACTION',
  attach = 'ATTACH_ACTION',
  notes = 'NOTES_ACTION',
  history = 'HISTORY_ACTION',
  tags = 'TAGS_ACTION',
  messages = 'MESSAGES_ACTION',
}
export enum UserProfileStatus {
  'ACTIVE' = 'Active',
  'AMENDED' = 'Amended',
  'APPROVED' = 'Approved',
  'CANCELED' = 'Canceled',
  'DECLINED' = 'Declined',
  'DRAFT' = 'Draft',
  'PENDING' = 'Pending',
  'STAGED' = 'Staged',
}

export enum ApprovalState {
  'APPROVED' = 'APPROVED',
  'APPROVED_NOT_REQUIRED' = 'APPROVED_NOT_REQUIRED',
  'PENDING'='PENDING'
}
