import { useLazyQuery, useQuery } from '@apollo/client';
import {
  IDropdownOption,
  Label,
  Separator,
  Stack,
  TooltipDelay,
  TooltipHost,
} from '@fluentui/react';
import {
  FormikCheckBox,
  FormikDropdown,
  FormikTextField,
} from 'common/components';
import { formatDropdownOptions } from 'common/utils';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import { useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  UserSetupCommonData,
  UserSetupCommonData_companyDepartmentOccupationTemplates_nodes_departmentOccupationTemplate,
} from 'settings/account/userSetup/__generated__/UserSetupCommonData';
import { AttachableUserProfileDocuments_attachableUserProfileDocuments_nodes } from '../__generated__/AttachableUserProfileDocuments';
import {
  UserProfileEmail,
  UserProfileEmailVariables,
} from '../__generated__/UserProfileEmail';
import { UserSetupCommon } from '../__generated__/UserSetupCommon';
import { ApprovalTypeView } from '../ApprovalTypeView';
import { UserSetupValues } from '../types';
import { useStyles } from './index.styles';

export type AttachableDataType =
  AttachableUserProfileDocuments_attachableUserProfileDocuments_nodes;
export interface AttachedDocumentsDataType {
  documentTypeId: number | null | undefined;
  entityDocumentId: string[] | null;
  attachedDocuments: AttachableDataType[];
}

const GET_USERPROFILE_COMMON_TYPES = loader(
  '../../UserSetupCommonData.graphql'
);
const COMMON_DATA = loader('../../view/UserSetupCommon.graphql');
const GET_USERPROFILE_EMAIL = loader('../UserProfileEmail.graphql');

interface BasicFormProps {
  toDeleteRows: (id: string, _rowTimestamp: string) => void;
  inputsDisabled: boolean;
  status: string;
  isNew: boolean;
  disableAllUserTemplateSelection: boolean;
  setDisableAllUserTemplateSelection: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  approvalStateDisable: boolean;
  isUpdatable: boolean;
  userId: string | undefined;
}

interface UserTemplateOptions extends IDropdownOption {
  key: string | number;
  text: string;
  departmentOccupationTemplate: UserSetupCommonData_companyDepartmentOccupationTemplates_nodes_departmentOccupationTemplate | null;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  toDeleteRows,
  inputsDisabled,
  status,
  isNew,
  disableAllUserTemplateSelection,
  setDisableAllUserTemplateSelection,
  approvalStateDisable,
  isUpdatable,
  userId,
}) => {
  const styles = useStyles();
  const { data: commonData } = useQuery<UserSetupCommonData>(
    GET_USERPROFILE_COMMON_TYPES
  );
  const { data: commonTypes } = useQuery<UserSetupCommon>(COMMON_DATA);

  const [isDisableDepartmentId, setIsDisableDepartmentId] =
    useState<boolean>(false);

  const [isDisableUserOccupationTitleId, setIsDisableUserOccupationTitleId] =
    useState<boolean>(false);

  const occTemplateOptions: UserTemplateOptions[] =
    commonData?.companyDepartmentOccupationTemplates?.nodes.map((item) => ({
      key: item.departmentOccupationTemplateId,
      text: item.departmentOccupationTemplate?.name || '',
      departmentOccupationTemplate: item.departmentOccupationTemplate,
    })) || [];

  const { values, setFieldValue } = useFormikContext<UserSetupValues>();

  const roleOptions = formatDropdownOptions(commonData?.roles?.nodes, {
    getKey: (item) => item.id,
    getText: (item) => item.name!,
  });
  const occTitleOptions = formatDropdownOptions(
    commonData?.companyUserOccupationTitles?.nodes,
    {
      getKey: (item) => item.userOccupationTitleId,
      getText: (item) => item.userOccupationTitles?.userOccupationTitle!,
    }
  );
  const departmentOptions = formatDropdownOptions(
    commonData?.companyDepartments?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
    }
  );
  const departmentGroupOptions = formatDropdownOptions(
    commonData?.companyDepartmentGroups?.nodes,
    {
      getKey: (item) => item.departmentGroupId,
      getText: (item) => item.departmentGroup?.name!,
    }
  );
  const accessPolicyOptions = formatDropdownOptions(
    commonData?.companyDataAccessPolicies?.nodes,
    {
      getKey: (item) => item.dataAccessPolicyId,
      getText: (item) => item.dataAccessPolicy?.name!,
    }
  );
  const userGroupOptions = formatDropdownOptions(
    commonData?.companyUserGroups?.nodes,
    {
      getKey: (item) => item.userGroupId,
      getText: (item) => item.userGroup?.name!,
    }
  );
  const accessGroupOptions = formatDropdownOptions(
    commonData?.companyAccessGroups?.nodes,
    {
      getKey: (item) => item.accessGroupId,
      getText: (item) => item.accessGroup?.name!,
    }
  );
  const tagGroupOptions = formatDropdownOptions(
    commonData?.companyTagGroups?.nodes,
    {
      getKey: (item) => item.tagGroupId,
      getText: (item) => item.tagGroup?.name!,
    }
  );
  const communicationGroupOptions = formatDropdownOptions(
    commonData?.companyCommunicationGroups?.nodes,
    {
      getKey: (item) => item.communicationGroupId,
      getText: (item) => item.communicationGroup?.name!,
    }
  );
  const rankOptions = formatDropdownOptions(commonTypes?.userRankTypes?.nodes, {
    getKey: (item) => item.id,
    getText: (item) => item.userRank!,
  });
  const securityLevelOptions = formatDropdownOptions(
    commonTypes?.secureRowLevels?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
    }
  );
  interface invitationOptionType extends IDropdownOption {
    key: string | number;
    text: string;
    isDefault: boolean;
    disabled: boolean;
  }
  const invitationTypeOptions: invitationOptionType[] =
    commonData?.userInviteStatusTypes?.nodes.map((item) => ({
      key: item.id,
      text: item.statusType || '',
      isDefault: item.isDefault ?? false,
      disabled: !item.isSelectable,
    })) || [];

  const defaultItemId =
    commonData?.userInviteStatusTypes?.nodes.find((item) => item.isDefault)
      ?.id || null;

  useEffect(() => {
    if (!values.departmentOccupationTemplateId) {
      setDisableAllUserTemplateSelection(false);
      setIsDisableDepartmentId(false);
      setIsDisableUserOccupationTitleId(false);
    }
  }, [
    setFieldValue,
    values.departmentId,
    values.departmentOccupationTemplateId,
  ]);

  const onTransactionTypeChange = async (
    selectedOption: UserTemplateOptions
  ) => {
    if (selectedOption.departmentOccupationTemplate?.departmentId) {
      setIsDisableDepartmentId(true);
    }

    if (selectedOption.departmentOccupationTemplate?.userOccupationTitleId) {
      setIsDisableUserOccupationTitleId(true);
    }
    if (
      selectedOption.departmentOccupationTemplate?.departmentId &&
      selectedOption.departmentOccupationTemplate?.userOccupationTitleId
    ) {
      setIsDisableUserOccupationTitleId(true);
      setIsDisableDepartmentId(true);
    }
    if (
      !selectedOption.departmentOccupationTemplate?.departmentId &&
      selectedOption.departmentOccupationTemplate?.userOccupationTitleId
    ) {
      setIsDisableDepartmentId(false);

      setIsDisableUserOccupationTitleId(true);
    }
    if (
      selectedOption.departmentOccupationTemplate?.departmentId &&
      !selectedOption.departmentOccupationTemplate?.userOccupationTitleId
    ) {
      setIsDisableDepartmentId(true);
      setIsDisableUserOccupationTitleId(false);
    }
    if (
      !selectedOption.departmentOccupationTemplate?.departmentId &&
      !selectedOption.departmentOccupationTemplate?.userOccupationTitleId
    ) {
      setIsDisableDepartmentId(false);
      setIsDisableUserOccupationTitleId(false);
    }

    setDisableAllUserTemplateSelection(true);
  };

  const inviteStatusDisabled =
    inputsDisabled || disableAllUserTemplateSelection || approvalStateDisable;

  const [getUserEmail, { data: userProfileEmail }] = useLazyQuery<
    UserProfileEmail,
    UserProfileEmailVariables
  >(GET_USERPROFILE_EMAIL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });

  return (
    <Stack grow tokens={{ childrenGap: 20, padding: '20px 35px 30px' }}>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikTextField
            name="name"
            label="Name"
            placeholder="Enter name"
            disabled={inputsDisabled || approvalStateDisable}
            required
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikTextField
            name="description"
            label="Description"
            placeholder="Enter description"
            disabled={inputsDisabled || isNew}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item
          className={styles.flex50}
          onMouseEnter={() => {
            if (userId)
              getUserEmail({
                variables: {
                  userProfileId: userId,
                },
              });
          }}
        >
          <TooltipHost
            content={userProfileEmail?.userProfileEmail ?? ''}
            delay={TooltipDelay.long}
          >
            <FormikTextField
              name="emailAccount"
              label="Email"
              placeholder="Enter email"
              disabled={inputsDisabled || approvalStateDisable}
              required
            />
          </TooltipHost>
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            placeholder="Select"
            label="User Template"
            name="departmentOccupationTemplateId"
            options={occTemplateOptions}
            disabled={inputsDisabled}
            onChange={(event, option) => {
              onTransactionTypeChange(option as UserTemplateOptions);
            }}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Role"
            placeholder="Select"
            options={roleOptions}
            name="roleId"
            disabled={inputsDisabled || disableAllUserTemplateSelection}
            required={!disableAllUserTemplateSelection}
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Occupation"
            placeholder="Select"
            options={occTitleOptions}
            name="userOccupationTitleId"
            disabled={inputsDisabled || isDisableUserOccupationTitleId}
            onChange={(_, value) => {
              if (value) {
                setFieldValue('description', value.text);
              }
            }}
            required
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Department"
            placeholder="Select"
            options={departmentOptions}
            name="departmentId"
            disabled={inputsDisabled || isDisableDepartmentId}
            required
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Department Access"
            placeholder="Select"
            name="departmentGroupId"
            options={departmentGroupOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Access Policy"
            placeholder="Select"
            options={accessPolicyOptions}
            name="dataAccessPolicyId"
            disabled={inputsDisabled || disableAllUserTemplateSelection}
            required={!disableAllUserTemplateSelection}
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="User Group"
            placeholder="Select"
            name="userGroupId"
            options={userGroupOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="User Group Access"
            placeholder="Select"
            name="accessGroupId"
            options={accessGroupOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Tagging Group"
            placeholder="Select"
            name="tagGroupId"
            options={tagGroupOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow verticalAlign="end" tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Communication Group"
            placeholder="Select"
            name="communicationGroupId"
            options={communicationGroupOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Rank"
            placeholder="Select"
            name="userRankTypeId"
            options={rankOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow verticalAlign="end" tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Security Level"
            placeholder="Select"
            name="secureRowLevelId"
            options={securityLevelOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Invite Status"
            placeholder="Select"
            name="_invitationStatusTypeId"
            options={invitationTypeOptions}
            disabled={inviteStatusDisabled}
            selectedKey={
              isNew && !values._invitationStatusTypeId
                ? defaultItemId
                : values._invitationStatusTypeId
            }
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow verticalAlign="end" tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <Stack
            horizontal
            className={styles.formikCheckbox}
            tokens={{ childrenGap: 20 }}
          >
            <FormikCheckBox
              disabled
              boxSide="end"
              name={`_signinInfo.isUserAdministrator`}
              label="User Adminsitrator"
            />
            <FormikCheckBox
              disabled
              boxSide="end"
              name={`_signinInfo.isProjectSupervisor`}
              label="Project Supervisor"
            />
          </Stack>
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <Stack
            horizontal
            style={{ justifyContent: 'flex-start' }}
            tokens={{ childrenGap: 50 }}
          >
            <Stack>
              <Label>Last Sign-in</Label>
              <Label style={{ alignSelf: 'center' }}>
                {values._lastUsedDate
                  ? getGlobalDateFormat(values._lastUsedDate)
                  : 'Not available'}
              </Label>
            </Stack>
            <Stack>
              <Label>Active Approvals</Label>
              <Label style={{ alignSelf: 'center' }}>
                {values._activeApprovalCount ?? 'Not Available'}
              </Label>
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>
      <Separator />
      <ApprovalTypeView
        approvalTypes={commonData?.approvalTypes?.nodes || []}
        deleteRow={toDeleteRows}
        inputsDisabled={!isUpdatable}
      />
    </Stack>
  );
};
