import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    borderRadius: 10,

    border: `1px Solid ${theme.palette.neutralDark}66`,
  },
  borderRadiusAdjust: {
    borderTopLeftRadius: 0,
  },
  badge: {
    width: 13,
    height: 13,
    borderRadius: 10,
    top: 0,
    right: 10,
    backgroundColor: theme.palette.orangeLighter,
    cursor: 'pointer',
  },
  noteBadge: {
    marginLeft: 16,
    width: 20,
    height: 20,
    borderRadius: 12,
    top: 0,
    right: 10,
    backgroundColor: theme.palette.orangeLighter,
    cursor: 'pointer',
  },
  notesCountStyle: {
    fontSize: 16,
    alignSelf: 'center',
    color: theme.palette.white,
  },
  sectionStyle: {
    backgroundColor: theme.palette.neutralLighter,
    marginTop: 20,
    borderRadius: 5,
  },
}));
