import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { setTripsModalState } from '../../..';
import { TripCreateFormValues } from '../../types';
import { SaveButton } from './SaveButton';
import { useStyles } from './index.styles';
import { setTripInitiateFlow } from 'utility/cache/ui';
import { useReactiveVar } from '@apollo/client';
import { Trip_trip } from '../../__generated__/Trip';
export const TRIP_MODAL_FOOTER_HEIGHT = 80;

export interface FooterProps {
  loading: boolean;
  onSave: (closeAfterComplete: boolean, addMore: boolean) => void;
  onClose?: () => void;
  tripData: Trip_trip | null | undefined;
}
export const Footer: React.FC<FooterProps> = ({
  loading,
  onSave,
  onClose,
  tripData,
}) => {
  const styles = useStyles();
  const tripInitiateFlowState = useReactiveVar(setTripInitiateFlow);
  const {
    formState: { errors, isDirty, dirtyFields },
  } = useFormContext<TripCreateFormValues>();
  const isErrorsExists = Object.keys(errors).length > 0;
  const isCreate = !tripData;
  const showSectionTripsToggle = isCreate
    ? tripInitiateFlowState !== 'ready'
    : false;
  const disableSaveButton =
    showSectionTripsToggle ||
    isErrorsExists ||
    !(isDirty && Object.keys(dirtyFields).length > 0);

  return (
    <Stack className={styles.container} tokens={{ childrenGap: 10 }}>
      <Stack style={{ height: 10, marginTop: 10 }}>
        {loading && <ProgressIndicator />}
      </Stack>
      <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
        <SaveButton disabled={disableSaveButton} onSave={onSave} />
        <CancelButton
          onConfirm={() => {
            setTripsModalState({
              trip: undefined,
              isModalOpen: false,
              isNew: true,
            });
            onClose?.();
            setTripInitiateFlow('none');
          }}
          isDirty={isDirty && Object.keys(dirtyFields).length > 0}
        />
      </Stack>
    </Stack>
  );
};

const dialogStyles = { main: { maxWidth: 450 } };

const cancelDialogContentProps = {
  type: DialogType.normal,
  title: 'Warning',
  closeButtonAriaLabel: 'Close',
  subText: 'Are you sure you want to leave without saving changes?',
};

export const CancelButton: React.FC<{
  isDirty: boolean;
  onConfirm: () => void;
}> = ({ isDirty, onConfirm }) => {
  const [visible, setVisible] = useState(false);
  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: dialogStyles,
    }),
    []
  );

  return (
    <Stack>
      {visible && (
        <Dialog
          hidden={false}
          dialogContentProps={cancelDialogContentProps}
          modalProps={modalProps}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                onConfirm();
                setVisible(false);
              }}
              text="Leave"
            />
            <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
          </DialogFooter>
        </Dialog>
      )}
      <DefaultButton
        onClick={() => {
          if (isDirty) {
            setVisible(true);
          } else onConfirm();
        }}
        text="Cancel"
      />
    </Stack>
  );
};
