import { UserDefaultValues } from './types';

export const USER_DEFAULT_INITIAL_VALUES: UserDefaultValues = {
  currencyId: null,
  projectId: null,
  proxyUserId: null,
  corporatePeriodId: null,
  businessUnitId: null,
  departmentId: null,
  isDocumentAutoviewEnabled: null,
  lookupAccountId: null,
  referenceCode6: null,
  referenceCode7: null,
  delegateUserProfileId: null,
  delegateEndDate: null,
  delegateEndTime: null,
  delegateStartDate: null,
  delegateStartTime: null,
  isEmailSentOnFinalApproval: null,
  isRequesterEmailSentEachApprovalCycle: null,
  isAccountingAreaExpanded: null,
  listviewSize: null,
  isRowSelectorBold: null,
  projectReference: null,
  setReference: null,
};
