import {
  IChoiceGroupOption,
  IChoiceGroupStyles,
  Stack,
  Text,
} from '@fluentui/react';
import { FormHookTextField } from 'common/components/FormHooksFields';
import React from 'react';
import { useStyles } from './index.styles';
import { FormHookChoiceGroup } from 'common/components/FormHooksFields/FormHookChoiceGroup';
import { useFormContext, useWatch } from 'react-hook-form';
import { TravelerValues } from '../interfaces';

const choiceGroupStyles: IChoiceGroupStyles = {
  label: {
    display: 'inline',
  },
  flexContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
};

export enum SeatPreferenceOptions {
  Aisle = 'isIsleSeatPreferred',
  Window = 'isWindowSeatPreferred',
}

const options: IChoiceGroupOption[] = [
  { key: SeatPreferenceOptions.Aisle, text: 'Aisle' },
  { key: SeatPreferenceOptions.Window, text: 'Window' },
];

interface SeatingPreferenceProps {
  inputsDisabled: boolean;
}

export const SeatingPreference: React.FC<SeatingPreferenceProps> = ({
  inputsDisabled,
}) => {
  const styles = useStyles();
  const { control } = useFormContext<TravelerValues>();
  const seatPreference = useWatch({ name: 'seatPreference', control });

  return (
    <Stack style={{ margin: '0px 20px' }} horizontal>
      <Stack style={{ flex: 1 }}>
        <FormHookTextField
          name="frequentFlyerNumber"
          label="Frequent Flyer"
          placeholder="Number(s)"
          disabled={inputsDisabled}
        />
      </Stack>
      <Stack style={{ flex: 1 }}>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontalAlign="center">
            <Text className={styles.heading}>Seating Preference</Text>
          </Stack>
          <FormHookChoiceGroup
            name="seatPreference"
            options={options}
            styles={choiceGroupStyles}
            value={seatPreference!}
            disabled={inputsDisabled}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
