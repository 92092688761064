import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  disabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
}));
