import React, { useState } from 'react';
import { SearchBarProps, SearchBar } from 'common/components/SearchBar';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { Stack } from '@fluentui/react';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { useStyles } from './index.styles';
import { UserSetupCommonData } from 'settings/account/userSetup/__generated__/UserSetupCommonData';

const COMMON_DATA = loader('../../UserSetupCommonData.graphql');

type UserSetupSearchBarProps = Omit<SearchBarProps, 'searchEnabled'> & {
  onFilterChange: (filterArray: FilterArrayType[]) => void;
};

export const UserSetupSearchBar: React.FC<UserSetupSearchBarProps> = ({
  onFilterChange,
  ...props
}) => {
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const { data: commonData } = useQuery<UserSetupCommonData>(COMMON_DATA);
  const styles = useStyles();

  const roleTypeOptions = formatDropdownOptions(commonData?.roles?.nodes, {
    getKey: (item) => item.id,
    getText: (item) => item.name!,
    includeAll: true,
  });

  const occupationTypeOptions = formatDropdownOptions(
    commonData?.companyUserOccupationTitles?.nodes,
    {
      getKey: (item) => item?.userOccupationTitleId,
      getText: (item) => item?.userOccupationTitles?.userOccupationTitle!,
      includeAll: true,
    }
  );
  const departmentTypeOptions = formatDropdownOptions(
    commonData?.companyDepartments?.nodes,
    {
      getKey: (item) => item?.id,
      getText: (item) => item?.name!,
      includeAll: true,
    }
  );
  const accessPolicyTypeOptions = formatDropdownOptions(
    commonData?.companyDataAccessPolicies?.nodes,
    {
      getKey: (item) => item?.dataAccessPolicyId,
      getText: (item) => item?.dataAccessPolicy?.name!,
      includeAll: true,
    }
  );
  const invitationTypeOptions = formatDropdownOptions(
    commonData?.userInviteStatusTypes?.nodes,
    {
      getKey: (item) => item?.id,
      getText: (item) => item?.statusType!,
      includeAll: true,
    }
  );

  const userStatusTypeOptions = formatDropdownOptions(
    commonData?.userStatusTypes?.nodes,
    {
      getKey: (item) => item?.id,
      getText: (item) => item?.statusType!,
      includeAll: true,
    }
  );
  
  const onOptionChange = (filterData: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterData, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange([]);
        setFilterTypes([]);
      }}
      searchEnabled={false}
      {...props}
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20 }}
        className={styles.filtersWrapperStyle}
      >
        <DropdownFilter
          filterKey={'roleId'}
          options={roleTypeOptions}
          placeholder={'Role'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'userOccupationTitleId'}
          options={occupationTypeOptions}
          placeholder={'Occupation'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'departmentId'}
          options={departmentTypeOptions}
          placeholder={'Department'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'dataAccessPolicyId'}
          options={accessPolicyTypeOptions}
          placeholder={'Access Policy'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'_invitationStatusTypeId'}
          options={invitationTypeOptions}
          placeholder={'Invite Status'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'_statusTypeId'}
          options={userStatusTypeOptions}
          placeholder={'Approval Status'}
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
