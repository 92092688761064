import {
  ActionButton,
  IColumn,
  IconButton,
  IDetailsRowProps,
  IRenderFunction,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react';
import React, { useCallback, useMemo, useState } from 'react';

import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { AppliableInvoiceSigningDocuments_appliableInvoiceSigningDocuments_nodes } from 'common/components/Modules/TransactionEdit/FormView/ActionMenu/AttachForm/__generated__/AppliableInvoiceSigningDocuments';
import { TABLE_ROWS } from 'common/constants';
import {
  EntityDocumentFilter,
  EntityDocumentInput,
  EntityDocumentsOrderBy,
} from 'common/types/globalTypes';
import { SortOrder } from 'common/utils/commonTypes';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { useToasts } from 'react-toast-notifications';
import {
  ApprovalExtendedDocumentTypes,
  ApprovalExtendedDocumentTypesVariables,
} from '../UploadForm/__generated__/ApprovalExtendedDocumentTypes';
import {
  AttachableApprovalExtendedDocuments,
  AttachableApprovalExtendedDocumentsVariables,
} from './__generated__/AttachableApprovalExtendedDocuments';
import {
  AttachApprovalExtendedDocument,
  AttachApprovalExtendedDocumentVariables,
} from './__generated__/AttachApprovalExtendedDocument';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { toOrderByVariable } from './utils';

const APPLIABLE_APPROVAL_DOCUMENTS = loader(
  './AttachableApprovalExtendedDocuments.graphql'
);

const APPLY_APPROVAL_DOCUMENTS = loader(
  './AttachApprovalExtendedDocument.graphql'
);
const AVAILABLE_DOCUMENT_TYPES = loader(
  '../UploadForm/ApprovalExtendedDocumentTypes.graphql'
);

type AttachableDataType =
  AppliableInvoiceSigningDocuments_appliableInvoiceSigningDocuments_nodes;
interface AttachFormProps {
  entityApprovalId: string;
  onRefetch: () => void;
}

export const AttachForm: React.FC<AttachFormProps> = ({
  entityApprovalId,
  onRefetch,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);

  const { data: AvailableDocumentTypes } = useQuery<
    ApprovalExtendedDocumentTypes,
    ApprovalExtendedDocumentTypesVariables
  >(AVAILABLE_DOCUMENT_TYPES, {
    variables: {
      entityApprovalId: entityApprovalId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          entityApprovalId={entityApprovalId}
          AvailableDocumentTypes={AvailableDocumentTypes}
          setOpen={setDialogVisible}
          onRefetch={onRefetch}
        />
      )}
    </>
  );
};

export const AttachFormModal: React.FC<
  AttachFormProps & {
    AvailableDocumentTypes: ApprovalExtendedDocumentTypes | undefined;
    setOpen: (open: boolean) => void;
  }
> = ({ AvailableDocumentTypes, entityApprovalId, setOpen, onRefetch }) => {
  const styles = useStyles();
  const [selectedList, setSelectedList] = useState<AttachableDataType[]>([]);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const {
    data: documentsList,
    loading: documentsLoading,
    fetchMore,
    variables: documentsVariables,
    networkStatus,
    refetch,
  } = useQuery<
    AttachableApprovalExtendedDocuments,
    AttachableApprovalExtendedDocumentsVariables
  >(APPLIABLE_APPROVAL_DOCUMENTS, {
    variables: {
      first: TABLE_ROWS,
      entityApprovalId: entityApprovalId,
      orderBy: [
        EntityDocumentsOrderBy._UPLOAD_DATE_DESC,
        EntityDocumentsOrderBy.PRIMARY_KEY_DESC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [attachDocuments, { loading: attachLoading }] = useMutation<
    AttachApprovalExtendedDocument,
    AttachApprovalExtendedDocumentVariables
  >(APPLY_APPROVAL_DOCUMENTS, { errorPolicy: 'all' });

  const { addToast } = useToasts();

  const documentTypeOptions =
    AvailableDocumentTypes?.approvalExtendedDocumentTypes?.nodes.map(
      (doctype) => ({
        key: doctype.id,
        text: doctype.documentType || '',
      })
    ) || [];
  const transformedData = useMemo(
    () =>
      documentsList?.attachableApprovalExtendedDocuments?.nodes.map(
        (documents) =>
          ({
            ...documents,
          } as AttachableDataType)
      ),
    [documentsList]
  );

  const onRenderItemColumn = useCallback(
    (item?: AttachableDataType, _index?: number, column?: IColumn) => {
      if (!item || !column) return undefined;
      const fieldContent = item[
        column.fieldName as keyof AttachableDataType
      ] as string | null;

      switch (column.key) {
        case 'indexAmount':
        case 'available':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <AmountTextView
                className={styles.contentColumnAlignRight}
                value={fieldContent}
              />
            </Stack>
          );
        case 'used':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <AmountTextView
                className={styles.contentColumnAlignRight}
                value={item.documentAppliedAmounts?.usedTotal!}
              />
            </Stack>
          );
        case 'view':
          const viewDocumentVisible =
            item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <DocumentDataCallout item={item} />
            </Stack>
          );
        case '_uploadDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{getGlobalDateFormat(item._uploadDate!)}</Text>
            </Stack>
          );

        default:
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    },
    [styles]
  );

  const _onAttach = async () => {
    const selectedEntities = selectedList.map((item) => {
      return {
        ...item,
      } as EntityDocumentInput;
    });

    const entityDocuments = selectedEntities.map((item) => item.id);

    const { errors } = await attachDocuments({
      variables: {
        input: {
          entityApprovalId: entityApprovalId,
          entityDocumentId: entityDocuments,
        },
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Document attached successfully', { appearance: 'success' });
      onRefetch();
      setOpen(false);
    }
  };

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    return (
      <>
        {defaultRender!({
          ...props,
        })}
      </>
    );
  };

  const loadMore = useCallback(
    async () =>
      await fetchMore({
        variables: {
          ...documentsVariables,
          after:
            documentsList?.attachableApprovalExtendedDocuments?.pageInfo
              .endCursor,
        },
      }),
    [fetchMore, documentsVariables, documentsList]
  );

  const onFiltersReload = useCallback(
    async (filter: EntityDocumentFilter | undefined) =>
      await refetch({ ...documentsVariables, filter }),
    [documentsVariables, refetch]
  );
  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
    [refetch]
  );

  const onDocumentTypeReload = useCallback(
    async (documentTypeId: number | null) =>
      await refetch({ ...documentsVariables, documentTypeId }),
    [refetch, documentsVariables]
  );

  const attachDisabled = selectedList.length === 0;

  return (
    <>
      <AttachDocumentModal
        columns={columns}
        modalWidth={1450}
        loading={documentsLoading}
        items={
          networkStatus === NetworkStatus.refetch ||
          networkStatus === NetworkStatus.setVariables
            ? undefined
            : transformedData
        }
        hasNextPage={
          documentsList?.attachableApprovalExtendedDocuments?.pageInfo
            .hasNextPage
        }
        attachLoading={attachLoading}
        availableDocumentTypes={documentTypeOptions}
        setOpen={setOpen}
        onSortReload={reload}
        onLoadMore={loadMore}
        onFiltersReload={onFiltersReload}
        onRenderRow={onRenderRow}
        onDocumentTypeChange={onDocumentTypeReload}
        attachDisabled={attachDisabled}
        onSelectionChanged={setSelectedList}
        dropdownDisabled={selectedList.length > 0}
        onRenderItemColumn={onRenderItemColumn}
        onAttachDocuments={_onAttach}
        isDraggable
      />
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
