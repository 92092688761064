import { makeVar, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserDefaults } from 'Preferences/__generated__/UserDefaults';
import { TravelAuthorizationPatch } from 'common/types/globalTypes';
import { dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { TravelAuthorizationSearch_travelAuthorizationSearch } from '../list/__generated__/TravelAuthorizationSearch';
import { FormView } from './FormView';
import {
  TravelAuthorization,
  TravelAuthorizationVariables,
} from './__generated__/TravelAuthorization';
import {
  TravelAuthorizationCreate,
  TravelAuthorizationCreateVariables,
} from './__generated__/TravelAuthorizationCreate';
import {
  TravelAuthorizationUpdate,
  TravelAuthorizationUpdateVariables,
} from './__generated__/TravelAuthorizationUpdate';
import { TravelAuthorizationValues } from './interface';
import { getDefaultValues } from './utils';
import { validationSchema } from './validations';
import { TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals } from '../list/__generated__/TravelAuthorizationSearchFilterTotals';
const TRAVEL_AUTHORIZATION = loader('./TravelAuthorization.graphql');
const TRAVEL_AUTHORIZATION_CREATE = loader(
  './TravelAuthorizationCreate.graphql'
);
const TRAVEL_AUTHORIZATION_UPDATE = loader(
  './TravelAuthorizationUpdate.graphql'
);
const USER_DEFAULTS = loader('../../../Preferences/UserDefaults.graphql');
const TRAVEL_AUTHORIZATION_LIST_FIELDS = loader(
  '../TravelAuthorizationListFields.graphql'
);
const TRAVEL_AUTHORIZATION_FRAGMENT_NAME = 'TravelAuthorizationListFields';
export const isTACreated = makeVar<boolean>(false);

export const TravelAuthorizationView = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const saveAnotherRecord = useRef<boolean>(false);
  const { travelId } = useParams<{ travelId: string | undefined }>();
  const formMethods = useForm<TravelAuthorizationValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { data: userDefaultsData } = useQuery<UserDefaults>(USER_DEFAULTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const {
    data: travelAuthorizationData,
    loading: travelAuthorizationDataLoading,
    refetch,
  } = useQuery<TravelAuthorization, TravelAuthorizationVariables>(
    TRAVEL_AUTHORIZATION,
    {
      variables: {
        id: travelId!,
      },
      skip: !travelId,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const [createTravelAuthorization] = useMutation<
    TravelAuthorizationCreate,
    TravelAuthorizationCreateVariables
  >(TRAVEL_AUTHORIZATION_CREATE, { errorPolicy: 'all' });

  const [updateTravelAuthorization] = useMutation<
    TravelAuthorizationUpdate,
    TravelAuthorizationUpdateVariables
  >(TRAVEL_AUTHORIZATION_UPDATE, { errorPolicy: 'all' });

  const { handleSubmit, trigger, reset } = { ...formMethods };

  const isNew = !travelId;
  const { travelAuthorization } = { ...travelAuthorizationData };
  const { _rowTimestamp } = { ...travelAuthorization };

  const defaultValues = getDefaultValues({
    isNew,
    travelAuthorizationData,
    userDefaultsData: userDefaultsData?.userDefaults?.nodes || [],
  });

  const onHandleSubmit = async (values: TravelAuthorizationValues) => {
    const {
      supplierId,
      tripPurpose,
      comment,
      travelAuthorizationDate,
      bookingFee,
      departmentId,
      currencyId,
      isPersonalTravelAuthorization,
      businessUnitId,
      corporateWorkgroupId,
    } = { ...values };

    const requestPayLoad = {
      supplierId,
      tripPurpose: tripPurpose || '',
      comment: comment || '',
      travelAuthorizationDate: travelAuthorizationDate
        ? dateFormat(travelAuthorizationDate)
        : '',
      bookingFee,
      departmentId: departmentId || null,
      currencyId: Number(currencyId),
      isPersonalTravelAuthorization,
      businessUnitId: businessUnitId || '',
      corporateWorkgroupId,
    };

    if (isNew) {
      const { data, errors } = await createTravelAuthorization({
        variables: {
          input: {
            travelAuthorization: requestPayLoad,
          },
        },
        update: (cache, { data }) => {
          try {
            cache.modify({
              fields: {
                TravelAuthorizationSearchFilterTotals(
                  existing: TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals
                ) {
                  if (existing?.totalCount1 !== 0) {
                    const { controlTotalAmount, budgetAmount } = {
                      ...data?.travelAuthorizationCreate?.travelAuthorization,
                    };
                    return {
                      ...existing,
                      totalAmount1:
                        existing.totalAmount1 +
                        parseFloat(controlTotalAmount || '0.00').toString(),
                      totalAmount4:
                        existing.totalAmount4 +
                        parseFloat(budgetAmount || '0.00').toString(),
                      totalCount1: existing.totalCount1
                        ? existing.totalCount1 + 1
                        : 1,
                    };
                  } else {
                    return {
                      ...existing,
                    };
                  }
                },
                travelAuthorizationSearch(
                  existing: TravelAuthorizationSearch_travelAuthorizationSearch
                ) {
                  if (data?.travelAuthorizationCreate?.travelAuthorization) {
                    const newTravelPlanRef = cache.writeFragment({
                      data: data.travelAuthorizationCreate.travelAuthorization,
                      fragment: TRAVEL_AUTHORIZATION_LIST_FIELDS,
                      fragmentName: TRAVEL_AUTHORIZATION_FRAGMENT_NAME,
                    });
                    return {
                      ...existing,
                      nodes: [newTravelPlanRef, ...existing.nodes],
                    };
                  }
                },
              },
            });
          } catch (error) {}
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      }
      if (data?.travelAuthorizationCreate?.travelAuthorization) {
        if (!saveAnotherRecord.current) {
          isTACreated(true);
        } else {
          isTACreated(false);
        }
        history.replace(
          `/ta/travel-plan/travel/${data?.travelAuthorizationCreate?.travelAuthorization?.id}`
        );
        addToast('Travel plan added successfully.', {
          appearance: 'success',
        });
      }
    } else {
      const fieldsToInclude = Object.keys(requestPayLoad);
      const travelAuthorizationPatch: TravelAuthorizationPatch = Object.entries(
        requestPayLoad
      ).reduce((res, [key, val]) => {
        if (
          val !== defaultValues[key as keyof TravelAuthorizationValues] &&
          fieldsToInclude.includes(key)
        ) {
          return { ...res, [key]: val };
        }
        return res;
      }, {});
      const { errors } = await updateTravelAuthorization({
        variables: {
          input: {
            id: travelId!,
            rowTimestamp: _rowTimestamp || '',
            travelAuthorizationPatch: !isEmpty(travelAuthorizationPatch)
              ? travelAuthorizationPatch
              : undefined,
          },
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Trip plan edited successfully', {
          appearance: 'success',
        });
      }
    }
  };

  useEffect(() => {
    const defaultValues = getDefaultValues({
      isNew,
      travelAuthorizationData,
      userDefaultsData: userDefaultsData?.userDefaults?.nodes || [],
    });

    reset(defaultValues);

    trigger();
  }, [travelAuthorizationData, isNew, reset, userDefaultsData, trigger]);

  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    isTACreated(false);
    triggerCallBack();
  }, [triggerCallBack]);

  return (
    <>
      <FormProvider {...formMethods}>
        <FormView
          onSave={handleSubmit(onHandleSubmit)}
          isSaveAnother={(value) => (saveAnotherRecord.current = value)}
          isNew={isNew}
          travelAuthorizationData={travelAuthorization}
          isLoading={travelAuthorizationDataLoading}
          refetch={refetch}
        />
      </FormProvider>
    </>
  );
};
